// component
// import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

// const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const navConfig = [
  {
    title: 'Dashboard',
    path: '/app/dashboard',
    icon: 'dashboard',
  },
  {
    title: 'Invitaciones',
    path: '/app/invitation',
    icon: 'mail_outline',
  },
  {
    title: 'Clientes',
    path: '/app/customers',
    icon: 'group_outline',
  },
  {
    title: 'Creditos',
    path: '/app/credits',
    icon: 'list_alt',
  },
  {
    title: 'Desembolsos Sede',
    path: '/app/disbursements',
    icon: 'store',
  },
  {
    title: 'Reportes',
    path: '/app/reports',
    icon: 'table_chart',
  },
  
  // {
  //   title: 'Simulador',
  //   path: '/app/simulator',
  //   icon: 'tune',
  // },
  // {
  //   title: 'Desembolsos Grupo',
  //   path: '/app/group-disbursements',
  //   icon: 'apartment',
  // },
  // {
  //   title: 'Configuración',
  //   path: '/dashboard/products',
  //   icon: 'settings',
  // },
  // {
  //   title: 'Salir',
  //   path: '/dashboard/blog',
  //   icon: 'logout',
  // },
  // {
  //   title: 'login',
  //   path: '/login',
  //   icon: 'login',
  // },
  // {
  //   title: 'Not found',
  //   path: '/404',
  //   icon: 'error',
  // },
];

export default navConfig;
