import React from 'react';
import { FormControl, InputLabel, Select, MenuItem, FormHelperText } from '@mui/material';

const CustomSelect = React.forwardRef((props, ref) => {
  const {
    name,
    error,
    handleChangeSelect,
    text,
    options,
    value,
    disabled = false,
  } = props;

  return (
    <FormControl
      fullWidth
      variant="outlined"
      error={!!error}
      disabled={disabled}
      sx={{ marginTop: 0 }}
    >
      <InputLabel id={`${name}-label`}>{text}</InputLabel>
      <Select
        labelId={`${name}-label`}
        id={name}
        name={name}
        value={value || ''}
        onChange={(e) => handleChangeSelect(e, options)}
        label={text}
        inputRef={ref}
      >
        <MenuItem value="" disabled>
          <em>Seleccione una opción</em>
        </MenuItem>
        {options.map((it) => (
          <MenuItem key={it.value} value={it.value}>
            {it.name}
          </MenuItem>
        ))}
      </Select>
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
});

export default CustomSelect;