import React from 'react';
import { Box } from '@mui/material';

const CustomBox = ({ children, sx = {}, ...otherProps }) => {
    return (
        <Box
            sx={{
                margin: "50px auto",
                bgcolor: 'background.paper',
                border: '2px solid #000',
                boxShadow: 24,
                pt: 2,
                px: 4,
                pb: 3,
                ...sx,
            }}
            {...otherProps}
        >
            {children}
        </Box>
    );
};

export default CustomBox;