import React, { useEffect, useState } from "react";
import { useCreditRequest } from '../Context/useCreditRequest';
import { useCreditRequestAuth } from "../Context/useCreditRequestAuth";
import {
    Box,
    Grid,
    Typography,
    Button,
    CircularProgress,
} from '@mui/material';
import { WhatsApp, Email, Sms } from '@mui/icons-material';
import CustomBox from "src/components/CustomBox";
import Iconify from '../components/iconify';

const NewCustomerS3_1 = ({ handleChangeStep, handleCloseModal }) => {
    const { setLogoSize } = useCreditRequest();
    const [sending, setSending] = useState(false);
    const { getStorage, setStorage } = useCreditRequestAuth();

    let request = getStorage("request");

    useEffect(() => {
        setLogoSize({ width: "240px", marginTop: "40px", marginBottom: "30px" });
    }, []);

    const smsOTP = () => {
        setSending(true);
        setStorage("request", {
            ...request,
            OTP: 2,
            state: 3
        });
        handleChangeStep(3);
    };

    const emailOTP = () => {
        setSending(true);
        setStorage("request", {
            ...request,
            OTP: 1,
            state: 3
        });
        handleChangeStep(3);
    };

    const waOTP = () => {
        setSending(true);
        setStorage("request", {
            ...request,
            OTP: 3,
            state: 3
        });
        handleChangeStep(3);
    };

    return (
        <CustomBox
            sx={{
                width: '80%'
            }}
        >
            <Grid item xs={12} sm={12} md={12} style={{ textAlign: "right", marginTop: "0px", paddingTop: "0px" }}>
                <button style={{ border: "none", backgroundColor: "transparent", fontWeight: "bold", fontSize: "30px", cursor: "pointer", marginTop: "0px" }} onClick={handleCloseModal} ><Iconify sx={{ width: "30px", height: "30px" }} icon="eva:close-outline" /></button>

            </Grid>
            <Box
                sx={{
                    maxWidth: { xs: '100%', md: '80%' },
                    margin: '0 auto',
                    textAlign: 'center',
                    padding: { xs: '0px', md: '20px' },
                }}
            >

                <Typography variant="subtitle1" color="black" sx={{ fontSize: '13px', padding: '0px' }}>
                    {request.cusFirstName} {request.cusSecondName} {request.cusFirstSurname} {request.cusSecondSurname}
                </Typography>
                <Typography variant="subtitle2" color="gray" sx={{ fontSize: '13px', padding: '0px' }}>
                    {request.cusDocumentTypeTemp} {request.cusDocument}
                </Typography>
                <Typography variant="h4" sx={{ paddingTop: '15px' }}>
                    Verificación electrónica
                </Typography>
                <Typography variant="h5" color="gray" sx={{ paddingTop: '15px' }}>
                    <b style={{ color: 'black' }}>2</b>/4
                </Typography>

                {/* Opción de WhatsApp */}
                {(request.origin === 1 || (request.origin === 2 && request.idMethodWA)) && (
                    <Box
                        onClick={waOTP}
                        sx={{
                            backgroundColor: '#efefef',
                            borderRadius: '15px',
                            margin: '10px auto',
                            border: '1px solid black',
                            padding: '10px',
                            cursor: 'pointer',
                            textAlign: 'center',
                            width: { xs: '100%', md: '50%' },
                        }}
                    >
                        <Grid container alignItems="center">
                            <Grid item xs={3}>
                                <WhatsApp sx={{ fontSize: '35px' }} />
                            </Grid>
                            <Grid item xs={9} sx={{ textAlign: 'left' }}>
                                <Typography variant="body1">
                                    <b>Mediante WhatsApp</b>
                                </Typography>
                                <Typography variant="body2">
                                    {request.phoneCodeTemp} {request.cusPhone}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>
                )}

                {/* Opción de correo electrónico */}
                <Box
                    onClick={emailOTP}
                    sx={{
                        backgroundColor: '#efefef',
                        borderRadius: '15px',
                        margin: '10px auto',
                        border: '1px solid black',
                        padding: '10px',
                        cursor: 'pointer',
                        textAlign: 'center',
                        width: { xs: '100%', md: '50%' },
                    }}
                >
                    <Grid container alignItems="center">
                        <Grid item xs={3}>
                            <Email sx={{ fontSize: '35px' }} />
                        </Grid>
                        <Grid item xs={9} sx={{ textAlign: 'left' }}>
                            <Typography variant="body1">
                                <b>Mediante correo electrónico</b>
                            </Typography>
                            <Typography variant="body2">
                                {request.cusEmail}
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>

                {/* Opción de mensaje de texto */}
                <Box
                    onClick={smsOTP}
                    sx={{
                        backgroundColor: '#efefef',
                        borderRadius: '15px',
                        margin: '10px auto',
                        border: '1px solid black',
                        padding: '10px',
                        cursor: 'pointer',
                        textAlign: 'center',
                        width: { xs: '100%', md: '50%' },
                    }}
                >
                    <Grid container alignItems="center">
                        <Grid item xs={3}>
                            <Sms sx={{ fontSize: '35px' }} />
                        </Grid>
                        <Grid item xs={9} sx={{ textAlign: 'left' }}>
                            <Typography variant="body1">
                                <b>Mediante mensaje de texto</b>
                            </Typography>
                            <Typography variant="body2">
                                {request.phoneCodeTemp} {request.cusPhone}
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>

                {/* Botón Regresar */}
                <Box sx={{ textAlign: 'center', padding: '10px' }}>
                    <Button
                        variant="outlined"
                        color="secondary"
                        disabled={sending}
                        onClick={() => {
                            if (request.origin === 1) {
                                setStorage("request", {
                                    ...request, state: 1
                                });
                            } else {
                                setStorage("request", {
                                    ...request, state: 0
                                });
                            }
                            handleChangeStep(1);
                        }}
                    >
                        Regresar
                    </Button>
                </Box>

                {/* Indicador de carga */}
                {sending && (
                    <Box sx={{ textAlign: 'center', padding: '10px' }}>
                        <CircularProgress />
                    </Box>
                )}
            </Box>
        </CustomBox>
    );
};

export default NewCustomerS3_1;
