import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content'
// @mui
import { Link, Stack, IconButton, InputAdornment, TextField, Checkbox, FormControlLabel } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import { useTheme } from '@mui/material/styles';
import ReactPasswordChecklist from 'react-password-checklist';
import Iconify from '../../../components/iconify';
import { ResetPasswordToken } from "../../../services/SoraService"



// ----------------------------------------------------------------------

export default function ChangePasswordForm({ token, setPasswordChanged }) {
    const navigate = useNavigate();

    const [showPassword, setShowPassword] = useState(false);
    const [showPassword2, setShowPassword2] = useState(false);

    const [password, setPassword] = useState("");
    const [password2, setPassword2] = useState("");

    const [passwordsValid, setPasswordsValid] = useState(true);

    const [loading, setLoading] = useState(false);
    const MySwal = withReactContent(Swal)
    const theme = useTheme();

    const handleClick = () => {

        setLoading(true);
        if (password && password2 && password === password2) {


            ResetPasswordToken(token, password).then(res => {
                setLoading(false);
                if (res.status === 200) {
                    setPasswordChanged(true);
                }
                else {

                    MySwal.fire({
                        html: res.data.message,
                        icon: 'error',
                        confirmButtonColor: theme.palette.primary.main
                    })
                }
            })
        }
        else {
            setLoading(false);
            MySwal.fire({
                // title: <strong>Error!</strong>,
                html: <p>Completa todos los campos!</p>,
                icon: 'error',
                confirmButtonColor: theme.palette.primary.main
            })
        }
    };

    return (
        <>
            <Stack spacing={3} sx={{ my: 2 }}>


                <TextField
                    name="password"
                    label="Nueva Contraseña"
                    type={showPassword ? 'text' : 'password'}
                    autoComplete='off'
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    onChange={(event) => {
                        setPassword(event.target.value);
                    }}
                />



                <TextField
                    name="password2"
                    label="Repita Nueva Contraseña"
                    type={showPassword2 ? 'text' : 'password'}
                    autoComplete='off'
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={() => setShowPassword2(!showPassword2)} edge="end">
                                    <Iconify icon={showPassword2 ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    onChange={(event) => {
                        setPassword2(event.target.value);
                    }}
                />

                <Stack spacing={3} sx={{ my: 2 }}>
                    <ReactPasswordChecklist
                        rules={["minLength", "capital", "lowercase", "number", "specialChar", "match"]}
                        minLength={8}
                        value={password}
                        valueAgain={password2}
                        specialCharsRegex={/[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]/g}
                        iconComponents={{ ValidIcon: <Iconify style={{ color: "green" }} icon="eva:checkmark-outline" />, InvalidIcon: <Iconify style={{ color: "red" }} icon="eva:close-outline" /> }}
                        messages={{
                            minLength: "Debe contener mínimo 8 caracteres.",
                            specialChar: "Debe incluir al menos un caracter especial.",
                            number: "Debe incluir al menos un número.",
                            capital: "Debe incluir al menos una letra mayúscula.",
                            lowercase: "Debe incluir al menos una letra minuscula.",
                            match: "Las contraseñas deben coincidir.",
                        }}
                        onChange={(isValid) => { setPasswordsValid(!isValid) }}
                    />
                </Stack>


            </Stack>



            <LoadingButton disabled={passwordsValid} fullWidth size="large" type="submit" variant="contained" onClick={handleClick} loading={loading}>
                Reestablecer
            </LoadingButton>
        </>
    );
}
