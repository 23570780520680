import { useEffect, useState } from 'react';
import { NumericFormat } from "react-number-format";
import {
    Modal,
    Box,
    Grid,
    IconButton,
    TextField,
    Chip,
    InputAdornment,
    Snackbar,
    Alert,
    Paper,
    Typography,
    Tooltip,
    Select,
    MenuItem,
    InputLabel,
    FormControl,
    FormControlLabel,
    Switch,
    ClickAwayListener,
    Stack
} from '@mui/material';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content'
import { LoadingButton } from '@mui/lab';
import Avatar from 'avataaars';
import { ChromePicker } from 'react-color';
import ReactPasswordChecklist from 'react-password-checklist';
import { useTheme } from '@mui/material/styles';
import Iconify from '../components/iconify';
import useResponsive from '../hooks/useResponsive';
import { useGlobalProvider } from '../components/GlobalProvider'
import SoraServiceWithToken from '../services/SoraServiceWithToken'


export default function ResetPasswordModal({ openModal, setOpenModal }) {

    const xlUp = useResponsive('up', 'xl');
    const mdUp = useResponsive('up', 'md');
    // const { posData, departments, sending, setSending, allTreatments } = useGlobalProvider();

    const [showCurrentPassword, setShowCurrentPassword] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showPassword2, setShowPassword2] = useState(false);

    const [currentPassword, setCurrentPassword] = useState("");
    const [password, setPassword] = useState("");
    const [password2, setPassword2] = useState("");

    const [passwordsValid, setPasswordsValid] = useState(true);
    const [loading, setLoading] = useState(false);
    const MySwal = withReactContent(Swal)
    const theme = useTheme();
    const [snackbarData, setSnackbarData] = useState({ message: "msg", severity: "warning" });
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const { ResetPassword } = SoraServiceWithToken();


    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenSnackbar(false);
    };

    const handleCloseModal = (event, reason) => {

        if (reason && reason === "backdropClick")
            return;
        setOpenModal(false)
    }

    const changePassword = () => {
        setLoading(true);

        if (currentPassword && password && password2 && password === password2) {

            ResetPassword({
                app: "AGORA",
                currentPassword,
                newPassword: password
            }).then(res => {
                setLoading(false);
                if (res.status === 200) {
                    setOpenSnackbar(true)
                    setSnackbarData({ message: "Datos guardados!", severity: "success" });
                    setOpenModal(false);
                }
                else {
                    setOpenSnackbar(true)
                    setSnackbarData({ message: "Contraseña actual incorrecta", severity: "error" })
                }
            })

        }
        else {
            setLoading(false);
            MySwal.fire({
                // title: <strong>Error!</strong>,
                html: <p>Completa todos los campos!</p>,
                icon: 'error',
                confirmButtonColor: theme.palette.primary.main
            })
        }
    }


    // const handleColorChange = (color) => {
    //     setColorState({ color: color.hex.toUpperCase() });
    // };

    // const handleColorChange2 = (color) => {
    //     setColorState2({ color: color.hex.toUpperCase() });
    // };




    const modalStyle = {
        margin: "20px auto",
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        pt: 2,
        px: 4,
        pb: 3,
    };




    return <>

        <Snackbar open={openSnackbar} autoHideDuration={5000} onClose={handleCloseSnackbar} anchorOrigin={{ horizontal: "center", vertical: "bottom" }}>
            <Alert onClose={handleCloseSnackbar} severity={snackbarData.severity} sx={{ width: '100%' }}>
                {snackbarData.message}
            </Alert>
        </Snackbar>

        <Modal
            open={openModal}
            onClose={handleCloseModal}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
            sx={{

                overflow: 'scroll',

            }}>




            <Box sx={{ ...modalStyle, width: mdUp ? "40%" : "90%" }}>


                {/* <Modal open={displayMainColorPicker}
                    onClose={() => { }}
                    aria-labelledby="parent-modal-title"
                    aria-describedby="parent-modal-description"
                >
                    <Box sx={{ ...modalStyle, width: "400px", textAlign: "center" }}>
                        <ChromePicker color={colorState.color}
                            onChangeComplete={handleColorChange} />

                        <br />
                        <LoadingButton color="success" variant="contained" onClick={() => { setdisplayMainColorPicker(false) }}>OK</LoadingButton>
                    </Box>


                </Modal> */}

                <Grid item xs={10} sm={10} md={10} xl={10} />
                <Grid item xs={2} sm={2} md={2} style={{ textAlign: "right", padding: "0px" }}><button style={{ border: "none", backgroundColor: "transparent", fontWeight: "bold", fontSize: "30px", cursor: "pointer", marginTop: "15px" }} onClick={() => { setOpenModal(false); }}><Iconify sx={{ width: "30px", height: "30px" }} icon="eva:close-outline" /></button></Grid>
                <Grid item xs={12} sm={12} md={12} xl={12} style={{ textAlign: "center", paddingTop: "0px" }}> <h2 id="parent-modal-title" style={{ margin: "0" }}>Cambiar Contraseña</h2></Grid>



                {/* <Grid item xs={12} sm={12} md={12} xl={12}>
                    <p style={{ margin: "0px 0px 15px 0px", fontSize: "15px" }}>
                        texto
                    </p>

                </Grid> */}




                <Grid container spacing={2} >

                    <Grid item xs={12} sm={12} md={12} xl={12}>

                        <Stack spacing={3} sx={{ my: 2 }}>


                            <TextField
                                name="current-password"
                                label="Contraseña Actual"
                                type={showCurrentPassword ? 'text' : 'password'}
                                autoComplete='off'
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton onClick={() => setShowCurrentPassword(!showCurrentPassword)} edge="end">
                                                <Iconify icon={showCurrentPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                onChange={(event) => {
                                    setCurrentPassword(event.target.value);
                                }}
                            />

                            <TextField
                                name="password"
                                label="Nueva Contraseña"
                                type={showPassword ? 'text' : 'password'}
                                autoComplete='off'
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                                                <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                onChange={(event) => {
                                    setPassword(event.target.value);
                                }}
                            />



                            <TextField
                                name="password2"
                                label="Repita Nueva Contraseña"
                                type={showPassword2 ? 'text' : 'password'}
                                autoComplete='off'
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton onClick={() => setShowPassword2(!showPassword2)} edge="end">
                                                <Iconify icon={showPassword2 ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                onChange={(event) => {
                                    setPassword2(event.target.value);
                                }}
                            />

                            <Stack spacing={3} sx={{ my: 2 }}>
                                <ReactPasswordChecklist
                                    rules={["minLength", "capital", "lowercase", "number", "specialChar", "match"]}
                                    minLength={8}
                                    value={password}
                                    valueAgain={password2}
                                    specialCharsRegex={/[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]/g}
                                    iconComponents={{ ValidIcon: <Iconify style={{ color: "green" }} icon="eva:checkmark-outline" />, InvalidIcon: <Iconify style={{ color: "red" }} icon="eva:close-outline" /> }}
                                    messages={{
                                        minLength: "Debe contener mínimo 8 caracteres.",
                                        specialChar: "Debe incluir al menos un caracter especial.",
                                        number: "Debe incluir al menos un número.",
                                        capital: "Debe incluir al menos una letra mayúscula.",
                                        lowercase: "Debe incluir al menos una letra minuscula.",
                                        match: "Las contraseñas deben coincidir.",
                                    }}
                                    onChange={(isValid) => { setPasswordsValid(!isValid) }}
                                />
                            </Stack>


                        </Stack>
                    </Grid>


                    <Grid item xs={12} sm={12} md={12} xl={12} container justifyContent="center" style={{ padding: "20px 0px 0px 0px" }}>
                        <LoadingButton disabled={passwordsValid || loading || !currentPassword} color="info" variant="contained" onClick={changePassword} loading={loading}>
                            Guardar
                        </LoadingButton>
                    </Grid>




                </Grid>

            </Box>

        </Modal >

    </>
}