import React, { useEffect, useRef, useState } from "react";
import { useCreditRequest } from "../Context/useCreditRequest";
import useForm from "../hooks/useForm";
import { useNavigate } from 'react-router-dom';
import TarsService from "../services/TarsService";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { useCreditRequestAuth } from "../Context/useCreditRequestAuth";
import CustomSelect from "../components/CustomSelect";
import Privacy from "./Privacy";
import PersonalData from "./PersonalData";
import {
    Box,
    Grid,
    Typography,
    TextField,
    Button,
    Checkbox,
    FormControlLabel,
    CircularProgress,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
} from '@mui/material';
import { NumericFormat } from 'react-number-format';
import { getCountriesState } from "../services/CountriesServices";
// import 'react-intl-tel-input-18/dist/main.css';
import CustomBox from "src/components/CustomBox";
import '../StyleSheets/SwalStyle.css';
import Iconify from '../components/iconify';
import { useGlobalProvider } from '../components/GlobalProvider'

const NewCustomerS4 = ({ handleChangeStep, handleCloseModal }) => {
    const navigate = useNavigate();
    const { getEC, getCT } = useCreditRequest();
    const { getStorage, setStorage } = useCreditRequestAuth();

    let request = getStorage("request");

    const educationalLevelRef = useRef(null);
    const maritalStatusRef = useRef(null);
    const housingTypeRef = useRef(null);
    const economicLevelRef = useRef(null);
    const dependentsRef = useRef(null);
    const stateCountryRef = useRef(null);
    const residenceCityRef = useRef(null);
    const addressRef = useRef();
    const companyRef = useRef();
    const laborTypeRef = useRef(null);
    const laborContractRef = useRef(null);
    const laborAntiquityRef = useRef(null);

    const [enumCustomer, setEnumCustomer] = useState([]);
    // const [educationalLevels, setEducationalLevels] = useState([]);
    // const [maritalStatuses, setMaritalStatuses] = useState([]);
    // const [housingTypes, setHousingTypes] = useState([]);
    // const [economicLevels, setEconomicLevels] = useState([]);
    // const [residenceCountries, setResidenceCountries] = useState([]);

    // const [laborTypes, setLaborTypes] = useState([]);
    // const [laborContracts, setLaborContracts] = useState([]);
    // const [laborAntiquities, setLaborAntiquities] = useState([]);
    // const [dependents, setDependents] = useState([]);
    const [municipalities, setMunicipalities] = useState([]);
    const [salaried, setSalaried] = useState(true);
    const [independent, setIndependent] = useState(false);
    const [otherLaborType, setOtherLaborType] = useState(false);
    const [showPrivacyDialog, setShowPrivacyDialog] = useState(false);
    const [showPersonalDataDialog, setShowPersonalDataDialog] = useState(false);

    const { sending, setSending,
        countries, educationalLevels, maritalStatuses, housingTypes, economicLevels, dependents,
        laborTypes, laborContracts, laborAntiquities, departments } = useGlobalProvider();

    const [stateCountries, setStateCountries] = useState(departments);
    const [stateCountriesTemp, setStateCountriesTemp] = useState(departments.map(el => ({
        value: el.value,
        IdCountry: "CO",
        name: el.label,
        control: "stateCountry",
        Municipalities: el.Municipalities.map(m => ({ value: m.value, name: m.label, control: "residenceCity" })),
    })));

    const MySwal = withReactContent(Swal);

    const validateForm = (form) => {
        let errors = {};

        if (!form.dataPrivacyCheck && request.comingFromDashboard) { errors.dataPrivacyCheck = "Debe aceptar la política de privacidad"; }
        if (!form.dataTreatmentCheck && request.comingFromDashboard) { errors.dataTreatmentCheck = "Debe aceptar el aviso de privacidad"; }

        if (!String(form.expenses).trim()) { errors.expenses = "El campo Egresos es requerido"; }
        if (!String(form.income).trim()) { errors.income = "El campo Ingresos es requerido"; }
        if (!form.laborAntiquity) { errors.laborAntiquity = "El campo Antigüedad laboral es requerido"; }
        if (!form.laborContract && form.laborType && (form.laborType === "ASA" || form.laborType === "FRL")) { errors.laborContract = "El campo Tipo de contrato es requerido"; }
        if (!form.laborType) { errors.laborType = "El campo Ocupación es requerido"; }
        if (!form.address.trim()) { errors.address = "El campo Dirección es requerido"; }
        if (!form.company.trim()) { errors.company = "El campo Empresa / fuente de ingresos es requerido"; }
        if (form.stateCountry && !form.residenceCity) { errors.residenceCity = "El campo Ciudad es requerido"; }
        if (form.residenceCountry && form.residenceCountry === "CO" && !form.stateCountry.trim()) { errors.stateCountry = "El campo Departamento de residencia es requerido"; }
        if (!form.residenceCountry.trim()) { errors.residenceCountry = "El campo País de residencia es requerido"; }
        if (!form.dependents.trim()) { errors.dependents = "El campo Personas a cargo es requerido"; }
        if (!form.economicLevel.trim()) { errors.economicLevel = "El campo Estrato es requerido"; }
        if (!form.housingType.trim()) { errors.housingType = "El campo Tipo de vivienda es requerido"; }
        if (!form.maritalStatus.trim()) { errors.maritalStatus = "El campo Estado civil es requerido"; }
        if (!form.educationalLevel.trim()) { errors.educationalLevel = "El campo Nivel educativo es requerido"; }

        return errors;
    };

    const initialForm = {
        educationalLevel: request.cusEducationLevel || "",
        maritalStatus: request.cusMaritalStatus || "",
        housingType: request.cusHousingType || "",
        economicLevel: request.cusEconomicLevel || "",
        company: request.cusLaborCompany || "",
        residenceCountry: request.cusAddressCountry || (request.cusDocumentType === "CCC" ? "CO" : ""),
        stateCountry: request.cusAddressState || "",
        residenceCity: request.cusAddressCity || "",
        neighborhood: request.cusAddressTown || "",
        address: request.cusAddress || "",
        laborType: request.cusLaborType || "",
        laborContract: request.cusLaborContract || "",
        income: request.cusLaborSalary || "",
        expenses: request.cusExpenses || "",
        laborAntiquity: request.cusLaborAntiquity || "",
        dependents: request.cusDependents || "",
        dataPrivacyCheck: false,
        dataTreatmentCheck: false,
    };

    const { form, errors, handleChange, handleSubmit, handleChecked, handleChangeSelect } = useForm(initialForm, validateForm);
    const { newCustomer, getCustomerById } = TarsService();

    useEffect(() => {
        // setSending(true);
        // let a = getEC();
        // let b = getCT();
        // let c = getCountriesState();

        // Promise.all([a, b, c]).then((values) => {
        //     setSending(false);
        //     let data1 = values[0];

        //     setEnumCustomer(data1);
        //     setEducationalLevels(data1.find(el => el.EnumerationId === "cusEnumEducationLevel").EnumerationValues.map(el => ({ value: el.value, name: el.label, control: "educationalLevel" })));
        //     setMaritalStatuses(data1.find(el => el.EnumerationId === "cusEnumMaritalStatus").EnumerationValues.map(el => ({ value: el.value, name: el.label, control: "maritalStatus" })));
        //     setHousingTypes(data1.find(el => el.EnumerationId === "cusEnumHousingType").EnumerationValues.map(el => ({ value: el.value, name: el.label, control: "housingType" })));
        //     setEconomicLevels(data1.find(el => el.EnumerationId === "cusEnumEconomicLevel").EnumerationValues.map(el => ({ value: el.value, name: el.label, control: "economicLevel" })));
        //     setLaborTypes(data1.find(el => el.EnumerationId === "cusEnumLaborType").EnumerationValues.map(el => ({ value: el.value, name: el.label, control: "laborType" })));
        //     setLaborContracts(data1.find(el => el.EnumerationId === "cusEnumLaborContract").EnumerationValues.map(el => ({ value: el.value, name: el.label, control: "laborContract" })));
        //     setLaborAntiquities(data1.find(el => el.EnumerationId === "cusEnumLaborAntiquity").EnumerationValues.map(el => ({ value: el.value, name: el.label, control: "laborAntiquity" })));
        //     setDependents(data1.find(el => el.EnumerationId === "cusEnumDependents").EnumerationValues.map(el => ({ value: el.value, name: el.label, control: "dependents" })));

        //     let data2 = values[1];
        //     setResidenceCountries(data2.map(el => ({ value: el.value, name: el.OriginalName, control: "residenceCountry" })));

        //     let data3 = values[2];
        //     let sct = data3.CacheValues[0].Departments.map(el => ({
        //         value: el.value,
        //         IdCountry: "CO",
        //         name: el.label,
        //         control: "stateCountry",
        //         Municipalities: el.Municipalities.map(m => ({ value: m.value, name: m.label, control: "residenceCity" })),
        //     }));
        //     setStateCountriesTemp(sct);

        //     if (request.comingFromDashboard) {
        //         getCustomerById().then(res => {
        //             mapRequest(JSON.parse(res.data.data));
        //         });
        //     }
        // });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const mapRequest = (data) => {
        handleChange({ target: { name: "address", value: data.cusAddress ?? "" } });
        handleChange({ target: { name: "company", value: data.cusLaborCompany ?? "" } });
        handleChange({ target: { name: "income", value: data.cusLaborSalary } });
        handleChange({ target: { name: "expenses", value: data.cusTotalExpenses } });
        handleChangeSelect({ target: { name: "educationalLevel", value: data.cusEducationLevel ?? "" } });
        handleChangeSelect({ target: { name: "maritalStatus", value: data.cusMaritalStatus ?? "" } });
        handleChangeSelect({ target: { name: "housingType", value: data.cusHousingType ?? "" } });
        handleChangeSelect({ target: { name: "economicLevel", value: data.cusEconomicLevel ?? "" } });
        handleChangeSelect({ target: { name: "dependents", value: data.cusNumDependents ?? "" } });
        handleChangeSelect({ target: { name: "residenceCountry", value: data.cusAddressCountry ?? "" } });
        handleChangeSelect({ target: { name: "stateCountry", value: data.cusAddressState ?? "" } });
        handleChangeSelect({ target: { name: "residenceCity", value: data.cusAddressCity ?? "" } });
        handleChangeSelect({ target: { name: "laborType", value: data.cusLaborType ?? "" } });
        handleChangeSelect({ target: { name: "laborContract", value: data.cusLaborContract ?? "" } });
        handleChangeSelect({ target: { name: "laborAntiquity", value: data.cusLaborAntiquity ?? "" } });
    };

    useEffect(() => {
        if (form.laborType) {
            if (form.laborType === "ASA") {
                setSalaried(true);
                setIndependent(false);
                setOtherLaborType(false);
            } else if (form.laborType === "FRL") {
                setIndependent(true);
                setSalaried(false);
                setOtherLaborType(false);
            } else {
                setOtherLaborType(true);
                setIndependent(false);
                setSalaried(false);
                handleChangeSelect({ target: { name: "laborContract", value: "" } });
            }
        } else {
            setSalaried(true);
            setIndependent(false);
            setOtherLaborType(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form.laborType]);

    useEffect(() => {
        if (form.residenceCountry) {
            if (form.residenceCountry !== "CO") {
                handleChangeSelect({ target: { name: "stateCountry", value: "" } });
                handleChangeSelect({ target: { name: "residenceCity", value: "" } });
            }

            let temp = stateCountriesTemp.filter(item => item.IdCountry === form.residenceCountry);
            if (temp) setStateCountries(temp);
            else setStateCountries([]);
        } else {
            setStateCountries([]);
            handleChangeSelect({ target: { name: "stateCountry", value: "" } });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form.residenceCountry, stateCountriesTemp]);

    useEffect(() => {
        if (form.stateCountry && stateCountries.length > 0) {
            let mt = stateCountries.find(sc => sc.value === form.stateCountry).Municipalities;
            setMunicipalities(mt);

            if (form.residenceCity && !mt.find(el => el.value === form.residenceCity)) {
                handleChangeSelect({ target: { name: "residenceCity", value: "" } });
            }
        } else {
            setMunicipalities([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form.stateCountry, stateCountries]);

    const submitForm = (form, error) => {
        form.income = String(form.income).replaceAll(",", "").replaceAll(".", "").replaceAll("$", "");
        form.expenses = String(form.expenses).replaceAll(",", "").replaceAll(".", "").replaceAll("$", "");

        if (!error) {
            setSending(true);

            let body = {
                cusType: "REGP",
                cusAddress: form.address,
                cusLaborCompany: form.company,
                cusEconomicLevel: form.economicLevel,
                cusMaritalStatus: form.maritalStatus,
                cusEducationLevel: form.educationalLevel,
                cusHousingType: form.housingType,
                cusLaborType: form.laborType,
                cusLaborContract: form.laborContract,
                cusLaborSalary: form.income,
                cusAddressCountry: form.residenceCountry,
                cusAddressCity: form.residenceCity ? String(form.residenceCity) : "",
                cusAddressState: form.stateCountry ? String(form.stateCountry) : "",
                cusLaborAntiquity: form.laborAntiquity,
                cusNumDependents: form.dependents,
                cusTotalExpenses: form.expenses,
                cusConditionsAcceptance: true,
            };

            newCustomer(body).then(res => {
                setSending(false);

                if (res.status === 201) {
                    setStorage("request", {
                        ...request,
                        cusAddress: form.address,
                        cusEconomicLevel: form.economicLevel,
                        cusMaritalStatus: form.maritalStatus,
                        cusEducationLevel: form.educationalLevel,
                        cusHousingType: form.housingType,
                        cusLaborType: form.laborType,
                        cusLaborContract: form.laborContract,
                        cusLaborSalary: form.income,
                        cusAddressCountry: form.residenceCountry,
                        cusAddressCity: form.residenceCity ? String(form.residenceCity) : "",
                        cusAddressState: form.stateCountry ? String(form.stateCountry) : "",
                        cusDependents: form.dependents,
                        cusExpenses: form.expenses,
                        cusLaborAntiquity: form.laborAntiquity,
                        cusLaborCompany: form.company,
                        state: request.comingFromDashboard ? 9 : 14,
                    });

                    if (request.comingFromDashboard) {
                        navigate("/");
                    } else {
                        handleChangeStep(5);
                        // navigate("/QuestionsVerification");
                    }
                } else {
                    MySwal.fire({
                        html: res.data.message,
                        icon: 'error',
                        customClass: {
                            container: 'swal2-container'
                        }
                    });
                }
            });
        } else {
            MySwal.fire({
                html: "Completa todos los campos",
                icon: 'warning',
                customClass: {
                    container: 'swal2-container'
                }
            });
        }
    };

    return (
        <CustomBox
            sx={{
                width: '80%'
            }}
        >

            <Grid item xs={12} sm={12} md={12} style={{ textAlign: "right", marginTop: "0px", paddingTop: "0px" }}>
                <button style={{ border: "none", backgroundColor: "transparent", fontWeight: "bold", fontSize: "30px", cursor: "pointer", marginTop: "0px" }} onClick={handleCloseModal} ><Iconify sx={{ width: "30px", height: "30px" }} icon="eva:close-outline" /></button>

            </Grid>
            <form onSubmit={(e) => handleSubmit(e, submitForm)}>
                <Box sx={{ width: { xs: '100%', sm: '80%', md: '60%' }, margin: '0 auto' }}>

                    <Grid container spacing={2}>
                        <Grid item xs={12} sx={{ textAlign: 'center' }}>
                            <Typography variant="body2" color="black" sx={{ fontSize: '13px' }}>
                                {request.cusFirstName} {request.cusSecondName} {request.cusFirstSurname} {request.cusSecondSurname}
                            </Typography>
                            <Typography variant="body2" color="gray" sx={{ fontSize: '13px' }}>
                                {request.cusDocumentTypeTemp} {request.cusDocument}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'center' }}>
                            <Typography variant="h5" color="gray">
                                <b style={{ color: 'black' }}>3</b>/4
                            </Typography>
                        </Grid>
                        {/* Nivel educativo y Estado civil */}
                        <Grid item xs={12} md={6}>
                            <CustomSelect
                                ref={educationalLevelRef}
                                name="educationalLevel"
                                value={form.educationalLevel}
                                options={educationalLevels}
                                text="Nivel educativo"
                                error={errors.educationalLevel}
                                handleChangeSelect={handleChangeSelect}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <CustomSelect
                                ref={maritalStatusRef}
                                name="maritalStatus"
                                value={form.maritalStatus}
                                options={maritalStatuses}
                                text="Estado civil"
                                error={errors.maritalStatus}
                                handleChangeSelect={handleChangeSelect}
                            />
                        </Grid>
                        {/* Tipo de vivienda y Estrato */}
                        <Grid item xs={12} md={6}>
                            <CustomSelect
                                ref={housingTypeRef}
                                name="housingType"
                                value={form.housingType}
                                options={housingTypes}
                                text="Tipo de vivienda"
                                error={errors.housingType}
                                handleChangeSelect={handleChangeSelect}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <CustomSelect
                                ref={economicLevelRef}
                                name="economicLevel"
                                value={form.economicLevel}
                                options={economicLevels}
                                text="Estrato"
                                error={errors.economicLevel}
                                handleChangeSelect={handleChangeSelect}
                            />
                        </Grid>
                        {/* Personas a cargo y País de residencia */}
                        <Grid item xs={12} md={6}>
                            <CustomSelect
                                ref={dependentsRef}
                                name="dependents"
                                value={form.dependents}
                                options={dependents}
                                text="Personas a cargo"
                                error={errors.dependents}
                                handleChangeSelect={handleChangeSelect}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <CustomSelect
                                name="residenceCountry"
                                value={form.residenceCountry}
                                options={countries.map(el => { return { value: el.value, name: el.label } })}
                                text="País de residencia"
                                error={errors.residenceCountry}
                                handleChangeSelect={handleChangeSelect}
                            />
                        </Grid>
                        {/* Departamento y Ciudad */}
                        <Grid item xs={12} md={6}>
                            <CustomSelect
                                ref={stateCountryRef}
                                name="stateCountry"
                                disabled={stateCountries.length === 0}
                                value={form.stateCountry}
                                options={stateCountries}
                                text="Departamento de residencia"
                                error={errors.stateCountry}
                                handleChangeSelect={handleChangeSelect}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <CustomSelect
                                ref={residenceCityRef}
                                name="residenceCity"
                                disabled={municipalities.length === 0}
                                value={form.residenceCity}
                                options={municipalities}
                                text="Ciudad"
                                error={errors.residenceCity}
                                handleChangeSelect={handleChangeSelect}
                            />
                        </Grid>
                        {/* Dirección */}
                        <Grid item xs={12}>
                            <TextField
                                inputRef={addressRef}
                                name="address"
                                value={form.address}
                                onChange={handleChange}
                                label="Dirección"
                                variant="outlined"
                                fullWidth
                                error={!!errors.address}
                                helperText={errors.address}
                                autoComplete="off"
                            />
                        </Grid>
                        {/* Ocupación */}
                        <Grid item xs={12}>
                            <CustomSelect
                                ref={laborTypeRef}
                                name="laborType"
                                value={form.laborType}
                                options={laborTypes}
                                text="Ocupación"
                                error={errors.laborType}
                                handleChangeSelect={handleChangeSelect}
                            />
                        </Grid>
                        {/* Empresa / fuente de ingresos */}
                        <Grid item xs={12}>
                            <TextField
                                inputRef={companyRef}
                                name="company"
                                value={form.company}
                                onChange={handleChange}
                                label="Empresa / fuente de ingresos"
                                variant="outlined"
                                fullWidth
                                error={!!errors.company}
                                helperText={errors.company}
                                autoComplete="off"
                            />
                        </Grid>
                        {/* Tipo de contrato y Antigüedad ocupación */}
                        <Grid item xs={12} md={6}>
                            <CustomSelect
                                ref={laborContractRef}
                                name="laborContract"
                                disabled={otherLaborType}
                                value={form.laborContract}
                                options={laborContracts}
                                text="Tipo de contrato"
                                error={errors.laborContract}
                                handleChangeSelect={handleChangeSelect}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <CustomSelect
                                ref={laborAntiquityRef}
                                name="laborAntiquity"
                                value={form.laborAntiquity}
                                options={laborAntiquities}
                                text="Antigüedad ocupación"
                                error={errors.laborAntiquity}
                                handleChangeSelect={handleChangeSelect}
                            />
                        </Grid>
                        {/* Ingresos y Egresos */}
                        <Grid item xs={12} md={6}>
                            <NumericFormat
                                value={form.income}
                                onValueChange={(values) => {
                                    const { value } = values; // Valor sin formato
                                    handleChange({ target: { name: 'income', value } });
                                }}
                                thousandSeparator="."
                                decimalSeparator=","
                                prefix="$"
                                customInput={TextField}
                                label="Ingresos"
                                variant="outlined"
                                fullWidth
                                error={!!errors.income}
                                helperText={errors.income}
                            />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <NumericFormat
                                value={form.expenses}
                                onValueChange={(values) => {
                                    const { value } = values; // Valor sin formato
                                    handleChange({ target: { name: 'expenses', value } });
                                }}
                                thousandSeparator="."
                                decimalSeparator=","
                                prefix="$"
                                customInput={TextField}
                                label="Egresos"
                                variant="outlined"
                                fullWidth
                                error={!!errors.expenses}
                                helperText={errors.expenses}
                            />
                        </Grid>
                        {/* Checkboxes de políticas */}
                        {request.comingFromDashboard && (
                            <>
                                <Grid item xs={12}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={form.dataPrivacyCheck}
                                                onChange={handleChecked}
                                                name="dataPrivacyCheck"
                                            />
                                        }
                                        label={
                                            <>
                                                Acepto la{' '}
                                                <span
                                                    style={{ color: 'blue', cursor: 'pointer', textDecoration: 'underline' }}
                                                    onClick={() => setShowPersonalDataDialog(true)}
                                                >
                                                    política de privacidad y tratamiento de datos personales
                                                </span>
                                            </>
                                        }
                                    />
                                    {errors.dataPrivacyCheck && (
                                        <Typography color="error" variant="body2">
                                            {errors.dataPrivacyCheck}
                                        </Typography>
                                    )}
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={form.dataTreatmentCheck}
                                                onChange={handleChecked}
                                                name="dataTreatmentCheck"
                                            />
                                        }
                                        label={
                                            <>
                                                Acepto el{' '}
                                                <span
                                                    style={{ color: 'blue', cursor: 'pointer', textDecoration: 'underline' }}
                                                    onClick={() => setShowPrivacyDialog(true)}
                                                >
                                                    Aviso de privacidad
                                                </span>{' '}
                                                y a ser contactado por los siguientes canales de cobranza:
                                                <br /> SMS, Correo electrónico, Llamada telefónica, Mensaje por aplicaciones
                                            </>
                                        }
                                    />
                                    {errors.dataTreatmentCheck && (
                                        <Typography color="error" variant="body2">
                                            {errors.dataTreatmentCheck}
                                        </Typography>
                                    )}
                                </Grid>
                            </>
                        )}
                        {/* Botones */}
                        <Grid item xs={12} sx={{ textAlign: 'center' }}>
                            <Button
                                variant="outlined"
                                color="secondary"
                                disabled={sending}
                                onClick={() => {
                                    if (request.comingFromDashboard) {
                                        setStorage("request", {
                                            ...request,
                                            state: 9,
                                        });
                                        navigate("/Dashboard");
                                    } else {
                                        setStorage("request", {
                                            ...request,
                                            state: 0,
                                            updateCustomer: true,
                                        });
                                        handleChangeStep(4);
                                        // navigate("/");
                                    }
                                }}
                                sx={{ marginRight: '20px' }}
                            >
                                Regresar
                            </Button>
                            <Button type="submit" variant="contained" color="primary" disabled={sending}>
                                Continuar
                            </Button>
                        </Grid>
                        {/* Loader */}
                        {sending && (
                            <Grid item xs={12} sx={{ textAlign: 'center' }}>
                                <CircularProgress />
                            </Grid>
                        )}
                    </Grid>
                </Box>
            </form>

            {/* Diálogo de Aviso de Privacidad */}
            <Dialog open={showPrivacyDialog} onClose={() => setShowPrivacyDialog(false)} maxWidth="md" fullWidth>
                <DialogTitle>Aviso de privacidad</DialogTitle>
                <DialogContent dividers>
                    <Privacy />
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            handleChecked({ target: { name: "dataTreatmentCheck", checked: true } });
                            setShowPrivacyDialog(false);
                        }}
                    >
                        He leído y acepto
                    </Button>
                    <Button
                        onClick={() => {
                            handleChecked({ target: { name: "dataTreatmentCheck", checked: false } });
                            setShowPrivacyDialog(false);
                        }}
                    >
                        No acepto
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Diálogo de Política de Privacidad */}
            <Dialog open={showPersonalDataDialog} onClose={() => setShowPersonalDataDialog(false)} maxWidth="md" fullWidth>
                <DialogTitle>Política de privacidad y tratamiento de datos personales</DialogTitle>
                <DialogContent dividers>
                    <PersonalData />
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            handleChecked({ target: { name: "dataPrivacyCheck", checked: true } });
                            setShowPersonalDataDialog(false);
                        }}
                    >
                        He leído y acepto
                    </Button>
                    <Button
                        onClick={() => {
                            handleChecked({ target: { name: "dataPrivacyCheck", checked: false } });
                            setShowPersonalDataDialog(false);
                        }}
                    >
                        No acepto
                    </Button>
                </DialogActions>
            </Dialog>
        </CustomBox>
    );
};

export default NewCustomerS4;
