const PersonalData = () => {

    return (<section>

        <div className="bg-mp-primary">
            <div className="container-fluid text-justify">
                <p className="fs-1 fw-bold">Política de tratamiento de datos personales</p>
            </div>
        </div>



        <div className="container">

            <div className="mt-4">
                <b>MEDDIPAY S.A.S</b> (En adelante Meddipay), identificada con <b>NIT 901763399-5</b>, sociedad inscrita en la Cámara de Comercio de Manizales por Caldas con matricula mercantil 233118, con página web www.meddipay.com.co, con el objeto de proteger el derecho constitucional del HABEAS DATA, en cumplimento de la Ley 1581 de 2012, Decreto 1074 de 2015 y demás normas concordantes, presenta a todos los clientes, usuarios y visitantes de la página web de Meddipay, la siguiente <b>POLÍTICA DE PRIVACIDAD Y TRATAMIENTO DE DATOS PERSONALES</b>, así como <b>LAS MEDIDAS DE PROTECCIÓN DE DERECHO A LA INTIMIDAD</b>.
            </div>



            <div className="my-5">
                <p className="text-mp-primary fs-3 fw-bold">
                    I) LEGISLACIÓN APLICABLE
                </p>
                <p></p>
                <p>
                    A la presente POLÍTICA DE PRIVACIDAD Y TRATAMIENTO DE DATOS PERSONALES le es aplicable lo consagrado en el artículo 15 de la Constitución Política de Colombia, Ley 1581 de 2012, Decreto 1074 de 2015 y demás normas compilatorias o complementarias.
                </p>
                <p></p>
                <p className="text-mp-primary fs-3 fw-bold">II) OBJETO</p>
                <p></p>
                <p>
                    La presente POLÍTICA DE PRIVACIDAD Y TRATAMIENTO DE DATOS PERSONALES tiene por objeto proteger y respetar el derecho constitucional del HABEAS DATA, consagrado en el artículo 15 de la Constitución Política de Colombia, por el cual todas las personas tienen derecho a conocer, actualizar y rectificar las informaciones que se hayan recogido sobre ellas en los bancos de datos y en archivos de Meddipay.

                    También tiene por objeto regular los procedimientos de recolección, almacenamiento, tratamiento y manejo de los datos personales realizados por Meddipay.
                </p>
                <p>
                    Tambi&eacute;n tiene por objeto regular los procedimientos de
                    recolecci&oacute;n, almacenamiento, tratamiento y manejo de los
                    datos personales realizados por Meddipay.
                </p>
                <p></p>
                <p className="text-mp-primary fs-3 fw-bold">III) DEFINICIONES</p>
                <p></p>
                <p>
                    A continuaci&oacute;n se indican los significados de los siguientes
                    t&eacute;rminos, conforme a la ley 1581 de 2012, Decreto 1074 de
                    2015 y dem&aacute;s normas concordantes:
                </p>
                <p></p>
                <p>
                    <b>Autorizaci&oacute;n:</b> Consentimiento previo, expreso e
                    informado del titular para llevar a cabo el tratamiento de datos
                    personales.
                </p>
                <p></p>
                <p>
                    <b>Aviso de privacidad:</b> Comunicaci&oacute;n verbal o escrita
                    (f&iacute;sica o electr&oacute;nica) generada por Meddipay, mediante
                    la cual se le informa al titular de la informaci&oacute;n acerca de
                    la existencia de las pol&iacute;ticas de tratamiento de
                    informaci&oacute;n que le ser&aacute;n aplicables, la forma de
                    acceder a las mismas y las finalidades del tratamiento que se
                    pretende dar a los datos personales.
                </p>
                <p></p>
                <p>
                    <b>Base de Datos:</b> Conjunto organizado de datos personales que
                    sea objeto de tratamiento.
                </p>
                <p></p>
                <p>
                    <b>Dato personal:</b> Cualquier informaci&oacute;n vinculada o que
                    pueda asociarse a una o varias personas naturales determinadas o
                    determinables.
                </p>
                <p></p>
                <p>
                    <b>Dato privado:</b> Es el dato que por su naturaleza &iacute;ntima
                    o reservada s&oacute;lo es relevante para el titular
                </p>
                <p></p>
                <p>
                    <b>Dato p&uacute;blico:</b> Son considerados datos p&uacute;blicos,
                    entre otros, los datos relativos al estado civil de las personas, a
                    su profesi&oacute;n u oficio y a su calidad de comerciante o de
                    servidor p&uacute;blico. Por su naturaleza, los datos
                    p&uacute;blicos pueden estar contenidos, entre otros, en registros
                    p&uacute;blicos, documentos p&uacute;blicos, gacetas y boletines
                    oficiales y sentencias judiciales debidamente ejecutoriadas que no
                    est&eacute;n sometidas a reserva.
                </p>
                <p></p>
                <p>
                    <b>semiprivado:</b> Es el dato que es de inter&eacute;s para el
                    titular y/o para un grupo de personas o para la sociedad en general,
                    por lo cual, no es &iacute;ntimo, reservado o p&uacute;blico.
                </p>
                <p></p>
                <p>
                    <b>Datos sensibles:</b> Se entiende por datos sensibles aquellos que
                    afectan la intimidad del titular o cuyo uso indebido puede generar
                    su discriminaci&oacute;n, tales como aquellos que revelen el origen
                    racial o &eacute;tnico, la orientaci&oacute;n pol&iacute;tica, las
                    convicciones religiosas o filos&oacute;ficas, la pertenencia a
                    sindicatos, organizaciones sociales, de derechos humanos o que
                    promueva intereses de cualquier partido pol&iacute;tico o que
                    garanticen los derechos y garant&iacute;as de partidos
                    pol&iacute;ticos de oposici&oacute;n, as&iacute; como los datos
                    relativos a la salud, a la vida sexual, y los datos
                    biom&eacute;tricos.
                </p>
                <p></p>
                <p>
                    <b>Encargado del tratamiento:</b> Persona natural o jur&iacute;dica,
                    p&uacute;blica o privada, que por s&iacute; misma o en asocio con
                    otros, realice el tratamiento de datos personales por cuenta del
                    responsable del tratamiento.
                </p>
                <p></p>
                <p>
                    <b>Habeas data:</b> El derecho que tienen todas las personas a
                    conocer, actualizar y rectificar las informaciones que se hayan
                    recogido sobre ellas en los bancos de datos y en archivos de
                    entidades p&uacute;blicas y privadas.
                </p>
                <p></p>
                <p>
                    <b>Responsable del tratamiento:</b> Persona natural o
                    jur&iacute;dica, p&uacute;blica o privada, que por s&iacute; misma o
                    en asocio con otros, decida sobre la base de datos y/o el
                    tratamiento de los datos.
                </p>
                <p></p>
                <p>
                    <b>Titular:</b> Persona natural cuyos datos personales sean objeto
                    de tratamiento.
                </p>
                <p></p>
                <p>
                    <b>Transferencia:</b> La transferencia de datos tiene lugar cuando
                    el responsable y/o encargado del tratamiento de datos personales,
                    ubicado en Colombia, env&iacute;a la informaci&oacute;n o los datos
                    personales a un receptor, que a su vez es responsable del
                    tratamiento y se encuentra dentro o fuera del pa&iacute;s.
                </p>
                <p></p>
                <p>
                    <b>Transmisi&oacute;n:</b> Tratamiento de datos personales que
                    implica la comunicaci&oacute;n de los mismos dentro o fuera del
                    territorio de la Rep&uacute;blica de Colombia cuando tenga por
                    objeto la realizaci&oacute;n de un tratamiento por el Encargado por
                    cuenta del responsable.
                </p>
                <p></p>
                <p>
                    <b>Tratamiento:</b> Cualquier operaci&oacute;n o conjunto de
                    operaciones sobre datos personales, tales como la
                    recolecci&oacute;n, almacenamiento, uso, circulaci&oacute;n o
                    supresi&oacute;n.
                </p>
                <p></p>
                <p>
                    <b>Canales autorizados:</b> Son los canales autorizados por los consumidores para ser contactados por las entidades vigiladas por la Superintendencia Financiera y todas las personas naturales y jurídicas que ejerzan actividades de cobranza, los cuales deberán ser informados y socializados previamente por parte de las entidades de cobranza con el fin de que los consumidores elijan cuáles autoriza.
                </p>
                <p></p>
                <p></p>
                <p className="text-mp-primary fs-3 fw-bold">IV) PRINCIPIOS</p>
                <p></p>
                <p>
                    Meddipay dar&aacute; cumplimiento a la normativa legal vigente en
                    materia de tratamiento de datos, y en especial, atender&aacute; a
                    los siguientes principios:
                </p>
                <p>- Principio de veracidad o calidad de los registros o datos.</p>
                <p>- Principio de finalidad.</p>
                <p>- Principio de circulaci&oacute;n restringida.</p>
                <p>- Principio de temporalidad de la informaci&oacute;n.</p>
                <p>
                    - Principio de interpretaci&oacute;n integral de derechos
                    constitucionales.
                </p>
                <p>- Principio de seguridad.</p>
                <p>- Principio de confidencialidad.</p>
                <p>- Principio de libertad.</p>
                <p>- Principio de transparencia.</p>
                <p>- Principio de legalidad en materia de tratamiento de datos.</p>
                <p></p>
                <p className="text-mp-primary fs-3 fw-bold">V) FINALIDADES</p>
                <p></p>
                <p>
                    Meddipay recolecta, almacena, tramita y analiza los datos con las
                    siguientes finalidades:
                </p>
                <p></p>
                <p>
                    <br />
                    <b
                    >Finalidades en el tratamiento de datos de visitantes/usuarios de <a data-fr-linked="true" target="_blank" href={process.env.REACT_APP_URL_SKAL}>www.meddipay.com.co</a> y sus otros subdominios.</b
                    >
                </p>
                <p></p>
                <p>
                    1. Almacenar, clasificar y catalogar la informaci&oacute;n de
                    navegaci&oacute;n en las bases de datos de Meddipay.
                </p>
                <p>
                    2. Correcto funcionamiento de las p&aacute;ginas web que son del
                    dominio de Meddipay.
                </p>
                <p>
                    3. Asegurar el correcto uso de las p&aacute;ginas web que son del
                    dominio de Meddipay por parte de usuarios y/o visitantes, e
                    identificar cualquier fraude o uso indebido de las mismas.
                </p>
                <p>
                    4. Brindar al usuario de la web una mejor experiencia de
                    navegaci&oacute;n, contenido e informaci&oacute;n que se ajusten a
                    sus preferencias, a trav&eacute;s de la autenticaci&oacute;n y
                    personalizaci&oacute;n de la navegaci&oacute;n del usuario.
                </p>
                <p>
                    5. Brindar seguridad al usuario al momento de la navegaci&oacute;n.
                </p>
                <p>
                    6. An&aacute;lisis del rendimiento del sitio web principal y de los
                    otros dominios de Meddipay.
                </p>
                <p></p>
                <p>
                    <br />
                    <b
                    >Finalidades en el tratamiento de datos de clientes/usuarios de
                        Meddipay</b
                    >
                </p>
                <p></p>
                <p>
                    1. Almacenar, clasificar y catalogar los datos personales en las
                    bases de datos de Meddipay.
                </p>
                <p>
                    2. Dar cumplimiento a las obligaciones contra&iacute;das con
                    nuestros usuarios y/o clientes.
                </p>
                <p>3. Prestar los servicios de forma correcta.</p>
                <p>4. Ordenar y clasificar los datos personales suministrados.</p>
                <p>
                    5. Verificar los datos personales suministrados, para evitar fraudes
                    o suplantaci&oacute;n de identidad.
                </p>
                <p>
                    6. Creaci&oacute;n y gesti&oacute;n de los perfiles y/o cuentas de
                    los usuarios y/o clientes.
                </p>
                <p>
                    7. Mantener contacto y comunicaci&oacute;n con los titulares de los
                    datos, para fines comerciales y legales.
                </p>
                <p>
                    8. Entablar comunicaci&oacute;n con los usuarios y/o clientes, a
                    trav&eacute;s de llamadas, mensajes de texto, correo
                    electr&oacute;nico o a trav&eacute;s de cualquier otro medio de
                    comunicaci&oacute;n f&iacute;sico o digital.
                </p>
                <p>9. Para gesti&oacute;n administrativa, contable y fiscal.</p>
                <p>
                    10. Analizar el perfil crediticio y de riesgo de nuestros clientes
                    y/o usuarios.
                </p>
                <p>
                    11. Consultar a las centrales de informaci&oacute;n de riesgo,
                    crediticia y comercial, el historial crediticio de los clientes y/o
                    usuarios, para conocer su comportamiento como deudor, capacidad de
                    pago y para medir el riesgo de celebrar contrato de mutuo o
                    cualquier otro tipo de relaci&oacute;n contractual.
                </p>
                <p>
                    12. Reportar a las centrales de riesgo incumplimiento de los
                    usuarios y/o clientes de sus obligaciones contractuales.
                </p>
                <p>
                    13. Verificar el cumplimiento de las obligaciones de nuestros
                    clientes y/o usuarios.
                </p>
                <p>
                    14. Compartir con terceros, incluyendo la transferencia y
                    transmisi&oacute;n (inclusive internacional), los datos personales
                    de los usuarios y/o clientes.
                </p>
                <p>
                    15. Recibir informaci&oacute;n de terceros sobre nuestros clientes
                    y/o usuarios.
                </p>
                <p>
                    16. Suministrar o recibir a las centrales de informaci&oacute;n
                    datos relacionados con las solicitudes de celebraci&oacute;n de
                    contrato de mutuo.
                </p>
                <p>
                    17. Presentar reportes o informes ante las autoridades de
                    inspecci&oacute;n, vigilancia y control, o atender requerimientos de
                    estas.
                </p>
                <p>
                    18. Enviar información relacionada con los servicios contratados e información comercial de otros servicios ofrecidos por Meddipay o nuevos servicios disponibles.
                </p>
                <p>
                    19. Gestión comercial y fidelización de clientes.
                </p>
                <p>
                    20. Verificar la existencia o reporte en listas restrictivas y de riesgo reputacional.
                </p>
                <p>
                    21. Consultar y acceder a información de seguridad social, incluyendo ingresos y datos de empleo, facturación y nómina electrónica, a través de bases de datos públicas o privadas tales como los operadores PILA y agremiaciones.
                </p>
                <p></p>
                <p>
                    <br />
                    <b
                    >Finalidades en el tratamiento de datos de candidatos y
                        empleados/trabajadores de Meddipay</b
                    >
                </p>
                <p></p>
                <p>
                    1. Analizar y verificar la informaci&oacute;n suministrada por los
                    candidatos en los procesos en los procesos de selecci&oacute;n.
                </p>
                <p>
                    2. Verificar y evaluar las competencias laborales y personales de
                    los candidatos.
                </p>
                <p>
                    3. Verificar y evaluar el desempe&ntilde;o de los
                    empleados/trabajadores.
                </p>
                <p>
                    4. Transmitir la informaci&oacute;n a terceros encargados de los
                    procesos de selecci&oacute;n de candidatos (en caso de que este
                    proceso se delegue a un tercero).
                </p>
                <p>5. Cumplimiento de Meddipay de sus obligaciones como empleador.</p>
                <p>
                    6. Seguimiento del cumplimiento de deberes y obligaciones de los
                    empleados/trabajadores.
                </p>
                <p>
                    7. Gestionar y administrar correctamente lo relativo a las
                    actividades con fines de desarrollo operativo.
                </p>
                <p>
                    8. Realizar control y seguimiento de las relaciones laborales entre
                    el titular y Meddipay.
                </p>
                <p>
                    9. Establecer comunicaci&oacute;n con los candidatos y empleados, o
                    con alg&uacute;n contacto en caso de emergencia.
                </p>
                <p>
                    10. Afiliaci&oacute;n de los empleados/trabajadores al sistema de
                    seguridad social.
                </p>
                <p>
                    11. Realizar todas las gestiones relacionadas a recursos humanos.
                </p>
                <p>
                    12. Presentar reportes o informes, o atender requerimientos de las
                    autoridades.
                </p>
                <p>13. Cumplimiento de obligaciones legales.</p>
                <p>
                    14. Protecci&oacute;n de la privacidad de Meddipay, de sus
                    usuarios/clientes, trabajadores y candidatos, como por ejemplo,
                    protecci&oacute;n de la informaci&oacute;n y bases de datos.
                </p>
                <p>
                    15. Tramitar y elaborar certificaciones relativas a las relaciones
                    laborales.
                </p>
                <p>
                    16. Conservaci&oacute;n de la informaci&oacute;n en los
                    t&eacute;rminos establecidos en la Ley.
                </p>
                <p></p>
                <p className="text-mp-primary fs-3 fw-bold">VI) AUTORIZACI&Oacute;N</p>
                <p></p>
                <p>
                    Meddipay, como responsable del tratamiento de datos personales, debe cumplir con los requisitos constitucionales y legales de la autorización y consentimiento de los titulares de los datos. Por eso, se garantiza que el consentimiento del titular sea previo, expreso e informado.

                    Por ende, en la navegación de página web de Meddipay, de sus otros dominios, y en todos los procesos que impliquen recolección de datos personales, se tiene en cuenta el consentimiento previo, expreso e informado del titular.
                </p>
                <p>
                    Por ende, en la navegaci&oacute;n de p&aacute;gina web de Meddipay,
                    de sus otros dominios, y en todos los procesos que impliquen
                    recolecci&oacute;n de datos personales, se tiene en cuenta el
                    consentimiento previo, expreso e informado del titular.
                </p>
                <p></p>
                <p></p>
                <p className="text-mp-primary fs-3 fw-bold">
                    VII) DERECHOS DE LOS TITULARES
                </p>
                <p></p>
                <p>Los titulares de los datos cuentan con los siguientes derechos:</p>
                <p></p>
                <p>
                    1. Conocer, actualizar y rectificar sus datos personales objeto del
                    tratamiento.
                </p>
                <p>
                    2. Solicitar prueba de la autorizaci&oacute;n otorgada a Meddipay.
                </p>
                <p>
                    3. Ser informado del uso que hace Meddipay de sus datos personales.
                </p>
                <p>
                    4. Revocar la autorizaci&oacute;n y/o solicitar la supresi&oacute;n
                    de datos, en caso de que no exista obligaci&oacute;n legal o
                    contractual que haga necesario conservar el almacenamiento de datos.
                </p>
                <p>
                    5. Oponerse a que los datos personales sean objeto de tratamiento.
                </p>
                <p>
                    Presentar quejas y reclamos ante la Superintendencia de Industria y
                    Comercio, en caso de infracciones o incumplimiento a la Ley 1581 de
                    2012 y dem&aacute;s normas concordantes.
                </p>
                <p></p>
                <p className="text-mp-primary fs-3 fw-bold">
                    VIII) PROCEDIMIENTO PARA EL EJERCICIO DE LOS DERECHOS
                </p>
                <p></p>
                <p><b>Quejas o reclamos/solicitudes</b></p>
                <p></p>
                <p>
                    Sin ning&uacute;n tipo de restricci&oacute;n, y de manera gratuita
                    el titular, su representante o sus causahabientes, podr&aacute;n
                    requerir de manera escrita, la rectificaci&oacute;n,
                    actualizaci&oacute;n o supresi&oacute;n de sus datos personales, y/o
                    presentar solicitudes acreditando la identidad del titular o la
                    calidad en que act&uacute;a.
                </p>
                <p></p>
                <p>
                    Meddipay cuenta con el t&eacute;rmino de quince (15) d&iacute;as
                    h&aacute;biles para dar respuesta, contados a partir del d&iacute;a
                    siguiente en que se recibi&oacute; la queja, reclamo o solicitud.
                </p>
                <p></p>
                <p>
                    Cuando no fuere posible atender el reclamo dentro del anterior
                    t&eacute;rmino, se informar&aacute; al interesado los motivos de la
                    demora y la fecha en que se atender&aacute; la queja o reclamo,
                    solicitud, la cual en ning&uacute;n caso superar&aacute; los ocho
                    (8) d&iacute;as h&aacute;biles siguientes al vencimiento del primer
                    t&eacute;rmino.
                </p>
                <p>
                    En caso de que la queja o reclamo, solicitud, resulte incompleto, se
                    requerir&aacute; al interesado dentro de los cinco (5) d&iacute;as
                    siguientes a la recepci&oacute;n de la queja y/o reclamo para que
                    subsane las fallas. Transcurridos dos (2) meses desde la fecha del
                    requerimiento sin que el solicitante presente la informaci&oacute;n
                    requerida, Meddipay entender&aacute; desistida la queja o reclamo,
                    solicitud.
                </p>
                <p></p>
                <p>
                    Las quejas, reclamos y/o solicitudes deben ser remitidas al
                    siguiente canal de atenci&oacute;n: <a data-fr-linked="true" href="mailto:hola@meddipay.com">hola@meddipay.com</a>
                </p>
                <p></p>
                <p><b>Consultas</b></p>
                <p></p>
                <p>
                    El titular, su representante o sus causahabientes, de acuerdo a lo
                    estipulado en la Ley, podr&aacute;n presentar consultas de la
                    informaci&oacute;n, acreditando la identidad del titular o la
                    calidad en que act&uacute;a.
                </p>
                <p></p>
                <p>
                    La consulta ser&aacute; atendida en un t&eacute;rmino m&aacute;ximo
                    de diez (10) d&iacute;as h&aacute;biles contados a partir de la
                    fecha de recibo de la misma.
                </p>
                <p></p>
                <p>
                    Cuando no fuere posible atender la consulta dentro de dicho
                    t&eacute;rmino, se informar&aacute; al interesado, expresando los
                    motivos de la demora y se&ntilde;alando la fecha en que se
                    atender&aacute; su consulta, la cual en ning&uacute;n caso
                    podr&aacute; superar los cinco (5) d&iacute;as h&aacute;biles
                    siguientes al vencimiento del primer t&eacute;rmino.
                </p>
                <p></p>
                <p>
                    Las consultas deben ser remitidas al siguiente canal de
                    atenci&oacute;n: <a data-fr-linked="true" href="mailto:hola@meddipay.com">hola@meddipay.com</a>
                </p>

                <p className="text-mp-primary fs-3 fw-bold">IX) COOKIES</p>

                <p>
                    Meddipay hace uso en su p&aacute;gina web principal y sus otros
                    subdominios de cookies. Las cookies son un peque&ntilde;o fragmento
                    de texto que se almacenan en el navegador cuando se visita la
                    p&aacute;gina web, las cuales permiten recolectar informaci&oacute;n
                    sobre los h&aacute;bitos de navegaci&oacute;n de los usuarios.
                    Meddipay utiliza cookies propias y de terceros para brindar una
                    mejor experiencia de navegaci&oacute;n.
                </p>
                <p></p>
                <p>
                    Meddipay almacena informaci&oacute;n de los visitantes de la
                    p&aacute;gina web, independientemente de si han o no iniciado
                    sesi&oacute;n. El uso de las cookies se hace en cumplimiento de la
                    Ley 1581 de 2012, sus dem&aacute;s normas concordantes y conforme a
                    la POL&Iacute;TICA y ACEPTACI&Oacute;N DE COOKIES.
                </p>
                <p></p>
                <p>
                    En cualquier momento el usuario podr&aacute; modificar, restringir o
                    deshabilitar la configuraci&oacute;n de las cookies del sitio web de
                    Meddipay y sus dominios desde el navegador.
                </p>
                <p></p>
                <p>
                    Para mayor informaci&oacute;n en cuanto al uso de cookies por parte
                    de Meddipay, puede consultar la <a target="_blank" href="https://www.meddipay.com.co/cookies">Pol&iacute;tica y aceptaci&oacute;n de cookies</a>
                </p>
                <p></p>
                <p className="text-mp-primary fs-3 fw-bold">
                    X) TRATAMIENTO DE DATOS PERSONALES SENSIBLES
                </p>
                <p></p>
                <p>
                    Meddipay no recolectará, almacenará o tratará datos sensibles, a menos que sea necesario. Estos datos serán tratados con cuidado y garantizando la debida seguridad.

                    En caso de que Meddipay sea responsable del tratamiento de datos sensibles, se entenderá que el titular acepta el tratamiento de estos al autorizar la presente política, el aviso de privacidad de tratamiento de datos personales y la autorización para tratamiento de datos.
                </p>
                <p></p>
                <p>
                    En caso de que Meddipay sea responsable del tratamiento de datos
                    sensibles, se entender&aacute; que el titular acepta el tratamiento
                    de estos al autorizar la presente pol&iacute;tica, el aviso de
                    privacidad de tratamiento de datos personales y la
                    autorizaci&oacute;n para tratamiento de datos
                </p>
                <p></p>
                <p className="text-mp-primary fs-3 fw-bold">
                    XI) TRANSMISI&Oacute;N Y/O TRANSFERENCIA&nbsp;
                </p>
                <p></p>
                <p>
                    Bajo la debida protección de los derechos de los titulares de la información, Meddipay podrá compartir los datos suministrados por los titulares con terceros, para el desarrollo de las actividades y el cumplimiento de su objeto.

                    Meddipay podrá hacer transmisión y/o transferencia internacional de datos. En caso de hacerlo, se hará a países que cumplan en debida forma con la protección de datos personales.
                    <br /><br />
                    Los titulares de la información, al momento de aceptar la presente política de tratamiento de datos, conocen y aceptan que la transmisión y/o transferencia de datos personales es necesaria para la correcta ejecución de las actividades de Meddipay.
                </p>
                <p></p>
                
                <p></p>
                <p></p>
                <p className="text-mp-primary fs-3 fw-bold">
                    XII) CANALES DE COMUNICACIÓN
                </p>
                <p></p>
                <p>
                    En virtud de la LEY 2300 DE 2023, Meddipay solicita autorización previa y a elección del usuario/cliente, acerca de los canales de comunicación a través de los cuales acepta ser contactado para el envío o entrega de información comercial o para ejercer actividades de cobranza.


                    <br /><br />
                    En cumplimiento de la normatividad mencionada, MEDDIPAY se abstendrá de: a) Utilizar medios o canales distintos a los elegidos por los clientes para ser contactados, salvo el cumplimiento de una obligación legal o reglamentaria; b) Contactar o enviar información por fuera de los horarios establecidos, esto es de lunes a viernes de 7:00 a.m. a 7:00 p.m., y sábados de 8:00 a.m. a 3:00 p.m. Los domingos y festivos no habrá lugar a realizar campañas comerciales ni de cobranza; c) Contactar en más de una ocasión por el mismo canal durante el mismo día o mediante varios canales dentro de una misma semana; d) Adelantar gestiones de cobranza mediante visitas domiciliarias o lugar de trabajo del consumidor financiero o de servicios.

                    <br />
                    <br />
                    En caso de que el usuario/cliente requiera ser contactado en horarios distintos a los definidos, deberá manifestarlo expresamente a través del correo electrónico <a data-fr-linked="true" href="mailto:hola@meddipay.com">hola@meddipay.com</a> o al celular <a target="_blank" rel="noreferrer" href={"https://wa.me/" + encodeURI("+573011633330")}>+57 3011633330</a>.
                </p>
                <p></p>
                <p className="text-mp-primary fs-3 fw-bold">
                    XIII) SEGURIDAD DE LA INFORMACI&Oacute;N
                </p>
                <p></p>
                <p>
                    En cumplimiento del principio de seguridad consagrado en la Ley 1581 de 2012, Meddipay implementará y ejecutará todas las medidas técnicas y humanas que sean necesarias para que la información sea almacenada y tratada con el debido cuidado y seguridad, para evitar adulteración, pérdida, acceso o uso indebido o no autorizado.
                </p>
                <p></p>


                <p className="text-mp-primary fs-3 fw-bold">XIV) RESPONSABLE DEL TRATAMIENTO DE DATOS</p>
                <p></p>
                <p>Meddipay S.A.S</p>
                <p></p>
                <p>NIT <b>901763399-5</b></p>
                <p></p>
                <p>Direcci&oacute;n <b>Carrera 14 #76-65, Oficina 302, Bogotá.</b></p>
                <p></p>
                <p>
                    Correo electr&oacute;nico <a data-fr-linked="true" href="mailto:hola@meddipay.com">hola@meddipay.com</a>
                </p>
                <p></p>
                <p>Teléfono <a target="_blank" rel="noreferrer" href={"https://wa.me/" + encodeURI("+573011633330")}>+57 3011633330</a></p>
                <p></p>
                <p className="text-mp-primary fs-3 fw-bold">XV) VIGENCIA</p>
                <p></p>
                <p>
                    La presente POLÍTICA DE PRIVACIDAD Y TRATAMIENTO DE DATOS PERSONALES rige a partir del 25/07/2024.


                    <br /><br />
                    Meddipay se reserva el derecho de modificar la presente POLÍTICA DE PRIVACIDAD Y TRATAMIENTO DE DATOS PERSONALES en cualquier tiempo.
                </p>
            </div>
        </div>
    </section>)
}

export default PersonalData;