import React, { useEffect, useState } from "react";

import { useCreditRequestAuth } from "../Context/useCreditRequestAuth";

import TarsService from '../services/TarsService';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import useForm from "../hooks/useForm";
import CustomBox from 'src/components/CustomBox';
import {
    Box,
    Button,
    Typography,
    Grid,
    CircularProgress,
    Modal,
    Slider,
} from '@mui/material';
import Iconify from '../components/iconify';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

const QuestionsVerification = ({ handleChangeStep, handleCloseModal }) => {


    const [sending, setSending] = useState(false);
    const [questionsData, setQuestionsData] = useState(null);

    const { getStorage, setStorage } = useCreditRequestAuth();

    let request = getStorage("request")
    const MySwal = withReactContent(Swal);
    const { GetQuestions, SetAnswers } = TarsService();
    const initialForm = { "1": "", "2": "", "3": "", "4": "" }

    const validateForm = (form) => {
        let errors = {};
        if (!form["1"]) { errors.pregunta1 = "El campo 1 es requerido" }
        if (!form["2"]) { errors.pregunta2 = "El campo 2 es requerido" }
        if (!form["3"]) { errors.pregunta3 = "El campo 3 es requerido" }
        if (!form["4"]) { errors.pregunta4 = "El campo 4 es requerido" }
        return errors;
    }
    const { form, errors, handleChange, handleSubmit, resetForm, } = useForm(initialForm, validateForm);

    useEffect(() => {
        getQuestions();

    }, []);


    const getQuestions = () => {
        resetForm(initialForm);
        setQuestionsData(null)
        setSending(true);
        GetQuestions({
            identificacion: request.cusDocument,
            primerApellido: request.cusFirstSurname,
            tipoIdentificacion: request.cusDocumentType,
            segundoApellido: request.cusSecondSurname,
            nombres: request.cusFirstName + " " + request.cusSecondName
        }).then(res => {
            setSending(false);

            if (res.status === 200) {

                let data = JSON.parse(res.data.data)

                // data = { "resultado_validacion": "05", "resultado_preguntas": "01", "id": "50220213", "registro": "4766896", "preguntas": [{ "id": "1", "texto": "CUAL ES EL DEPARTAMENTO DE EXPEDICION DE SU DOCUMENTO DE IDENTIDAD?", "respuestas": [{ "id": "001", "texto": "BOYACA" }, { "id": "002", "texto": "VALLE DEL CAUCA" }, { "id": "003", "texto": "GUAINIA" }, { "id": "004", "texto": "GUAJIRA" }, { "id": "005", "texto": "CUNDINAMARCA" }, { "id": "006", "texto": "NINGUNA DE LAS ANTERIORES" }] }, { "id": "2", "texto": "EN LOS ULTIMOS 2 AÑOS (ENTRE NOVIEMBRE DE 2022 Y OCTUBRE DE 2024) SU ULTIMO(A) CREDITO DE VEHICULOS O FACTORING  CON FIDUCIARIA BOGOTA S.A. FUE CERRADO(A) / CANCELADO ENTRE:", "respuestas": [{ "id": "001", "texto": "ENTRE ENERO Y MARZO DE 2024" }, { "id": "002", "texto": "ENTRE ENERO Y MARZO DE 2023" }, { "id": "003", "texto": "ENTRE JULIO Y SEPTIEMBRE DE 2023" }, { "id": "004", "texto": "ENTRE JULIO Y SEPTIEMBRE DE 2024" }, { "id": "005", "texto": "NUNCA HE TENIDO CREDITO DE VEHICULOS O FACTORING CON LA ENTIDAD" }] }, { "id": "3", "texto": "EN OCTUBRE DE 2024 SU CREDITO DE VEHICULOS O FACTORING  CON FIDUCIARIA BOGOTA S.A.:", "respuestas": [{ "id": "001", "texto": "ESTABA ABIERTO/VIGENTE" }, { "id": "002", "texto": "ESTABA CANCELADA/SALDADA/CERRADA/INACTIVA" }, { "id": "003", "texto": "NUNCA HE TENIDO CREDITO DE VEHICULOS O FACTORING CON LA ENTIDAD" }] }, { "id": "4", "texto": "CUAL ES EL DEPARTAMENTO DE EXPEDICION DE SU DOCUMENTO DE IDENTIDAD?", "respuestas": [{ "id": "001", "texto": "CALDAS" }, { "id": "002", "texto": "CORDOBA" }, { "id": "003", "texto": "NARINO" }, { "id": "004", "texto": "NORTE DE SANTANDER" }, { "id": "005", "texto": "CESAR" }, { "id": "006", "texto": "NINGUNA DE LAS ANTERIORES" }] }] }



                if (data.resultado_validacion === "01" || data.resultado_validacion === "05") {
                    if (data.resultado_preguntas === "01") {
                        console.log("OK")
                    }
                    else if (data.resultado_preguntas === "10") {
                        MySwal.fire({
                            html: <ModalError msg={"Se han agotado el número de intentos permitidos por día"} retry={false}></ModalError>,
                            icon: 'error',
                            showCancelButton: false,
                            showConfirmButton: false,
                            showDenyButton: false,
                            allowEnterKey: false,
                            allowEscapeKey: false,
                            allowOutsideClick: false

                        })
                    }
                    else if (data.resultado_preguntas === "11") {
                        MySwal.fire({
                            html: <ModalError msg={"Se han agotado el número de intentos permitidos por este mes"} retry={false}></ModalError>,
                            icon: 'error',
                            showCancelButton: false,
                            showConfirmButton: false,
                            showDenyButton: false,
                            allowEnterKey: false,
                            allowEscapeKey: false,
                            allowOutsideClick: false

                        })
                    }
                    else if (data.resultado_preguntas === "12") {
                        MySwal.fire({
                            html: <ModalError msg={"Se han agotado el número de intentos permitidos por este año"} retry={false}></ModalError>,
                            icon: 'error',
                            showCancelButton: false,
                            showConfirmButton: false,
                            showDenyButton: false,
                            allowEnterKey: false,
                            allowEscapeKey: false,
                            allowOutsideClick: false

                        })
                    }
                    else {

                        MySwal.fire({
                            html: <ModalError msg={"No se pudieron generar las preguntas para la identificación " + request.cusDocument} retry={false}></ModalError>,
                            icon: 'error',
                            showCancelButton: false,
                            showConfirmButton: false,
                            showDenyButton: false,
                            allowEnterKey: false,
                            allowEscapeKey: false,
                            allowOutsideClick: false

                        })
                    }

                }
                else if (data.resultado_validacion === "06") {

                    MySwal.fire({
                        html: <ModalError msg={"Nombres y/o apellidos no coinciden para la identificación " + request.cusDocument} retry={false} ></ModalError>,
                        icon: 'error',
                        showCancelButton: false,
                        showConfirmButton: false,
                        showDenyButton: false,
                        allowEnterKey: false,
                        allowEscapeKey: false,
                        allowOutsideClick: false

                    })
                }
                else if (data.resultado_validacion === "07") {

                    MySwal.fire({
                        html: <ModalError msg={"Se generó un problema al validar la identificacion " + request.cusDocument} retry={false}></ModalError>,
                        icon: 'error',
                        showCancelButton: false,
                        showConfirmButton: false,
                        showDenyButton: false,
                        allowEnterKey: false,
                        allowEscapeKey: false,
                        allowOutsideClick: false

                    })
                }
                else if (data.resultado_validacion === "08") {

                    MySwal.fire({
                        html: <ModalError msg={"Se generó un problema al validar la identificacion " + request.cusDocument} retry={false}></ModalError>,
                        icon: 'error',
                        showCancelButton: false,
                        showConfirmButton: false,
                        showDenyButton: false,
                        allowEnterKey: false,
                        allowEscapeKey: false,
                        allowOutsideClick: false

                    })
                }
                else if (data.resultado_validacion === "09") {

                    MySwal.fire({
                        html: <ModalError msg={"Se generó un problema al validar la identificacion " + request.cusDocument} retry={false}></ModalError>,
                        icon: 'error',
                        showCancelButton: false,
                        showConfirmButton: false,
                        showDenyButton: false,
                        allowEnterKey: false,
                        allowEscapeKey: false,
                        allowOutsideClick: false

                    })
                }
                else if (data.resultado_validacion === "11") {

                    MySwal.fire({
                        html: <ModalError msg={"Se generó un problema al validar la identificacion " + request.cusDocument} retry={false}></ModalError>,
                        icon: 'error',
                        showCancelButton: false,
                        showConfirmButton: false,
                        showDenyButton: false,
                        allowEnterKey: false,
                        allowEscapeKey: false,
                        allowOutsideClick: false

                    })
                }

                setQuestionsData({ ...data, preguntas: data?.preguntas?.map(p => { let maxLength = 0; return { ...p, maxLength: Math.max(...p.respuestas.map(r => { return r.texto.length > maxLength ? r.texto.length : maxLength })) } }) })

            } else {

                MySwal.fire({
                    html: <ModalError msg={"Se generó un error"} retry={true}></ModalError>,
                    icon: 'error',
                    showCancelButton: false,
                    showConfirmButton: false,
                    showDenyButton: false,
                    allowEnterKey: false,
                    allowEscapeKey: false,
                    allowOutsideClick: false

                })
            }
        })
    }

    const submitForm = (form, error) => {

        if (!error) {
            setSending(true);

            let body = {
                identificacion: request.cusDocument,
                tipoIdentificacion: request.cusDocumentType,
                idCuestionario: questionsData.id,
                regCuestionario: questionsData.registro,
                respuestas: Object.entries(form).map(f => { return { idPregunta: f[0], idRespuesta: f[1] } })
            }

            SetAnswers(body).then(res => {

                if (res.status === 200) {
                    let data = JSON.parse(res.data.data)

                    if (data.aprobacion) {

                        CreateUserAgora({ token: data.token }).then(resjwt => {
                            setSending(false);
                            if (resjwt.status === 200 || resjwt.status === 201) {

                                setStorage("request", {
                                    ...request,
                                    state: 9
                                });

                                handleChangeStep(9);

                            } else {

                                MySwal.fire({
                                    html: resjwt.data.message,
                                    icon: 'error'
                                })
                            }

                        })

                    } else {
                        setSending(false);
                        MySwal.fire({
                            html: <ModalError msg={"Validación fallida"} retry={true}></ModalError>,
                            icon: 'error',
                            showCancelButton: false,
                            showConfirmButton: false,
                            showDenyButton: false,
                            allowEnterKey: false,
                            allowEscapeKey: false,
                            allowOutsideClick: false

                        })
                    }
                } else {
                    setSending(false);
                    MySwal.fire({
                        html: <ModalError msg={"Se generó un error"} retry={true}></ModalError>,
                        icon: 'error',
                        showCancelButton: false,
                        showConfirmButton: false,
                        showDenyButton: false,
                        allowEnterKey: false,
                        allowEscapeKey: false,
                        allowOutsideClick: false

                    })
                }




            })
        } else {
            MySwal.fire({
                html: "Completa todos los campos",
                icon: 'warning',
                customClass: {
                    container: 'swal2-container'
                }
            });
        }
    }

    const ModalError = ({ msg, retry }) => {


        return <>

            {msg}
            <br />
            <br />
            <table style={{ width: "100%" }}>
                <tbody>
                    <tr>
                        {retry && (<td style={{ padding: "5px" }}>
                            <Button type="button"
                                style={{ width: '250px', backgroundColor: "#00a9eb" }}
                                variant="contained"
                                onClick={() => { getQuestions(); MySwal.close() }}>Reintentar</Button>
                        </td>)}
                    </tr>

                    <tr>
                        <td style={{ padding: "5px" }}>
                            <Button

                                style={{ width: '250px', backgroundColor: "#00a9eb" }}
                                variant="contained"
                                onClick={() => {
                                    window.open("https://wa.me/" + encodeURI("+573011633330"))
                                    // MySwal.close();
                                }}>Contactar con servicio al cliente</Button></td>
                    </tr>

                    <tr>
                        <td style={{ padding: "5px" }}>
                            <Button

                                style={{ width: '250px', textDecoration: 'none', color: "#00a9eb", borderColor: "#00a9eb" }}
                                variant="outlined"
                                color="secondary"
                                onClick={() => {
                                    setStorage("request", {})
                                    handleChangeStep(0);
                                    handleCloseModal();
                                    MySwal.close();
                                }}>Finalizar proceso</Button></td>
                    </tr>

                    {/* <tr>
                        <td style={{ padding: "5px" }}>
                            <button type="button" className="btn btn-mp" onClick={() => {
                                setStorage("request", {
                                    ...request,
                                    state: 5,
                                    allowSelfieVerification: false,
                                    allowManualVerification: true,
                                    allowQuestionsVerification: false
                                });
                                MySwal.close();
                                navigate("/Verification");
                            }}>Elegir otro metodo</button><br /></td>
                    </tr> */}

                    {/* <tr>
                        <td style={{ padding: "5px" }}>
                            <button type="button" className="btn btn-mp" onClick={() => {
                                setStorage("request", {
                                    ...request,
                                    state: 0,
                                    updateCustomer: true
                                });
                                MySwal.close();
                                navigate("/");
                            }}>Modificar datos</button></td>
                    </tr> */}

                </tbody>
            </table>


        </>

    }

    return (
        <>


            <CustomBox
                sx={{
                    width: '80%',
                }}
            >
                <Grid container justifyContent="center" spacing={2} sx={{ mt: 2 }} >

                    <Grid item xs={12} sm={12} md={12} style={{ textAlign: "right", marginTop: "0px", paddingTop: "0px" }}>
                        <button style={{ border: "none", backgroundColor: "transparent", fontWeight: "bold", fontSize: "30px", cursor: "pointer", marginTop: "0px" }} onClick={handleCloseModal} ><Iconify sx={{ width: "30px", height: "30px" }} icon="eva:close-outline" /></button>

                    </Grid>
                    <form onSubmit={(e) => handleSubmit(e, submitForm)}>

                        <Grid item xs={12} sx={{ textAlign: 'center' }}>
                            <Typography variant="body2" color="black" sx={{ fontSize: '13px' }}>
                                {request.cusFirstName} {request.cusSecondName} {request.cusFirstSurname} {request.cusSecondSurname}
                            </Typography>
                            <Typography variant="body2" color="gray" sx={{ fontSize: '13px' }}>
                                {request.cusDocumentTypeTemp} {request.cusDocument}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} textAlign="center">
                            <Typography variant="h5" color="textSecondary">
                                <b style={{ color: 'black' }}>4</b>/4
                            </Typography>
                        </Grid>


                        <Grid item xs={12} textAlign="center">
                            <Typography variant="p" color="textSecondary">
                                Por motivos de seguridad, a continuación te solicitamos responder varias preguntas con el fin de validar tu identidad
                            </Typography>
                        </Grid>



                        {questionsData && questionsData.preguntas && (questionsData.preguntas.map(q => {

                            return <React.Fragment key={q.id}>

                                <Grid item xs={12} textAlign="left">
                                    <Typography variant="p" color="textSecondary">
                                        {q.texto}
                                    </Typography>
                                </Grid>


                                <Grid item xs={12} textAlign="left">
                                    <FormControl>

                                        <RadioGroup
                                            aria-labelledby="demo-radio-buttons-group-label"
                                            name="radio-buttons-group"
                                            value={form[q.id]}
                                            onChange={handleChange}
                                        >
                                            {q.respuestas.map(r => {
                                                return <FormControlLabel
                                                    key={r.id}
                                                    label={r.texto}
                                                    name={q.id}
                                                    id={"pregunta" + q.id + "_respuesta" + r.id}
                                                    value={r.id}

                                                    autoComplete="off"
                                                    required
                                                    control={<Radio />} />





                                            })
                                            }



                                        </RadioGroup>
                                    </FormControl>
                                </Grid>


                            </React.Fragment>
                        }))}


                        <Grid item xs={12} textAlign="center">
                            <Button
                                variant="outlined"
                                color="secondary"
                                sx={{ marginRight: 2 }}
                                disabled={sending}
                                onClick={() => {
                                    setStorage("request", { ...request, state: 14 })
                                    handleChangeStep(14);
                                }}
                                style={{ textDecoration: 'none' }}
                            >
                                Regresar
                            </Button>
                            <Button type="submit" variant="contained" color="primary" disabled={sending}>
                                Continuar
                            </Button>
                        </Grid>
                    </form>
                    {/* Loader */}
                    {sending && (
                        <Grid item xs={12} textAlign="center" style={{ marginTop: "50px" }}>
                            <CircularProgress />
                        </Grid>
                    )}

                </Grid>
            </CustomBox>

            {/* <table id="q-verification" >
                    <tbody>

                        <tr>
                            <td style={{ textAlign: "center", color: "black", fontSize: "13px", padding: "0px" }} colSpan={2}>
                                {request.cusFirstName} {request.cusSecondName} {request.cusFirstSurname} {request.cusSecondSurname}

                            </td>

                        </tr>
                        <tr>
                            <td style={{ textAlign: "center", color: "gray", fontSize: "13px", padding: "0px" }} colSpan={2}>

                                {request.cusDocumentTypeTemp}   {request.cusDocument}
                            </td>

                        </tr>
                        <tr>
                            <td style={{ textAlign: "center", color: "gray", paddingTop: "15px" }} colSpan={2}>
                                <h3><b style={{ color: "black" }}>4</b>/4</h3>
                                <br />
                            </td>
                        </tr>
                        <tr>
                            <td style={{ textAlign: "center", fontSize: "18px", padding: "0px", fontWeight: "bold" }} >
                                Por motivos de seguridad, a continuación te solicitamos responder varias preguntas con el fin de validar tu identidad
                                <br />
                                <br />
                            </td>
                        </tr>
                        {questionsData && questionsData.preguntas && (questionsData.preguntas.map(q => {

                            return <React.Fragment key={q.id}>
                                <tr>
                                    <td >
                                        <p>{q.texto}</p>
                                        <br />
                                    </td>
                                </tr>
                                <tr>
                                    <td >
                                        <div className="container-fluid">
                                            <div className="row" style={{ textAlign: "center" }}>
                                                {
                                                    q.respuestas.map(r => {
                                                        return <React.Fragment key={r.id}>


                                                            <div className="col" key={r.id} style={{ marginBottom: "10px" }}>
                                                                <input type="radio" className="btn-check" name={q.id} id={"pregunta" + q.id + "_respuesta" + r.id} value={r.id} onChange={handleChange} autoComplete="off" required />
                                                                <label style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", height: q.maxLength > 50 && window.innerWidth < 640 ? "150px" : q.maxLength > 50 ? "120px" : q.maxLength > 30 && window.innerWidth < 640 ? "150px" : q.maxLength > 30 ? "120px" : q.maxLength > 20 ? "80px" : "50px" }} className="btn btn-outline-secondary" htmlFor={"pregunta" + q.id + "_respuesta" + r.id}>{r.texto}</label>
                                                            </div>



                                                        </React.Fragment>
                                                    })
                                                }
                                            </div>
                                        </div>
                                        <br />     <br />
                                    </td>
                                </tr>
                            </React.Fragment>
                        }))}
                        <tr>

                            <td style={{ textAlign: "center", padding: "10px" }} colSpan={2}>
                                <Link to="/IdentityValidation" onClick={() => {
                                    setStorage("request", {
                                        ...request, state: 14
                                    })
                                }}>
                                    <button type="button" disabled={sending} className="btn btn-mp-back" style={{ marginRight: "20px" }}>Regresar</button>
                                </Link>

                                <input type="submit" value="Continuar" disabled={sending} className="btn btn-mp" />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                {sending &&
                                    (
                                        <div className="my-loader">
                                            <div className="my-loader-container">
                                                <img src={imgLoader} alt="loader"></img>

                                            </div>
                                        </div>
                                    )}
                            </td>
                        </tr>
                    </tbody>
                </table> */}





        </>

    );
}

export default QuestionsVerification;