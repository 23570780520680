import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content'
// @mui
import { Link, Stack, IconButton, InputAdornment, TextField, Checkbox, FormControlLabel } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import { useTheme } from '@mui/material/styles';
import Iconify from '../../../components/iconify';
import { getToken } from "../../../services/SoraService"


// ----------------------------------------------------------------------

export default function LoginForm() {
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [remember, setRemember] = useState(false);
  const [loading, setLoading] = useState(false);
  const MySwal = withReactContent(Swal)
  const theme = useTheme();


  const handleClick = (e) => {
    e.preventDefault();
    setLoading(true);
    if (email && password) {

      getToken({ userName: email, password, app: "AGORA" }).then(res => {
        setLoading(false);
        if (res.status === 200) {

          const data = res.data.data

          localStorage.setItem("token", data.token);
          localStorage.setItem("refreshToken", data.refreshToken);
          localStorage.setItem("expiredTime", Date.parse(data.expiredTime));
          localStorage.setItem("userName", data.userName);
          localStorage.setItem("fullName", data.fullName);
          localStorage.setItem("email", data.email);
          localStorage.setItem("phone", data.phone);
          localStorage.setItem("firstLogin", data.firstLogin);
          localStorage.setItem("avatar", JSON.stringify(data.avatar));
          localStorage.setItem("remember", remember ? "1" : "0");





          navigate('/app', { replace: true });
        } else {
          MySwal.fire({
            // title: <strong>Error!</strong>,
            html: <p>{res.data.message}</p>,
            icon: 'error',
            confirmButtonColor: theme.palette.primary.main
          })
        }

      })

    }
    else {
      setLoading(false);
      MySwal.fire({
        // title: <strong>Error!</strong>,
        html: <p>Completa todos los campos!</p>,
        icon: 'error',
        confirmButtonColor: theme.palette.primary.main
      })
    }
  };

  return (
    <>
      <form onSubmit={handleClick} >
        <Stack spacing={3}>
          <TextField name="email" label="Usuario" autoComplete='off' onChange={(event) => {
            setEmail(event.target.value);
          }} />

          <TextField
            name="password"
            label="Contraseña"
            type={showPassword ? 'text' : 'password'}
            autoComplete='off'
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            onChange={(event) => {
              setPassword(event.target.value);
            }}
          />
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
          <FormControlLabel control={<Checkbox sx={{ '& .MuiSvgIcon-root': { fontSize: 20 } }} onChange={event => setRemember(event.target.checked)} />} label="Recuérdame" />
          <Link variant="subtitle2" underline="hover" style={{ cursor: "pointer" }} onClick={() => { navigate("/restore-password") }}>
            ¿Olvidaste tu contraseña?
          </Link>
        </Stack>

        <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={loading}>
          Acceder
        </LoadingButton>
      </form>
    </>
  );
}
