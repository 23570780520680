import React from 'react';
import { Dialog, DialogContent } from '@mui/material';

const ModalCreditRequest = ({ children, isOpen, closeModal }) => {
    return (
        <Dialog
            open={isOpen}
            onClose={closeModal}
            fullWidth
            maxWidth="md"
            sx={{
                '& .MuiBackdrop-root': {
                    backgroundColor: 'rgba(0, 0, 0, 0.75)',
                },
            }}
        >
            <DialogContent
                onClick={(e) => e.stopPropagation()}
                sx={{
                    textAlign: 'center',
                    padding: '1rem',
                    backgroundColor: '#fff',
                    minHeight: '200px',
                }}
            >
                {children}
            </DialogContent>
        </Dialog>
    );
};

export default ModalCreditRequest;