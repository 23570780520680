import axios from "axios";
import { SoraLogin } from "../Config";
import { urlBase, Sora } from "./ServicesConfig";

export const getToken = async (body) => {
    const respose = await axios.post(Sora('User/Login'),
        body,
        {
            headers: {
                'content-type': 'application/json'
            }
        })
        .then(data => {
            return data;
        })
        .catch((error) => {

            if (error.response) {
                return error.response; // => the response payload 
            }
            return { status: 500, data: { message: "ERROR" } }


        });
    return respose;
};

export const GetTokenNC = async (body) => {
    const respose = await axios.post(SoraLogin('User/Login'),
        body,
        {
            headers: {
                'content-type': 'application/json'
            }
        })
        .then(({ data }) => {
            return data;
        })
        .catch((error) => {
            if (error.response) {
                return error.response.data;
            } else if (error.request) {
                return {
                    result: '400',
                    responseText: 'Error de origen tipo Bad Request',
                    data: ''
                };
            } else {
                return {
                    result: '400',
                    responseText: 'Error en código front: ' + error.message,
                    data: ''
                };
            }
        });
    return respose;
};


export const RefreshToken = async (body) => {
    const respose = await axios.post(Sora('User/RefreshToken'),
        body,
        {
            headers: {
                'content-type': 'application/json'
            }
        })
        .then(data => {
            return data;
        })
        .catch((error) => {

            if (error.response) {
                return error.response; // => the response payload 
            }
            return { status: 500, data: { message: "ERROR" } }


        });
    return respose;
};


export const ChangePasswordRequest = async (userName) => {
    const respose = await axios.get(Sora(`/User/ChangePasswordRequest?userName=${userName}&app=AGORA&urlBase=${urlBase}`),
        {
            headers: {
                'content-type': 'application/json'
            }
        })
        .then(data => {
            return data;
        })
        .catch((error) => {

            if (error.response) {
                return error.response; // => the response payload 
            }
            return { status: 500, data: { message: "ERROR" } }


        });
    return respose;
};




export const VerifyTokenChangePassword = async (token) => {
    const respose = await axios.get(Sora(`/User/VerifyTokenChangePassword?token=${token}&MarkVerification=true`),
        {
            headers: {
                'content-type': 'application/json'
            }
        })
        .then(data => {
            return data;
        })
        .catch((error) => {

            if (error.response) {
                return error.response; // => the response payload 
            }
            return { status: 500, data: { message: "ERROR" } }


        });
    return respose;
};




export const ResetPasswordToken = async (token, password) => {
    const respose = await axios.get(Sora(`/User/ResetPasswordToken?token=${token}&Password=${password}`),
        {
            headers: {
                'content-type': 'application/json'
            }
        })
        .then(data => {
            return data;
        })
        .catch((error) => {

            if (error.response) {
                return error.response; // => the response payload 
            }
            return { status: 500, data: { message: "ERROR" } }


        });
    return respose;
};

export const IsKnownCustomer = async (DocumentType, Document) => {

    let respose = await axios.get(SoraLogin("Customers/IsKnownCustomer?DocumentType=" + DocumentType + "&Document=" + Document),
        {
            headers: {
                'content-type': 'application/json'
            }
        })
        .then((data) => {
            return data;
        })
        .catch((error) => {
            if (error.response) {
                return error.response; // => the response payload 
            }
            return { status: 500, data: { message: "ERROR" } }
        })

    return respose;

};

export const GenerateJWTSessionCustomer = async (body) => {

    let respose = await axios.post(SoraLogin("Customers/GenerateJWTSessionCustomer"),
        body,
        {
            headers: {
                'content-type': 'application/json'
            }
        })
        .then((data) => {
            return data;
        })
        .catch((error) => {
            if (error.response) {
                return error.response.data; // => the response payload 
            }
            return { error: 1 }
        })

    return respose;

};


export const CreateUserAgora = async (body) => {

    let respose = await axios.post(SoraLogin("Customers/CreateUserAgora"),
        body,
        {
            headers: {
                'content-type': 'application/json'
            }
        })
        .then((data) => {
            return data;
        })
        .catch((error) => {
            if (error.response) {
                return error.response.data; // => the response payload 
            }
            return { error: 1 }
        })

    return respose;

};