import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
// @mui
import { styled, alpha } from '@mui/material/styles';
import { Toolbar, Tooltip, IconButton, Typography, OutlinedInput, InputAdornment, Grid } from '@mui/material';
// component
// component
import { CSVLink, CSVDownload } from "react-csv";

import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import 'dayjs/locale/es';
import { LoadingButton } from '@mui/lab';
import Iconify from '../../../components/iconify';


// ----------------------------------------------------------------------

const StyledRoot = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  // justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3),
}));

const StyledSearch = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  // transition: theme.transitions.create(['box-shadow', 'width'], {
  //   easing: theme.transitions.easing.easeInOut,
  //   duration: theme.transitions.duration.shorter,
  // }),
  // '&.Mui-focused': {
  //   width: 320,
  //   boxShadow: theme.customShadows.z8,
  // },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${alpha(theme.palette.grey[500], 0.32)} !important`,
  },
}));

// ----------------------------------------------------------------------

DisbursementsToolbar.propTypes = {
  numSelected: PropTypes.number,
  filterName: PropTypes.string,
  onFilterName: PropTypes.func,
};

export default function DisbursementsToolbar({ numSelected, filterName, onFilterName, disbursements, queryDisbursements, query = false }) {

  // const [datesData, setDatesData] = useState({
  //   date1: dayjs().add(-90, 'days').format("YYYY-MM-DD"),
  //   date1O: dayjs().add(-90, 'days'),
  //   date2: dayjs().format("YYYY-MM-DD"),
  //   date2O: dayjs()
  // });
  const [datesData, setDatesData] = useState({
    date1: null,
    date1O: null,
    date2: null,
    date2O: null
  });

  useEffect(() => {
    if (query)
      setDatesData({
        date1: dayjs().add(-90, 'days').format("YYYY-MM-DD"),
        date1O: dayjs().add(-90, 'days'),
        date2: dayjs().format("YYYY-MM-DD"),
        date2O: dayjs()
      });
  }, []);

  const sendData = () => {

    queryDisbursements(datesData)
  }

  return (
    <>
      <Grid container spacing={2} style={{ marginTop: "10px", marginBottom: "20px", marginLeft: "5px", width: "95%" }}>

        <Grid item xs={12} sm={12} md={4} xl={3}>
          <StyledSearch
            value={filterName}
            onChange={onFilterName}
            placeholder="Buscar..."
            startAdornment={
              <InputAdornment position="start">
                <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
              </InputAdornment>
            }
          />
          <Tooltip title="Buscar por Referencia, Nombre, Documento, Estado o Fecha" enterTouchDelay={0}>

            <Iconify icon={'eva:info-outline'} sx={{ color: "gray", marginLeft: "10px" }} />

          </Tooltip>
        </Grid>

        <Grid item xs={12} sm={12} md={2} xl={2}>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">

            <DatePicker label="Fecha Desde" sx={{ width: "100%" }} format="DD-MM-YYYY"
              value={datesData.date1O}
              onChange={(a) => { setDatesData({ ...datesData, date1O: a, date1: a.format("YYYY-MM-DD") }) }}
              slotProps={{
                textField: {
                  variant: 'outlined',
                },
              }}
              maxDate={dayjs()}
            />
          </LocalizationProvider>

        </Grid>


        <Grid item xs={12} sm={12} md={2} xl={2}>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">

            <DatePicker label="Fecha Hasta" sx={{ width: "100%" }} format="DD-MM-YYYY"

              value={datesData.date2O}
              onChange={(a) => { setDatesData({ ...datesData, date2O: a, date2: a.format("YYYY-MM-DD") }) }}
              slotProps={{
                textField: {

                  variant: 'outlined',


                },
              }}
              maxDate={dayjs()}
            />

          </LocalizationProvider>

        </Grid>

        <Grid item xs={6} sm={6} md={2} xl={2}>
          <LoadingButton style={{ marginTop: "10px" }} variant="contained" onClick={sendData}>
            Consultar
          </LoadingButton>

        </Grid>


        <Grid item xs={6} sm={6} md={2} xl={3} style={{ textAlign: "right" }}>
          <Tooltip title="Generar reporte">

            <IconButton size="large" color="inherit" style={{}}>
              <CSVLink style={{ textDecoration: "none" }} data={disbursements.map(i => {
                return {

                  TipoMovimiento: i.MovementType,
                  ReferenciaCredito: i.ReferenceC,
                  FechaMovimiento: i.MovementDate,
                  Cliente: i.Client,
                  Documento: i.Document,
                  ReferenciaPresupuesto: i.ReferenceP,
                  Estado: i.StatusLabel,
                  Valor: i.Amount

                }
              })}
                separator={";"}
                filename={"Desembolsos.csv"}
              >
                <span style={{ fontSize: "20px", color: "green", marginRight: "20px" }}>Descargar</span>
                <Iconify icon={'eva:file-text-outline'} sx={{ color: "green", position: "absolute", marginTop: "10px", right: "10px" }} />

              </CSVLink>

            </IconButton>

          </Tooltip>
        </Grid>

      </Grid>


    </>




  );
}
