import axios from "axios";
import { useNavigate } from 'react-router-dom';
import { RefreshToken } from './SoraService'



const SoraServiceWithToken = () => {

    const navigate = useNavigate();

    const axiosInstance = axios.create({
        baseURL: `${process.env.REACT_APP_URL_SORA}`, // Replace with your API base URL
    });



    axiosInstance.interceptors.request.use(
        async (config) => {

            const token = localStorage.getItem("token");
            const expiredTime = localStorage.getItem("expiredTime");
            const remember = localStorage.getItem("remember");

            if (expiredTime && new Date().getTime() > expiredTime) {


                if (remember === "1") {

                    const res = await RefreshToken({ AccessToken: localStorage.getItem("token"), RefreshToken: localStorage.getItem("refreshToken") })

                    if (res.status === 200) {
                        const data = res.data.data
                        localStorage.setItem("token", data.token);
                        localStorage.setItem("refreshToken", data.refreshToken);
                        localStorage.setItem("expiredTime", Date.parse(data.expiredTime));
                        localStorage.setItem("userName", data.userName);


                        config.headers.authorization = `Bearer ${data.token}`
                    } else {
                        localStorage.removeItem("token");
                        localStorage.removeItem("refreshToken");
                        localStorage.removeItem("expiredTime");
                        localStorage.removeItem("userName");
                        localStorage.removeItem("fullName");
                        localStorage.removeItem("email");
                        localStorage.removeItem("phone");
                        localStorage.removeItem("avatar");
                        localStorage.removeItem("firstLogin");
                        localStorage.removeItem("remember");
                        navigate('/', { replace: true });
                    }
                } else {
                    localStorage.removeItem("token");
                    localStorage.removeItem("refreshToken");
                    localStorage.removeItem("expiredTime");
                    localStorage.removeItem("userName");
                    localStorage.removeItem("fullName");
                    localStorage.removeItem("email");
                    localStorage.removeItem("phone");
                    localStorage.removeItem("avatar");
                    localStorage.removeItem("firstLogin");
                    localStorage.removeItem("remember");
                    navigate('/', { replace: true });
                }




            }
            else if (token) {
                if (config.headers) config.headers.authorization = `Bearer ${token}`;
            }

            return config;
        },
        (error) => {
            // Handle request errors here

            return Promise.reject(error);
        }
    );


    const SaveProfileData = async (body, UserName) => {

        const respose = await axiosInstance.post(`User/SaveProfileData?UserName=${UserName}`,
            body,
            {
                headers: {
                    'content-type': 'application/json'
                }
            })
            .then(data => {
                return data;
            })
            .catch((error) => {

                if (error.response) {
                    return error.response; // => the response payload 
                }
                return { status: 500, data: { message: "ERROR" } }


            });
        return respose;
    };


    const ResetPassword = async (body, UserName) => {

        const respose = await axiosInstance.post(`User/ResetPassword`,
            body,
            {
                headers: {
                    'content-type': 'application/json'
                }
            })
            .then(data => {
                return data;
            })
            .catch((error) => {

                if (error.response) {
                    return error.response; // => the response payload 
                }
                return { status: 500, data: { message: "ERROR" } }


            });
        return respose;
    };




    return { SaveProfileData, ResetPassword }
}


export default SoraServiceWithToken;