import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content'
// @mui
import { Link, Stack, IconButton, InputAdornment, TextField, Checkbox, FormControlLabel } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import { useTheme } from '@mui/material/styles';
import Iconify from '../../../components/iconify';
import { ChangePasswordRequest } from "../../../services/SoraService"


// ----------------------------------------------------------------------

export default function RestorePasswordForm({ setEmailSended ,setEmail1}) {
  const navigate = useNavigate();

  const [emailR, setEmailR] = useState(null);

  const [loading, setLoading] = useState(false);
  const MySwal = withReactContent(Swal)
  const theme = useTheme();

  const handleClick = () => {

    setLoading(true);
    if (emailR) {

      ChangePasswordRequest(emailR).then(res => {
        setLoading(false);

        if (res.status === 200) {
          setEmailSended(true)
          setEmail1(emailR)
        } else {

          MySwal.fire({

            html: <p>{res.data.message}</p>,
            icon: 'error',
            confirmButtonColor: theme.palette.primary.main
          })
        }

      })



    }
    else {
      setLoading(false);
      MySwal.fire({
        // title: <strong>Error!</strong>,
        html: <p>Completa todos los campos!</p>,
        icon: 'error',
        confirmButtonColor: theme.palette.primary.main
      })
    }
  };

  return (
    <>
      <Stack spacing={3} sx={{ my: 2 }}>
        <TextField name="email" label="Usuario" onChange={(event) => {
          setEmailR(event.target.value);
        }} />
        <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={handleClick} loading={loading}>
          Recuperar Contraseña
        </LoadingButton>

        <LoadingButton color="success" fullWidth size="large" type="button" variant="contained" onClick={() => { navigate("/") }} loading={loading}>
          Regresar
        </LoadingButton>

      </Stack>




    </>
  );
}
