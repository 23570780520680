import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';

import {
  Card,
  Table,
  Stack,
  Paper,
  Button,
  Popover,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Snackbar,
  Alert,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Tooltip,
  Select,
  Modal,
  Box,
  Grid,
  FormControl,
  InputLabel,
  TextField,
} from '@mui/material';
import Icon from '@mui/material/Icon';
import { LoadingButton } from '@mui/lab';
// components
import Brightness1Icon from '@mui/icons-material/Brightness1';
import Label from '../components/label';
import Iconify from '../components/iconify';
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';
import TarsService from "../services/TarsService"
import NewInvitation from './NewInvitation';
import useResponsive from '../hooks/useResponsive';
import BellService from "../services/BellService"
import { useGlobalProvider } from '../components/GlobalProvider'


// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'IdInvitation', label: 'Id', alignRight: false },
  // { id: 'Reference', label: 'Referencia', alignRight: false },
  { id: 'CustomerName', label: 'Nombre', alignRight: false },
  { id: 'Document', label: 'Documento', alignRight: false },
  { id: 'StatusLabel', label: 'Estado', alignRight: false },
  { id: 'Treatments', label: 'Tratamientos', alignRight: false },
  { id: 'AmountTreatment', label: 'Presupuesto', alignRight: false },
  { id: 'AmountFinance', label: 'Financiación', alignRight: false },
  { id: 'Survey', label: 'Sondeo', alignRight: false },
  { id: 'DateCreated', label: 'Fecha', alignRight: false },
  { id: '' },
];

// ----------------------------------------------------------------------



function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {

    // return filter(array, (_user) => _user.Document.indexOf(query) !== -1); // por documento
    return filter(array, (_user) =>
      (_user.CustomerName ? _user.CustomerName.toLowerCase().indexOf(query.toLowerCase()) !== -1 : false)
      || _user.Document.indexOf(query) !== -1
      || (_user.StatusLabel ? _user.StatusLabel.toLowerCase().indexOf(query.toLowerCase()) !== -1 : false)
      || (_user.Reference ? _user.Reference.toLowerCase().indexOf(query.toLowerCase()) !== -1 : false)
      || (_user.DateCreated ? _user.DateCreated.indexOf(query) !== -1 : false)
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function InvitationPage() {
  const [open, setOpen] = useState(null);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('desc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('IdInvitation');

  const [isUpdate, setIsUpdate] = useState(false);

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [invitations, setInvitations] = useState([]);
  // const [treatments, setTreatments] = useState([]);
  // const [invitationsStatus, setInvitationsStatus] = useState([]);

  const initialInvitation = {
    posGroup: "", notificationType: "", documentType: "CCC", document: "", phone: "", email: "", reference: "", amountTreatment: "", amountFinance: "", expirationDate: dayjs().add(15, 'days').format("DD-MM-YYYY"), expirationDateO: dayjs().add(15, 'days'), treatments: [],
    customerIsPatient: "",
    patients: []
  }

  const [invitationData, setInvitationData] = useState(initialInvitation);
  const [rememberData, setRememberData] = useState({ notificationType: "", phone: "", email: "" });
  const [temporalInvitation, setTemporalInvitation] = useState({});
  const [snackbarData, setSnackbarData] = useState({ message: "msg", severity: "warning" });
  // const [notificationTypes, setNotificationTypes] = useState(null);
  // const [documentTypes, setDocumentTypes] = useState([]);
  const [errors, setErrors] = useState({});
  const [loadingButton, setLoadingButton] = useState(false);
  const { SearchInvitations, CancelInvitation } = TarsService();
  const { SendTemplateEmail, SendTemplateSMS } = BellService();
  const mdUp = useResponsive('up', 'md');
  const { posData, treatments, documentTypes, notificationTypes, invitationsStatus, sending, setSending, countries } = useGlobalProvider();


  useEffect(() => {


    handleInvitations();

    // setSending(true)

    // const a = GetEnumsCustomer()

    // const c = GetTreatments();

    // Promise.all([a, c]).then(values => {

    //   const enumsCustomer = JSON.parse(values[0].data.data)

    //   const treatmentsTemp = JSON.parse(values[1].data.data)[0].PosTreatments

    //   const invitationsStatus = enumsCustomer.cache.find(s => s.EnumerationId === "cusEnumInvitationsStatus")
    //   const cusEnumDocumentType = enumsCustomer.cache.find(s => s.EnumerationId === "cusEnumDocumentType")



    //   handleInvitations(invitationsStatus, treatmentsTemp)
    //   setDocumentTypes(cusEnumDocumentType.EnumerationValues)
    //   setNotificationTypes(enumsCustomer.cache.find(s => s.EnumerationId === "enumNotificationType").EnumerationValues)
    //   setTreatments(treatmentsTemp)
    //   setInvitationsStatus(invitationsStatus)

    // })

  }, []);

  // useEffect(() => {
  //   if (treatments.length > 0)
  //     handleInvitations(invitationsStatus, treatments)
  // }, [treatments]);


  const handleInvitations = () => {

    setSending(true)

    SearchInvitations().then(res => {
      setSending(false)
      const invitationsTemp = JSON.parse(res.data.data)

      invitationsTemp.Invitations = invitationsTemp.Invitations.map(i => {
        return {
          ...i, StatusLabel: i.LabelStatus ?? ""
          , TreatmentsLabel: i.Treatments.map(t => { return t.TreatmentName ?? "" }).join(", ")
        }
      })
      setInvitations(invitationsTemp.Invitations);

    })

  }



  const handleOpenMenu = (event, row) => {

    const temp = {
      posGroup: row.IdGroup,
      documentType: documentTypes.find(d => d.value === row.DocumentType).value,// { label: "C.C", value: "CCC" },
      document: row.Document,
      phone: row.Phone || "",
      email: row.Email || "",
      reference: row.Reference,
      amountTreatment: row.AmountTreatment,
      AmountTreatmentLabel: row.AmountTreatmentLabel,
      amountFinance: row.AmountFinance,
      AmountFinanceLabel: row.AmountFinanceLabel,
      expirationDate: row.ExpirationDate,
      expirationDateO: dayjs(row.ExpirationDate),
      // treatments: row.Treatments.map(t => { return { IdTreatment: t, TreatmentName: treatments.find(tr => t === tr.IdTreatment).TreatmentName } })
      treatments: row.Treatments,// .map(t => { return treatments.find(tr => t === tr.IdTreatment) }),
      IdInvitation: row.IdInvitation,
      notificationType: row.NotificationType ?? "",
      customerIsPatient: row.CustomerIsPatient ? "SI" : "NO",
      patients: row.Patients && row.Patients.length > 0 ? row.Patients.map(p => {
        return {
          isExisting: true,
          id: p.IdInvitationPatient,
          documentType: p.PatientDocumentType,
          document: p.PatientDocument,
          phoneCountry: countries.find(c => c.value === p.PatientPhoneCountry),
          phone: p.PatientPhone,
          DOB: p.PatientBirthDate,
          DOBo: dayjs(p.PatientBirthDate),
          patientRelationship: p.PatientCustomerRelationship,
          firstName: p.PatientFirstName,
          secondName: p.PatientSecondName,
          firstLastName: p.PatientFirstSurname,
          secondLastName: p.PatientSecondSurname
        }
      }) : []
    }


    setTemporalInvitation(temp)

    if (row.Status === "PEN")
      setOpen(event.currentTarget);
  };

  const editInvitation = () => {
    setInvitationData(temporalInvitation);
    setOpenModal(true)
  }

  const setRememberInvitationData = () => {

    setRememberData(temporalInvitation);
    setOpenModalRemember(true)
  }

  const rememberInvitation = () => {

    setLoadingButton(true);

    setErrors({})
    let errorsB = false;
    if (!rememberData.notificationType) {
      setOpenSnackbar(true)
      setErrors(errors => { return { ...errors, notificationType: true } })
      setSnackbarData({ message: "El campo Medio de comunicación es obligatorio", severity: "warning" })
      errorsB = true;
    }

    const regexEmail = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (rememberData.notificationType && rememberData.notificationType === "EMA" && (!rememberData.email || !regexEmail.test(rememberData.email))) {
      setOpenSnackbar(true)
      setErrors(errors => { return { ...errors, email: true } })
      setSnackbarData({ message: "El campo Correo es obligatorio", severity: "warning" })
      errorsB = true;
    }


    if (rememberData.notificationType && rememberData.notificationType === "SMS" && (!rememberData.phone || rememberData.phone.length < 10)) {
      setOpenSnackbar(true)
      setErrors(errors => { return { ...errors, phone: true } })
      setSnackbarData({ message: "El campo Celular es obligatorio", severity: "warning" })
      errorsB = true;
    }


    if (errorsB) { setLoadingButton(false); return };

    if (rememberData.notificationType === "EMA") {

      const templateBody = {
        templateId: "INV",
        variables: {
          oriPosCommercialName: posData.posCommercialName,
          oriPurchaseAmount: `${rememberData.AmountTreatmentLabel.replaceAll(" ", "")}`,
          oriCreditAmount: `${rememberData.AmountFinanceLabel.replaceAll(" ", "")}`,
          oriTreatments: rememberData.treatments.map(t => { return t.TreatmentName }).join(", "),
          oriInvitationExpirationDate: rememberData.expirationDate
        },
        to: [
          {
            eMail: rememberData.email
          }
        ],
        region: "co-co",
        lang: "co"
      }



      SendTemplateEmail(templateBody).then(resEMA => {
        setLoadingButton(false);
        setOpenSnackbar(true)
        setSnackbarData({ message: "Invitación notificada", severity: "success" });
        setOpenModalRemember(false)
        setOpen(null);
      })

    }
    if (rememberData.notificationType === "SMS") {
      const templateBody = {
        templateId: "INV",
        variables: {
          oriPosCommercialName: posData.posCommercialName,// .normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
          oriPurchaseAmount: `${rememberData.AmountTreatmentLabel.replaceAll(" ", "")}`,
          oriCreditAmount: `${rememberData.AmountFinanceLabel.replaceAll(" ", "")}`,
          oriTreatments: rememberData.treatments.map(t => { return t.TreatmentName }).join(", "),
          oriInvitationExpirationDate: rememberData.expirationDate
        },
        destination: `+57${rememberData.phone}`,
        region: "co-co",
        lang: "co"
      }



      SendTemplateSMS(templateBody).then(resSMS => {
        setLoadingButton(false);
        setOpenSnackbar(true)
        setSnackbarData({ message: "Invitación notificada", severity: "success" })
        setOpenModalRemember(false)
        setOpen(null);
      })
    }
  }

  const cancelInvitation = () => {

    CancelInvitation(temporalInvitation.IdInvitation).then(res => {

      if (res.status === 200) {
        setOpenSnackbar(true)
        setSnackbarData({ message: "Invitación eliminada satisfactoriamente", severity: "success" })
        handleInvitations()
      } else {
        setOpenSnackbar(true)
        setSnackbarData({ message: "Error eliminando la invitación", severity: "error" })
      }
      setOpen(null);
    })
  }

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  // const handleSelectAllClick = (event) => {
  //   if (event.target.checked) {
  //     const newSelecteds = invitations.map((n) => n.Document);
  //     setSelected(newSelecteds);
  //     return;
  //   }
  //   setSelected([]);
  // };

  // const handleClick = (event, Document) => {
  //   const selectedIndex = selected.indexOf(Document);
  //   let newSelected = [];
  //   if (selectedIndex === -1) {
  //     newSelected = newSelected.concat(selected, Document);
  //   } else if (selectedIndex === 0) {
  //     newSelected = newSelected.concat(selected.slice(1));
  //   } else if (selectedIndex === selected.length - 1) {
  //     newSelected = newSelected.concat(selected.slice(0, -1));
  //   } else if (selectedIndex > 0) {
  //     newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
  //   }
  //   setSelected(newSelected);
  // };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - invitations.length) : 0;

  const filteredUsers = applySortFilter(invitations, getComparator(order, orderBy), filterName);

  const isNotFound = !filteredUsers.length && !!filterName;

  const [openModal, setOpenModal] = useState(false);
  const [openModalRemember, setOpenModalRemember] = useState(false);

  const [openSnackbar, setOpenSnackbar] = useState(false);
  // const [sending, setSending] = useState(false);


  const [openConfimationDialog, setOpenConfimationDialog] = useState(false);


  const handleCloseModalRemember = (event, reason) => {

    if (reason && reason === "backdropClick")
      return;
    setOpenModalRemember(false)
  }




  const modalStyle = {
    margin: "50px auto",
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };



  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackbar(false);
  };


  return (
    <>


      <Modal
        open={openModalRemember}
        onClose={handleCloseModalRemember}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
        sx={{

          overflow: 'scroll',

        }}>
        <Box sx={{ ...modalStyle, width: '90%' }}>
          <Grid container spacing={2}>
            <Grid item xs={10} sm={10} md={10}> <h2 id="parent-modal-title">Notificar invitación</h2></Grid>
            <Grid item xs={2} sm={2} md={2} style={{ textAlign: "right" }}><button style={{ border: "none", backgroundColor: "transparent", fontWeight: "bold", fontSize: "30px", cursor: "pointer", marginTop: "20px" }} onClick={() => { setOpenModalRemember(false); setErrors({}); setOpen(null); }}><Iconify sx={{ width: "30px", height: "30px" }} icon="eva:close-outline" /></button></Grid>


            <Grid item xs={12} sm={6} md={6} xl={6}>
              <FormControl sx={{ width: "100%" }}>
                <InputLabel htmlFor="notification-type">Medio de comunicación</InputLabel>
                <Select
                  label="Medio de comunicación"
                  id="notification-type"
                  sx={{ width: "100%" }}
                  value={rememberData.notificationType}
                  onChange={(event) => { setRememberData({ ...rememberData, notificationType: event.target.value }) }}
                  error={errors && errors.notificationType}
                >
                  {notificationTypes && notificationTypes.map(d => {
                    return <MenuItem key={d.value} value={d.value}>{d.label}</MenuItem>
                  })}
                </Select>
              </FormControl>
            </Grid>

            {rememberData.notificationType === "EMA" && <Grid item xs={12} sm={6} md={6} xl={6}>
              <TextField id="Correo" label="Correo electrónico" variant="outlined" sx={{ width: "100%" }} value={rememberData.email} onChange={(event) => { setRememberData({ ...rememberData, email: event.target.value }) }}
                autoComplete="off"
                error={errors && errors.email} />
            </Grid>}

            {rememberData.notificationType === "SMS" && <Grid item xs={12} sm={6} md={6} xl={6}>
              <TextField id="Celular" label="Celular" variant="outlined" sx={{ width: "100%" }} value={rememberData.phone} onChange={(event) => { setRememberData({ ...rememberData, phone: event.target.value }) }}
                type="number"
                autoComplete="off"
                error={errors && errors.phone}
              />
            </Grid>}
            <Grid item xs={12} sm={12} md={12} xl={12} container justifyContent="flex-end">
              <LoadingButton disabled={sending} color="success" variant="contained" onClick={() => rememberInvitation()} loading={loadingButton}>
                Notificar invitación
              </LoadingButton>
            </Grid>
          </Grid>
        </Box>

      </Modal>

      <Dialog
        sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
        maxWidth="xs"

        open={openConfimationDialog}

      >
        <DialogTitle>Eliminar invitación</DialogTitle>
        <DialogContent dividers>
          ¿Desea eliminar la invitación {temporalInvitation.IdInvitation}?
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={() => { setOpenConfimationDialog(false); setOpen(null); }}>
            Cancelar
          </Button>
          <Button onClick={() => { cancelInvitation(); setOpenConfimationDialog(false); }}>Si</Button>
        </DialogActions>
      </Dialog>

      <Snackbar open={openSnackbar} autoHideDuration={5000} onClose={handleCloseSnackbar} anchorOrigin={{ horizontal: "center", vertical: "bottom" }}>
        <Alert onClose={handleCloseSnackbar} severity={snackbarData.severity} sx={{ width: '100%' }}>
          {snackbarData.message}
        </Alert>
      </Snackbar>

      <NewInvitation
        isUpdate={isUpdate}
        openModal={openModal}
        setOpenModal={setOpenModal}
        invitationData={invitationData}
        setInvitationData={setInvitationData}
        initialInvitation={initialInvitation}
        setOpen={setOpen}
        handleInvitations={handleInvitations}
        invitationsStatus={invitationsStatus}

      />

      {/* <NewInvitation
        isUpdate={false}
        openModal={openModal}
        setOpenModal={setOpenModal}
        invitationData={invitationData}
        setInvitationData={setInvitationData}
        initialInvitation={initialInvitation}
      /> */}

      <Helmet>
        <title> Invitaciones | Aliados Meddipay </title>
      </Helmet>

      <Container style={{ maxWidth: "unset" }}>

        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom />
          {posData && <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} onClick={() => { setIsUpdate(false); setOpenModal(true) }}>
            Nueva invitación
          </Button>}
        </Stack>

        <Card id="card-ip">

          <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} invitations={invitations} />

          <Card style={{ borderRadius: "0" }} >
            <TableContainer style={{ width: mdUp ? "100%" : "99%", margin: "0 auto" }} className={mdUp ? undefined : "main-container-ip"}>
              <Table >
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={invitations.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}

                />
                <TableBody >
                  {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                    const { IdInvitation, Reference, CustomerName, Document, Status, StatusLabel, TreatmentsLabel,
                      AmountTreatmentLabel, AmountFinanceLabel, Survey, DateCreated, CustomerState } = row;
                    const selectedUser = selected.indexOf(Document) !== -1;

                    return (
                      <TableRow hover key={IdInvitation} tabIndex={-1} role="checkbox" selected={selectedUser}>
                        {/* <TableCell padding="checkbox">
                          <Checkbox checked={selectedUser} onChange={(event) => handleClick(event, document)} />
                        </TableCell> */}


                        <TableCell align="left">{IdInvitation}</TableCell>

                        {/* <TableCell align="left">{Reference}</TableCell> */}

                        <TableCell align="left" style={{ width: "150px" }}>
                          <Tooltip title={CustomerState === "Verified" ? "Verificado" : (CustomerState === "Pending" ? "Pendiente" : (CustomerState === "Missing" ? "Sin datos" : "---"))}>
                            <Brightness1Icon color={CustomerState === "Verified" ? "success" : (CustomerState === "Pending" ? "warning" : (CustomerState === "Missing" ? "error" : "grey"))} sx={{ fontSize: 15, marginTop: 1 }} />
                          </Tooltip>

                          <Tooltip title={CustomerName}>



                            <span>

                              {CustomerName && CustomerName.length > 15 ? `${CustomerName.slice(0, 15)}...` : (CustomerName ?? "---")}

                            </span>


                          </Tooltip>



                        </TableCell>

                        <TableCell align="left">{Document}</TableCell>



                        <TableCell align="left">
                          <Label color={(Status === 'EXP' && 'error') || (Status === 'CAN' && 'error') || (Status === 'DEN' && 'error') || (Status === 'PEN' && 'warning') || 'success'}>{sentenceCase(StatusLabel)}</Label>
                        </TableCell>

                        <TableCell align="left" style={{ width: "50px" }}>

                          <Tooltip title={TreatmentsLabel}>

                            <span> {TreatmentsLabel.length > 20 ? `${TreatmentsLabel.slice(0, 20)}...` : TreatmentsLabel}</span>
                          </Tooltip>




                        </TableCell>

                        <TableCell align="left">{AmountTreatmentLabel}</TableCell>
                        <TableCell align="left">{AmountFinanceLabel}</TableCell>


                        <TableCell align="left">
                          <Tooltip title={Survey}>

                            {Survey === "SI" ? <Icon style={{ color: "#229A16" }}>call_end</Icon> :
                              Survey === "NO" ? <Icon style={{ color: "#B72136" }}>phone_enabled</Icon> : <Icon style={{ color: "gray" }}>phone_disabled</Icon>}
                          </Tooltip>
                        </TableCell>

                        <TableCell align="left" style={{ minWidth: "110px" }}>{dayjs(DateCreated).format("DD-MM-YYYY")}</TableCell>

                        <TableCell align="right">
                          <IconButton size="large" color="inherit" onClick={(event) => { handleOpenMenu(event, row) }}>
                            <Iconify icon={'eva:more-vertical-fill'} color={Status === 'PEN' ? "black" : "grey"} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={9} />
                    </TableRow>
                  )}
                </TableBody>

                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={9} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            No encontrada
                          </Typography>

                          <Typography variant="body2">
                            Sin resultados para &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br />
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}

                {sending &&

                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={9} sx={{ py: 3 }}>
                        <CircularProgress color="primary" />
                      </TableCell>
                    </TableRow>
                  </TableBody>}
              </Table>




            </TableContainer>
          </Card>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={invitations.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage="Filas por página"
          />
        </Card>

      </Container>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >

        <MenuItem onClick={() => { setRememberInvitationData() }}>
          <Iconify icon={'eva:bell-outline'} sx={{ mr: 2 }} />
          Notificar
        </MenuItem>

        <MenuItem onClick={() => { setIsUpdate(true); editInvitation() }}>
          <Iconify icon={'eva:edit-outline'} sx={{ mr: 2 }} />
          Editar
        </MenuItem>

        <MenuItem sx={{ color: 'error.main' }} onClick={() => setOpenConfimationDialog(true)}>
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
          Eliminar
        </MenuItem>
      </Popover>
    </>
  );
}
