import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { faker } from '@faker-js/faker';
// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, Container, Typography, Button, Tooltip } from '@mui/material';
// components
import dayjs from 'dayjs';
import Iconify from '../components/iconify';
// sections
import {
  AppTasks,
  AppNewsUpdate,
  AppOrderTimeline,
  AppCurrentVisits,
  AppWebsiteVisits,
  AppTrafficBySite,
  AppWidgetSummary,
  AppCurrentSubject,
  AppConversionRates,
} from '../sections/@dashboard/app';
import NewInvitation from './NewInvitation';
import NewCustomerModal from './NewCustomerModal';
import useResponsive from '../hooks/useResponsive';
import { useGlobalProvider } from '../components/GlobalProvider'
// ----------------------------------------------------------------------

export default function DashboardAppPage() {
  const theme = useTheme();
  const [openModal, setOpenModal] = useState(false);
  const [openModalNC, setOpenModalNC] = useState(false);

  const initialInvitation = {
    posGroup: "", notificationType: "", documentType: "CCC", document: "", phone: "", email: "", reference: "", amountTreatment: "", amountFinance: "", expirationDate: dayjs().add(15, 'days').format("DD-MM-YYYY"), expirationDateO: dayjs().add(15, 'days'), treatments: [],
    customerIsPatient: "",
    patients: []
  }

  const { posData } = useGlobalProvider();
  const [invitationData, setInvitationData] = useState(initialInvitation);

  const smUp = useResponsive('up', 'sm');

  useEffect(() => {

  }, []);

  return (
    <>
      <Helmet>
        <title> Dashboard | Aliados Meddipay </title>
      </Helmet>



      <NewInvitation
        isUpdate={false}
        openModal={openModal}
        setOpenModal={setOpenModal}
        invitationData={invitationData}
        setInvitationData={setInvitationData}
        initialInvitation={initialInvitation}
      />

      <NewCustomerModal
        openModalNC={openModalNC}
        setOpenModalNC={setOpenModalNC}
      />

      <Container maxWidth="xl">
        {/* <Typography variant="h4" sx={{ mb: 5 }}>
          Hola, Bienvenido
        </Typography> */}

        <Grid container spacing={3} sx={{ textAlign: "center" }}>


          {posData && (
            <>


              <Grid item xs={12} sm={6} md={6} >
                <div style={{ display: "inline-flex", alignItems: "center" }}>
                <Button variant="outlined" sx={{
                  width: smUp ? 300 : 200,
                  fontSize: smUp ? 20 : 15,
                }} startIcon={<Iconify icon="eva:person-add-fill" />} onClick={() => { setOpenModalNC(true) }}>
                  Nuevo cliente
                </Button>
                <Tooltip title="Esta es la nueva funcionalidad de Meddipay para que puedas ayudar en el proceso de registro del cliente en nuestra plataforma desde tu propio dispositivo, recuerda que debes disponer de la previa autorización del cliente de tratamiento de datos personales para poder realizar el registro en su nombre" enterTouchDelay={0}>

                  <Iconify icon={'eva:info-outline'} sx={{ color: "gray", marginLeft: "10px" }} />

                </Tooltip>
                </div>
                
              </Grid>

              <Grid item xs={12} sm={6} md={6}>
                <Button variant="contained" sx={{ width: smUp ? 300 : 200, fontSize: smUp ? 20 : 15 }} startIcon={<Iconify icon="eva:plus-fill" />} onClick={() => { setOpenModal(true) }}>
                  Nueva invitación
                </Button>
              </Grid>



            </>)
          }


          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary IdGraphic="1" />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary IdGraphic="2" />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary IdGraphic="3" />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary IdGraphic="4" />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppCurrentVisits
              IdGraphic="5"
            />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppWebsiteVisits
              IdGraphic="6"
            />
          </Grid>








          {/* <Grid item xs={12} md={12} lg={12}>
            <AppWebsiteVisits
              IdGraphic="6"
            />
          </Grid> */}

          {/*   
          <Grid item xs={12} md={6} lg={8}>
            <AppConversionRates
              title="Conversion Rates"
              subheader="(+43%) than last year"
              chartData={[
                { label: 'Italy', value: 400 },
                { label: 'Japan', value: 430 },
                { label: 'China', value: 448 },
                { label: 'Canada', value: 470 },
                { label: 'France', value: 540 },
                { label: 'Germany', value: 580 },
                { label: 'South Korea', value: 690 },
                { label: 'Netherlands', value: 1100 },
                { label: 'United States', value: 1200 },
                { label: 'United Kingdom', value: 1380 },
              ]}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppCurrentSubject
              title="Current Subject"
              chartLabels={['English', 'History', 'Physics', 'Geography', 'Chinese', 'Math']}
              chartData={[
                { name: 'Series 1', data: [80, 50, 30, 40, 100, 20] },
                { name: 'Series 2', data: [20, 30, 40, 80, 20, 80] },
                { name: 'Series 3', data: [44, 76, 78, 13, 43, 10] },
              ]}
              chartColors={[...Array(6)].map(() => theme.palette.text.secondary)}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppNewsUpdate
              title="News Update"
              list={[...Array(5)].map((_, index) => ({
                id: faker.datatype.uuid(),
                title: faker.name.jobTitle(),
                description: faker.name.jobTitle(),
                image: `/assets/images/covers/cover_${index + 1}.jpg`,
                postedAt: faker.date.recent(),
              }))}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppOrderTimeline
              title="Order Timeline"
              list={[...Array(5)].map((_, index) => ({
                id: faker.datatype.uuid(),
                title: [
                  '1983, orders, $4220',
                  '12 Invoices have been paid',
                  'Order #37745 from September',
                  'New order placed #XF-2356',
                  'New order placed #XF-2346',
                ][index],
                type: `order${index + 1}`,
                time: faker.date.past(),
              }))}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppTrafficBySite
              title="Traffic by Site"
              list={[
                {
                  name: 'FaceBook',
                  value: 323234,
                  icon: <Iconify icon={'eva:facebook-fill'} color="#1877F2" width={32} />,
                },
                {
                  name: 'Google',
                  value: 341212,
                  icon: <Iconify icon={'eva:google-fill'} color="#DF3E30" width={32} />,
                },
                {
                  name: 'Linkedin',
                  value: 411213,
                  icon: <Iconify icon={'eva:linkedin-fill'} color="#006097" width={32} />,
                },
                {
                  name: 'Twitter',
                  value: 443232,
                  icon: <Iconify icon={'eva:twitter-fill'} color="#1C9CEA" width={32} />,
                },
              ]}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppTasks
              title="Tasks"
              list={[
                { id: '1', label: 'Create FireStone Logo' },
                { id: '2', label: 'Add SCSS and JS files if required' },
                { id: '3', label: 'Stakeholder Meeting' },
                { id: '4', label: 'Scoping & Estimations' },
                { id: '5', label: 'Sprint Showcase' },
              ]}
            />
          </Grid> */}

        </Grid>
      </Container>
    </>
  );
}
