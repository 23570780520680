import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import { StyledChart } from './components/chart';
import ScrollToTop from './components/scroll-to-top';
import { CreditRequestUserProvider } from './Context/useCreditRequestAuth';
import { CreditRequestProvider } from './Context/useCreditRequest';

// ----------------------------------------------------------------------

export default function App() {
  return (

    <HelmetProvider>
      <BrowserRouter>
        <CreditRequestUserProvider>
          <CreditRequestProvider>
            <ThemeProvider>
              <ScrollToTop />
              <StyledChart />
              <Router />
            </ThemeProvider>
          </CreditRequestProvider>
        </CreditRequestUserProvider>
      </BrowserRouter>
    </HelmetProvider>
  );
}
