import React, { useEffect, useState } from 'react';
import { NumericFormat } from "react-number-format";
import {
    Modal,
    Box,
    Grid,
    TextField,
    Chip,
    InputAdornment,
    Snackbar,
    Alert,
    Tooltip,
    Select,
    MenuItem,
    InputLabel,
    FormControl,
    Icon,
    ClickAwayListener
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Iconify from '../components/iconify';
import useResponsive from '../hooks/useResponsive';
import { useGlobalProvider } from '../components/GlobalProvider'
import TarsService from "../services/TarsService"

export default function PosDataModal({ openModal, setOpenModal }) {

    const xlUp = useResponsive('up', 'xl');
    const mdUp = useResponsive('up', 'md');
    const { posData, departments, sending, setSending, setPosData } = useGlobalProvider();
    const [errors, setErrors] = useState({});
    const [cities, setCities] = useState([]);
    // const [colorState, setColorState] = useState({
    //     color: '#00A9EB',
    // });

    // const [colorState2, setColorState2] = useState({
    //     color: '#00A9EB',
    // });

    const [snackbarData, setSnackbarData] = useState({ message: "msg", severity: "warning" });
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [openTooltip1, setOpenTooltip1] = useState(false);
    // const [openTooltip2, setOpenTooltip2] = useState(false);
    const [openTooltip3, setOpenTooltip3] = useState(false);
    // const [openTooltip4, setOpenTooltip4] = useState(false);
    const [openTooltip5, setOpenTooltip5] = useState(false);
    const [openTooltip6, setOpenTooltip6] = useState(false);
    const [openTooltip7, setOpenTooltip7] = useState(false);
    const [openTooltip8, setOpenTooltip8] = useState(false);
    const [openTooltip9, setOpenTooltip9] = useState(false);

    const [phoneList, setPhoneList] = useState([]);
    const [emailList, setEmailList] = useState([]);

    const { UpdatePos } = TarsService();

    const [newPosData, setNewPosData] = useState({
        commercialName: "",
        minAmountTreatment: "",
        maxAmountTreatment: "",
        department: "",
        city: "",
        neighborhood: "",
        address: "",
        treatments: [],
        phone: "",
        email: ""
    });


    useEffect(() => {

        if (posData) {
            setNewPosData({
                commercialName: posData.posCommercialName ?? "",
                minAmountTreatment: posData.posMinPurchaseAmt ?? "",
                maxAmountTreatment: posData.posMaxPurchaseAmt ?? "",
                department: posData.posAddressState ?? "",
                city: posData.posAddressCity ?? "",
                neighborhood: posData.posAddressTown ?? "",
                address: posData.posAddress ?? "",
                treatments: posData.posTreatments,
                phone: posData.posPhone ?? "",
                email: posData.posEmail ?? ""
            });

            if (posData.posContactBook) {
                setEmailList(posData.posContactBook.filter(p => p.type === "EMA").map(p => { return { ...p, key: p.id } }))
                setPhoneList(posData.posContactBook.filter(p => p.type !== "EMA").map(p => { return { ...p, key: p.id } }))
            }

            // setColorState({ color: posData.posMainColor ?? '#00A9EB' });
            // setColorState2({ color: posData.posSecondaryColor ?? '#00A9EB' })
        }

    }, [posData]);

    const handleCloseModal = (event, reason) => {

        if (reason && reason === "backdropClick")
            return;
        setOpenModal(false)
    }

    useEffect(() => {

        if (departments && departments.length > 0) {

            setNewPosData({ ...newPosData, city: "" })
            const citiesTemp = departments.find(a => a.value === newPosData.department);


            if (citiesTemp) {
                setCities(citiesTemp.Municipalities)
                if (posData && posData.posAddressCity && citiesTemp.Municipalities.find(a => a.value === posData.posAddressCity))
                    setNewPosData({ ...newPosData, city: posData.posAddressCity })
            }
        }



    }, [newPosData.department, departments]);


    // const handleColorChange = (color) => {
    //     setColorState({ color: color.hex.toUpperCase() });
    // };

    // const handleColorChange2 = (color) => {
    //     setColorState2({ color: color.hex.toUpperCase() });
    // };

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenSnackbar(false);
    };

    const saveNewPosData = () => {


        setSending(true);

        setErrors({})
        let errorsB = false;
        if (!newPosData.commercialName) {
            setOpenSnackbar(true)
            setErrors(errors => { return { ...errors, commercialName: true } })
            setSnackbarData({ message: "El campo Nombre comercial es obligatorio", severity: "warning" })
            errorsB = true;
        }

        if (!newPosData.minAmountTreatment) {
            setOpenSnackbar(true)
            setErrors(errors => { return { ...errors, minAmountTreatment: true } })
            setSnackbarData({ message: "El campo Valor mínimo del presupuesto es obligatorio", severity: "warning" })
            errorsB = true;
        }

        if (!newPosData.maxAmountTreatment) {
            setOpenSnackbar(true)
            setErrors(errors => { return { ...errors, maxAmountTreatment: true } })
            setSnackbarData({ message: "El campo Valor máximo del presupuesto es obligatorio", severity: "warning" })
            errorsB = true;
        }

        if (!newPosData.department) {
            setOpenSnackbar(true)
            setErrors(errors => { return { ...errors, department: true } })
            setSnackbarData({ message: "El campo Departamento es obligatorio", severity: "warning" })
            errorsB = true;
        }

        if (!newPosData.city) {
            setOpenSnackbar(true)
            setErrors(errors => { return { ...errors, city: true } })
            setSnackbarData({ message: "El campo Ciudad es obligatorio", severity: "warning" })
            errorsB = true;
        }

        // if (!newPosData.neighborhood) {
        //     setOpenSnackbar(true)
        //     setErrors(errors => { return { ...errors, neighborhood: true } })
        //     setSnackbarData({ message: "El campo Barrio es obligatorio", severity: "warning" })
        //     errorsB = true;
        // }

        if (!newPosData.address) {
            setOpenSnackbar(true)
            setErrors(errors => { return { ...errors, address: true } })
            setSnackbarData({ message: "El campo Dirección es obligatorio", severity: "warning" })
            errorsB = true;
        }

        // if (newPosData.treatments.length === 0) {
        //     setOpenSnackbar(true)
        //     setErrors(errors => { return { ...errors, treatments: true } })
        //     setSnackbarData({ message: "El campo Tratamientos es obligatorio", severity: "warning" })
        //     errorsB = true;
        // }

        if (!newPosData.phone) {
            setOpenSnackbar(true)
            setErrors(errors => { return { ...errors, phone: true } })
            setSnackbarData({ message: "El campo Celular es obligatorio", severity: "warning" })
            errorsB = true;
        }

        if (!newPosData.email) {
            setOpenSnackbar(true)
            setErrors(errors => { return { ...errors, email: true } })
            setSnackbarData({ message: "El campo Correo es obligatorio", severity: "warning" })
            errorsB = true;
        }

        if (errorsB) { setSending(false); return };


        let contactBook = phoneList.map(p => { return { contact: p.contact, type: p.type, id: p.id } })
            .concat(emailList.map(p => { return { contact: p.contact, type: p.type, id: p.id } }))

        contactBook = contactBook.filter(c => c.contact)
        // if (!contactBook.find(c => c.contact === newPosData.phone))
        //     contactBook.push({ contact: newPosData.phone, type: "MOB" })

        // if (!contactBook.find(c => c.contact === newPosData.email))
        //     contactBook.push({ contact: newPosData.email, type: "EMA" })



        const body = {

            displayName: newPosData.commercialName,
            minAmt: newPosData.minAmountTreatment,
            maxAmt: newPosData.maxAmountTreatment,
            state: newPosData.department,
            city: newPosData.city,
            town: newPosData.neighborhood,
            address: newPosData.address,
            phone: newPosData.phone,
            email: newPosData.email,
            contactBook,
            // treatments: newPosData.treatments.map(t => { return t.value })
        };



        UpdatePos(body).then(res => {

            setSending(false);

            if (res.status === 200) {
                setPosData({ ...posData, posMinPurchaseAmt: newPosData.minAmountTreatment, posMaxPurchaseAmt: newPosData.maxAmountTreatment })
                setOpenSnackbar(true)
                setSnackbarData({ message: "Datos guardados", severity: "success" });
                setOpenModal(false);
            }
            else {
                setOpenSnackbar(true)
                setSnackbarData({ message: res.data.message, severity: "error" })
            }
        })
    }

    const modalStyle = {
        margin: "20px auto",
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        pt: 2,
        px: 4,
        pb: 3,
    };




    return <>

        <Snackbar open={openSnackbar} autoHideDuration={5000} onClose={handleCloseSnackbar} anchorOrigin={{ horizontal: "center", vertical: "bottom" }}>
            <Alert onClose={handleCloseSnackbar} severity={snackbarData.severity} sx={{ width: '100%' }}>
                {snackbarData.message}
            </Alert>
        </Snackbar>

        <Modal
            open={openModal}
            onClose={handleCloseModal}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
            sx={{

                overflow: 'scroll',

            }}>




            <Box sx={{ ...modalStyle, width: mdUp ? "40%" : "90%", textAlign: "center" }}>



                {/* <Modal open={displayMainColorPicker}
                    onClose={() => { }}
                    aria-labelledby="parent-modal-title"
                    aria-describedby="parent-modal-description"
                >
                    <Box sx={{ ...modalStyle, width: "400px", textAlign: "center" }}>
                        <ChromePicker color={colorState.color}
                            onChangeComplete={handleColorChange} />

                        <br />
                        <LoadingButton color="success" variant="contained" onClick={() => { setdisplayMainColorPicker(false) }}>OK</LoadingButton>
                    </Box>


                </Modal> */}

                <Grid item xs={10} sm={10} md={10} xl={10} />
                <Grid item xs={2} sm={2} md={2} style={{ textAlign: "right", padding: "0px" }}><button style={{ border: "none", backgroundColor: "transparent", fontWeight: "bold", fontSize: "30px", cursor: "pointer", marginTop: "15px" }} onClick={() => { setOpenModal(false); }}><Iconify sx={{ width: "30px", height: "30px" }} icon="eva:close-outline" /></button></Grid>
                <Grid item xs={12} sm={12} md={12} xl={12} style={{ textAlign: "center", paddingTop: "0px" }}> <h2 id="parent-modal-title" style={{ margin: "0" }}>Datos Sede</h2></Grid>

                <Grid item xs={12} sm={12} md={12} xl={12} style={{ paddingTop: "0px" }}>

                    {posData ? posData.posCommercialName ?? "" : ""}
                    <br />     <br />
                </Grid>

                <Grid item xs={12} sm={12} md={12} xl={12}>
                    <p style={{ margin: "0px 0px 15px 0px", fontSize: "15px" }}>
                        Aquí podrás editar los detalles de tu sede. Estos datos afectan a como los usuarios te ven en nuestra web y también afecta a la interacción con este portal de aliados.
                    </p>

                </Grid>

                <Grid item xs={12} sm={12} md={12} xl={12}>
                    <ul style={{
                        display: 'flex',
                        justifyContent: 'center',
                        flexWrap: 'wrap',
                        listStyle: 'none',

                    }}>
                        {posData ? posData.posSector.map(s => {
                            return <li key={s.Id} style={{ margin: "24px 4px 4px" }}><Chip label={s.Name} /></li>


                        }) : <></>}
                    </ul>


                    <br />
                </Grid>


                <Grid container spacing={2} >

                    <Grid item xs={12} sm={12} md={12} xl={12}>
                        <TextField id="commercialName" label="Nombre Comercial" variant="outlined" sx={{ width: "100%" }}
                            type="text"
                            autoComplete="off"
                            value={newPosData.commercialName}
                            onChange={(event) => { setNewPosData({ ...newPosData, commercialName: event.target.value }); setErrors(errors => { return { ...errors, commercialName: false } }) }}
                            error={errors && errors.commercialName}
                            InputProps={{
                                endAdornment:
                                    <ClickAwayListener onClickAway={() => { setOpenTooltip1(false) }}>
                                        <Tooltip disableFocusListener
                                            disableHoverListener
                                            disableTouchListener
                                            PopperProps={{
                                                disablePortal: true,
                                            }} open={openTooltip1} onClose={() => { setOpenTooltip1(false) }} title="Este será el nombre que mostraremos a los clientes en nuestro directorio de aliados y cuando el cliente reciba una invitación. Recomendamos que sea un nombre corto." enterTouchDelay={0}><InputAdornment style={{ cursor: "pointer" }} position="start" onClick={() => { setOpenTooltip1(true) }}>?</InputAdornment></Tooltip>
                                    </ClickAwayListener>
                            }}
                        />
                    </Grid>


                    <Grid item xs={12} sm={12} md={6} xl={6}>

                        <NumericFormat
                            customInput={TextField}
                            id="minAmountTreatment"
                            label="Valor mínimo del presupuesto"
                            variant="outlined"
                            valueIsNumericString
                            thousandSeparator="."
                            decimalSeparator=","
                            value={newPosData.minAmountTreatment}
                            decimalScale={0}
                            onValueChange={(value) => { setNewPosData({ ...newPosData, minAmountTreatment: value.floatValue ?? "", minAmountTreatmentF: value.formattedValue ?? "" }); setErrors(errors => { return { ...errors, minAmountTreatment: false } }) }}
                            InputProps={{
                                startAdornment:
                                    <InputAdornment position="start">$</InputAdornment>,
                                endAdornment:
                                    <ClickAwayListener onClickAway={() => { setOpenTooltip3(false) }}>
                                        <Tooltip disableFocusListener
                                            disableHoverListener
                                            disableTouchListener
                                            PopperProps={{
                                                disablePortal: true,
                                            }} open={openTooltip3} onClose={() => { setOpenTooltip3(false) }} title={<p>Valor mínimo de los presupuestos de las invitaciones. Este valor <b>NO</b> afecta al valor a financiar, ya que este estará definido y controlado por Meddipay.</p>} enterTouchDelay={0}><InputAdornment style={{ cursor: "pointer" }} position="start" onClick={() => { setOpenTooltip3(true) }}>?</InputAdornment></Tooltip>
                                    </ClickAwayListener>
                            }}
                            autoComplete="off"
                            error={errors && errors.minAmountTreatment}
                            sx={{ width: "100%" }}
                        />

                    </Grid>


                    <Grid item xs={12} sm={12} md={6} xl={6}>

                        <NumericFormat
                            customInput={TextField}
                            id="maxAmountTreatment"
                            label="Valor máximo del presupuesto"
                            variant="outlined"
                            valueIsNumericString
                            thousandSeparator="."
                            decimalSeparator=","
                            value={newPosData.maxAmountTreatment}
                            decimalScale={0}
                            onValueChange={(value) => { setNewPosData({ ...newPosData, maxAmountTreatment: value.floatValue ?? "", maxAmountTreatmentF: value.formattedValue ?? "" }); setErrors(errors => { return { ...errors, maxAmountTreatment: false } }) }}
                            InputProps={{
                                startAdornment:
                                    <InputAdornment position="start">$</InputAdornment>,
                                endAdornment:
                                    <ClickAwayListener onClickAway={() => { setOpenTooltip5(false) }}>
                                        <Tooltip disableFocusListener
                                            disableHoverListener
                                            disableTouchListener
                                            PopperProps={{
                                                disablePortal: true,
                                            }} open={openTooltip5} onClose={() => { setOpenTooltip5(false) }} title={<p>Valor máximo de los presupuestos de las invitaciones. Este valor <b>NO</b> afecta al valor a financiar, ya que este estará definido y controlado por Meddipay.</p>} enterTouchDelay={0}><InputAdornment style={{ cursor: "pointer" }} position="start" onClick={() => { setOpenTooltip5(true) }}>?</InputAdornment></Tooltip>
                                    </ClickAwayListener>
                            }}
                            autoComplete="off"
                            error={errors && errors.maxAmountTreatment}
                            sx={{ width: "100%" }}
                        />

                    </Grid>


                    <Grid item xs={12} sm={12} md={12} xl={12} style={{ textAlign: "left" }}>
                        <FormControl sx={{ width: "100%" }}>
                            <InputLabel htmlFor="department">Departamento</InputLabel>
                            <Select
                                label="Departamento"
                                id="department"
                                sx={{ width: "100%" }}
                                value={newPosData.department}
                                onChange={(event) => { setNewPosData({ ...newPosData, department: event.target.value }); setErrors(errors => { return { ...errors, department: false } }) }}
                                error={errors && errors.department}
                            >
                                {departments.map(d => {
                                    return <MenuItem key={d.value} value={d.value}>{d.label}</MenuItem>
                                })}
                            </Select>
                        </FormControl>
                    </Grid>


                    <Grid item xs={12} sm={12} md={6} xl={6} style={{ textAlign: "left" }}>
                        <FormControl sx={{ width: "100%" }}>
                            <InputLabel htmlFor="city">Ciudad</InputLabel>
                            <Select
                                label="Ciudad"
                                id="city"
                                sx={{ width: "100%" }}
                                value={newPosData.city}
                                disabled={!newPosData.department}
                                onChange={(event) => { setNewPosData({ ...newPosData, city: event.target.value }); setErrors(errors => { return { ...errors, city: false } }) }}
                                error={errors && errors.city}
                            >
                                {cities.map(d => {
                                    return <MenuItem key={d.value} value={d.value}>{d.label}</MenuItem>
                                })}
                            </Select>
                        </FormControl>
                    </Grid>


                    <Grid item xs={12} sm={12} md={6} xl={6}>
                        <TextField id="neighborhood" label="Barrio" variant="outlined" sx={{ width: "100%" }}
                            type="text"
                            autoComplete="off"
                            value={newPosData.neighborhood}
                            onChange={(event) => { setNewPosData({ ...newPosData, neighborhood: event.target.value }); setErrors(errors => { return { ...errors, neighborhood: false } }) }}
                            error={errors && errors.neighborhood}
                        />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} xl={12}>
                        <TextField id="address" label="Dirección" variant="outlined" sx={{ width: "100%" }}
                            type="text"
                            autoComplete="off"
                            value={newPosData.address}
                            onChange={(event) => { setNewPosData({ ...newPosData, address: event.target.value }); setErrors(errors => { return { ...errors, address: false } }) }}
                            error={errors && errors.address}
                        />
                    </Grid>




                    <Grid item xs={12} sm={12} md={12} xl={12} >
                        <TextField id="Celular" label="Celular principal" variant="outlined" sx={{ width: "100%" }}
                            type="text"
                            autoComplete="off"
                            value={newPosData.phone}
                            onChange={(event) => { setNewPosData({ ...newPosData, phone: event.target.value }); setErrors(errors => { return { ...errors, phone: false } }) }}
                            error={errors && errors.phone}

                            InputProps={{
                                endAdornment:
                                    <ClickAwayListener onClickAway={() => { setOpenTooltip6(false) }}>
                                        <Tooltip disableFocusListener
                                            disableHoverListener
                                            disableTouchListener
                                            PopperProps={{
                                                disablePortal: true,
                                            }} open={openTooltip6} onClose={() => { setOpenTooltip6(false) }} title="Mostraremos este numero de teléfono/celular a los clientes para que te puedan contactar fácilmente." enterTouchDelay={0}><InputAdornment style={{ cursor: "pointer" }} position="start" onClick={() => { setOpenTooltip6(true) }}>?</InputAdornment></Tooltip>
                                    </ClickAwayListener>
                            }}

                        />

                    </Grid>


                    <Grid item xs={12} sm={12} md={12} xl={12}>
                        <Grid container style={{ paddingLeft: "20px", marginBottom: "20px", border: "1px solid", borderRadius: "5px", borderColor: "#d9d9d9", boxShadow: "0px 11px 15px -7px rgba(145, 158, 171, 0.2),0px 24px 38px 3px rgba(145, 158, 171, 0.14),0px 9px 46px 8px rgba(145, 158, 171, 0.12)" }} >
                            <Grid item xs={10} sm={10} md={10} xl={10} style={{ textAlign: "left" }}>
                                <h4 style={{ marginTop: "20px", marginBottom: "20px" }}>Celulares Notificaciones</h4>

                            </Grid>
                            <Grid item xs={2} sm={2} md={2} xl={2} style={{ textAlign: "center" }}>
                                <ClickAwayListener onClickAway={() => { setOpenTooltip8(false) }}>
                                    <Tooltip disableFocusListener
                                        disableHoverListener
                                        disableTouchListener
                                        PopperProps={{
                                            disablePortal: true,
                                        }} open={openTooltip8} onClose={() => { setOpenTooltip8(false) }} title="Los números de celular diferentes al principal solamente se usaran para enviar comunicaciones importantes." enterTouchDelay={0}>
                                        <Icon style={{ cursor: "pointer", marginLeft: mdUp ? "10px" : "0px", color: "gray", marginTop: "20px" }} onClick={() => { setOpenTooltip8(true) }}>help_outlined</Icon>
                                    </Tooltip>
                                </ClickAwayListener>
                            </Grid>

                            {phoneList.map((p, i) => {
                                return <React.Fragment key={p.key}><Grid item xs={10} sm={10} md={10} xl={10} style={{ display: "inline-flex", marginBottom: "20px" }}>
                                    <TextField id={`Celular #${i + 2}`} label={`Celular #${i + 2}`} variant="outlined" sx={{ width: "100%" }}
                                        type="text"
                                        autoComplete="off"
                                        value={p.contact}
                                        onChange={(event) => {
                                            const prt = phoneList.map(rt => {
                                                if (rt.key === p.key) { return { ...rt, contact: event.target.value } } return rt
                                            })
                                            setPhoneList(prt);
                                            // setErrors(errors => { return { ...errors, phone: false } })
                                        }}
                                    // error={errors && errors.phone}

                                    // InputProps={{
                                    //     endAdornment:
                                    //         <ClickAwayListener onClickAway={() => { setOpenTooltip6(false) }}>
                                    //             <Tooltip disableFocusListener
                                    //                 disableHoverListener
                                    //                 disableTouchListener
                                    //                 PopperProps={{
                                    //                     disablePortal: true,
                                    //                 }} open={openTooltip6} onClose={() => { setOpenTooltip6(false) }} title="Mostraremos este numero de teléfono/celular a los clientes para que te puedan contactar fácilmente. También usaremos el numero de celular para enviarte comunicaciones importantes." enterTouchDelay={0}><InputAdornment style={{ cursor: "pointer" }} position="start" onClick={() => { setOpenTooltip6(true) }}>?</InputAdornment></Tooltip>
                                    //         </ClickAwayListener>
                                    // }}

                                    />

                                </Grid>
                                    <Grid item xs={2} sm={2} md={2} xl={2} style={{ textAlign: "center" }}>
                                        <Icon style={{ marginTop: "15px", color: "red", marginLeft: mdUp ? "10px" : "0px", cursor: "pointer" }} onClick={() => {
                                            const prt = phoneList.filter(pr => pr.key !== p.key)
                                            setPhoneList(prt)
                                        }}>delete</Icon>
                                    </Grid>

                                </React.Fragment>
                            })}
                            <Grid item xs={12} sm={12} md={12} xl={12} style={{ textAlign: "center" }}>
                                <Icon style={{ marginBottom: "20px", color: phoneList.length < 4 ? "#54D62C" : "gray", cursor: "pointer" }} onClick={() => { if (phoneList.length < 4) setPhoneList([...phoneList, { key: Date.now(), contact: "", type: "MOB" }]) }}>add_circle</Icon>
                            </Grid>

                        </Grid>
                    </Grid>




                    <Grid item xs={12} sm={12} md={12} xl={12} style={{ display: "inline-flex" }}>
                        <TextField id="Correo" label="Correo electrónico principal" variant="outlined" sx={{ width: "100%" }}
                            value={newPosData.email} onChange={(event) => { setNewPosData({ ...newPosData, email: event.target.value }); setErrors(errors => { return { ...errors, email: false } }) }}
                            autoComplete="off"
                            error={errors && errors.email} InputProps={{
                                endAdornment:
                                    <ClickAwayListener onClickAway={() => { setOpenTooltip7(false) }}>
                                        <Tooltip disableFocusListener
                                            disableHoverListener
                                            disableTouchListener
                                            PopperProps={{
                                                disablePortal: true,
                                            }} open={openTooltip7} onClose={() => { setOpenTooltip7(false) }} title="Mostraremos este correo electrónico a los clientes para que te puedan contactar fácilmente." enterTouchDelay={0}><InputAdornment style={{ cursor: "pointer" }} position="start" onClick={() => { setOpenTooltip7(true) }}>?</InputAdornment></Tooltip>
                                    </ClickAwayListener>
                            }}
                        />

                    </Grid>


                    <Grid item xs={12} sm={12} md={12} xl={12}>
                        <Grid container style={{ paddingLeft: "20px", marginBottom: "20px", border: "1px solid", borderRadius: "5px", borderColor: "#d9d9d9", boxShadow: "0px 11px 15px -7px rgba(145, 158, 171, 0.2),0px 24px 38px 3px rgba(145, 158, 171, 0.14),0px 9px 46px 8px rgba(145, 158, 171, 0.12)" }} >
                            <Grid item xs={10} sm={10} md={10} xl={10} style={{ textAlign: "left" }}>
                                <h4 style={{ marginTop: "20px", marginBottom: "20px" }}>Correos Notificaciones</h4>

                            </Grid>
                            <Grid item xs={2} sm={2} md={2} xl={2} style={{ textAlign: "center" }}>
                                <ClickAwayListener onClickAway={() => { setOpenTooltip9(false) }}>
                                    <Tooltip disableFocusListener
                                        disableHoverListener
                                        disableTouchListener
                                        PopperProps={{
                                            disablePortal: true,
                                        }} open={openTooltip9} onClose={() => { setOpenTooltip9(false) }} title="Los correos electrónicos diferentes al principal solamente se usaran para enviar comunicaciones importantes." enterTouchDelay={0}>
                                        <Icon style={{ cursor: "pointer", marginLeft: mdUp ? "10px" : "0px", color: "gray", marginTop: "20px" }} onClick={() => { setOpenTooltip9(true) }}>help_outlined</Icon>
                                    </Tooltip>
                                </ClickAwayListener>
                            </Grid>

                            {emailList.map((p, i) => {
                                return <React.Fragment key={p.key}><Grid item xs={10} sm={10} md={10} xl={10} style={{ display: "inline-flex", marginBottom: "20px" }}>
                                    <TextField id={`Correo #${i + 2}`} label={`Correo #${i + 2}`} variant="outlined" sx={{ width: "100%" }}
                                        type="text"
                                        autoComplete="off"
                                        value={p.contact}
                                        onChange={(event) => {
                                            const prt = emailList.map(rt => {
                                                if (rt.key === p.key) { return { ...rt, contact: event.target.value } } return rt
                                            })
                                            setEmailList(prt);
                                            // setErrors(errors => { return { ...errors, phone: false } })
                                        }}


                                    />

                                </Grid>
                                    <Grid item xs={2} sm={2} md={2} xl={2} style={{ textAlign: "center" }}>
                                        <Icon style={{ marginTop: "15px", color: "red", marginLeft: mdUp ? "10px" : "0px", cursor: "pointer" }} onClick={() => {
                                            const prt = emailList.filter(pr => pr.key !== p.key)
                                            setEmailList(prt)
                                        }}>delete</Icon>
                                    </Grid>

                                </React.Fragment>
                            })}
                            <Grid item xs={12} sm={12} md={12} xl={12} style={{ textAlign: "center" }}>
                                <Icon style={{ marginBottom: "20px", color: emailList.length < 4 ? "#54D62C" : "gray", cursor: "pointer" }} onClick={() => { if (emailList.length < 4) setEmailList([...emailList, { key: Date.now(), contact: "", type: "EMA" }]) }}>add_circle</Icon>
                            </Grid>

                        </Grid>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} xl={12} container justifyContent="center" style={{ padding: "20px 0px 0px 0px" }}>
                        <LoadingButton disabled={sending} color="info" variant="contained" onClick={saveNewPosData} loading={sending}>
                            Guardar
                        </LoadingButton>
                    </Grid>

                    {/* <Grid item xs={12} sm={12} md={12} xl={12}>
                        <ChromePicker color={colorState.background}
                            onChangeComplete={handleChangeComplete} />
                    </Grid> */}


                </Grid>

            </Box>

        </Modal >

    </>
}