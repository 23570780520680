import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ReactApexChart from 'react-apexcharts';
// @mui
import { Card, CardHeader, Box, Skeleton } from '@mui/material';
// components
import { useChart } from '../../../components/chart';
import TarsService from '../../../services/TarsService';
// ----------------------------------------------------------------------

// AppWebsiteVisits.propTypes = {
//   title: PropTypes.string,
//   subheader: PropTypes.string,
//   chartData: PropTypes.array.isRequired,
//   chartLabels: PropTypes.arrayOf(PropTypes.string).isRequired,
// };

export default function AppWebsiteVisits({ IdGraphic, ...other }) {

  const { GraphicsData } = TarsService();
  const [GraphicData, setGraphicData] = useState(null);

  useEffect(() => {
    GraphicsData(IdGraphic).then(res => {
      const data = JSON.parse(res.data.data)

    
      setGraphicData(data)

    })
  }, []);

  const COP = new Intl.NumberFormat('es-CO', {
    style: 'currency',
    currency: 'COP',
    maximumFractionDigits: 0
  });

  const chartOptions = useChart({
    chart: {
      height: 270,
      type: 'bar',
    },
    plotOptions: { bar: { columnWidth: '16%' } },
    fill: { type: GraphicData ? GraphicData.chartData.map((i) => i.fill) : "solid" },
    labels: GraphicData ? GraphicData.chartLabels : [],
    yaxis: {
      title: {
        text: GraphicData ? GraphicData.yAxisTitle : ""
      },
      labels: {
        formatter: (y) => {
          if (typeof y !== 'undefined') {
            return `${COP.format(y)}`;
          }
          return y;
        }
      }
    },
    dataLabels: {
      enabled: false
    },
    xaxis: {
      type: 'datetime', labels: {
        format: 'dd'
      },

      title: {
        text: GraphicData ? GraphicData.xAxisTitle : ""
      }
    },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: (y) => {
          if (typeof y !== 'undefined') {
            return `${COP.format(y)}`;
          }
          return y;
        },
      },
    },
  });

  return GraphicData ? (
    <Card {...other}>
      <CardHeader title={GraphicData.title} subheader={GraphicData.subheader} />

      <Box sx={{ p: 3, pb: 1 }} dir="ltr">
        <ReactApexChart type="bar" series={GraphicData.chartData} options={chartOptions} height={270} />
      </Box>
    </Card>
  ) : <Card><Skeleton variant="rounded" width="100%" height={390} /></Card>;
}
