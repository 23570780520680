import { createContext, useContext, useEffect } from 'react';
// import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import { GetTokenNC } from '../services/SoraService';
// import api from '../services/api'

const UserContext = createContext();

export const CreditRequestUserProvider = ({ children }) => {
    const navigate = useNavigate();
    // const [cookies, setCookies] = useCookies();

    // let interval = null;

    // useEffect(() => {
    //     interval = setInterval(() => {

    //         const expiredTime = sessionStorage.getItem("expiredTime")
    //         console.log(expiredTime)
    //         if (expiredTime) {

    //             if (expiredTime < new Date().getTime()) {

    //                 logout();
    //                 clearRequest();
    //             }
    //         }
    //         else {
    //             logout();
    //             clearRequest();
    //         }


    //     }, 1000 * 5);

    // }, []);


    // useEffect(() => {

    //     return () => {
    //         clearInterval(interval);
    //     };
    // }, []);


    const login = (body) => {

        return new Promise((resolve, reject) => {

            GetTokenNC(body).then(res => {

                if (res.result === 200) {
                    setStorage("creditRequestToken", res.data.token, { path: "/" });
                    setStorage("expiredTime", Date.parse(res.data.expiredTime), { path: "/" })
                    resolve({ "msg": "ok" })
                }
                else {
                    resolve({ "msg": "error" })
                }

            });

        })


    };

    const logout = () => {
        removeCookies();
        // navigate("/app/dashboard");
    };


    const setStorage = (name, item) => {
        sessionStorage.setItem(name, JSON.stringify(item));
    }
    const getStorage = (name) => {
        // sessionStorage.setItem("CEDULA", form.idNumber)
        const item = sessionStorage.getItem(name);
        return JSON.parse(item);
    }
    const removeStorage = (name) => {
        sessionStorage.removeItem(name);
    }

    const removeCookies = () => {
        sessionStorage.removeItem("creditRequestToken");
        sessionStorage.removeItem("expiredTime");
    }

    const clearRequest = () => {
        removeStorage("request")
    }

    const value = {
        login,
        logout,
        clearRequest,
        setStorage,
        getStorage,
        removeStorage
    }

    return (
        <UserContext.Provider value={value}>
            {children}
        </UserContext.Provider>
    )
};

export const useCreditRequestAuth = () => {
    return useContext(UserContext)
};