
export default function getCroppedImg(imageSrc, pixelCrop, rotation = 0, flip = { horizontal: false, vertical: false }) {

  const image = new Image();
  image.src = imageSrc;
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  if (!ctx) {
    return null;
  }

  const rotRad = getRadianAngle(rotation);

  // calculate bounding box of the rotated image
  const { width: bBoxWidth, height: bBoxHeight } = rotateSize(image.width, image.height, rotation);

  // set canvas size to match the bounding box
  canvas.width = bBoxWidth;
  canvas.height = bBoxHeight;

  // translate canvas context to a central location to allow rotating and flipping around the center
  ctx.translate(bBoxWidth / 2, bBoxHeight / 2);
  ctx.rotate(rotRad);
  ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1);
  ctx.translate(-image.width / 2, -image.height / 2);

  // draw rotated image
  ctx.drawImage(image, 0, 0);

  // croppedAreaPixels values are bounding box relative
  // extract the cropped image using these values
  const data = ctx.getImageData(pixelCrop.x, pixelCrop.y, pixelCrop.width, pixelCrop.height);

  // set canvas width to final desired crop size - this will clear existing context
  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;

  // paste generated rotate image at the top left corner
  ctx.putImageData(data, 0, 0);


  return new Promise((resolve, reject) => {

    resolve(canvas.toDataURL('image/jpeg'));

  });
  // resolve(canvas.toDataURL('image/jpeg'));
  // const rotRad = getRadianAngle(rotation);


  // const image = new Image();
  // image.src = imageSrc;

  // const { width: bBoxWidth, height: bBoxHeight } = rotateSize(image.width, image.height, rotation);

  // const canvas = document.createElement('canvas');
  // canvas.width = bBoxWidth;
  // canvas.height = bBoxHeight;


  // const ctx = canvas.getContext('2d');
  // ctx.rotate(rotRad);


  // return new Promise((resolve, reject) => {
  //   image.onload = () => {
  //     ctx.drawImage(
  //       image,
  //       pixelCrop.x,
  //       pixelCrop.y,
  //       pixelCrop.width,
  //       pixelCrop.height,
  //       0,
  //       0,
  //       pixelCrop.width,
  //       pixelCrop.height
  //     );
  //     resolve(canvas.toDataURL('image/jpeg'));
  //   };
  //   image.onerror = (error) => reject(error);
  // });
}

export function getRadianAngle(degreeValue) {
  return (degreeValue * Math.PI) / 180;
}

export function rotateSize(width, height, rotation) {
  const rotRad = getRadianAngle(rotation);

  return {
    width: Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height),
    height: Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height)
  };
}