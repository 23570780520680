import React, { useEffect, useRef, useState, createRef } from "react";
import imgLoader from '../Images/MeddipayLoaderEllipsis.gif';
import { Button, Modal, Box, Grid, Typography, TextField, FormControlLabel, Checkbox, RadioGroup, Radio, CircularProgress } from '@mui/material';
import { useCreditRequest } from "../Context/useCreditRequest";
import TarsService from "../services/TarsService";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { useCreditRequestAuth } from "../Context/useCreditRequestAuth";
import CustomSelect from "../components/CustomSelect";
import Privacy from "./Privacy";
import PersonalData from "./PersonalData";
import useForm from "../hooks/useForm";
import Iconify from '../components/iconify';
import { useGlobalProvider } from '../components/GlobalProvider'

const esBisiesto = (year) => {
    return (year % 400 === 0) ? true :
        (year % 100 === 0) ? false :
            year % 4 === 0;
};

const NewCustomerS2 = ({ handleChangeStep, handleCloseModal }) => {

    const { getStorage, setStorage } = useCreditRequestAuth();

    let request = getStorage("request");

    const initialForm = {
        firstName: request.cusFirstName ?? "",
        secondName: request.cusSecondName ?? "",
        firstSurname: request.cusFirstSurname ?? "",
        secondSurname: request.cusSecondSurname ?? "",
        year: request.year ?? "",
        month: request.month ?? "",
        day: request.day ?? "",
        country: request.cusBirthCountry ?? (request.cusDocumentType === "CCC" ? "CO" : ""),
        nationality: request.cusNationality ?? (request.cusDocumentType === "CCC" ? "CO" : ""),
        phoneCode: [{ value: "CO", name: "57" }],
        phone: request.cusPhone ?? "",
        email: request.cusEmail ?? "",
        gender: request.cusGender ?? null,
        dataPrivacyCheck: false,
        dataTreatmentCheck: false,
    };

    // const [sending, setSending] = useState(false);
    // const [countries, setCountries] = useState([]);
    // const [phoneCodes, setPhoneCodes] = useState([]);
    const [showPrivacyModal, setShowPrivacyModal] = useState(false);
    const [showPersonalDataModal, setShowPersonalDataModal] = useState(false);
    // const [genders, setGenders] = useState([]);
    const { newCustomer } = TarsService();
    const MySwal = withReactContent(Swal);

    const { sending, setSending,
        genders, countries } = useGlobalProvider();

    const firstNameRef = useRef(null);
    const firstSurnameRef = useRef(null);
    const secondSurnameRef = useRef(null);
    const phoneRef = useRef(null);
    const emailRef = useRef(null);
    const dayRef = useRef(null);
    const monthRef = useRef(null);
    const yearRef = useRef(null);
    const countryRef = createRef(null);

    const validateForm = (form) => {
        let errors = {};
        let regexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if (!form.year.trim()) { errors.year = "El campo Año es requerido"; yearRef.current.focus(); }
        if (!form.month.trim()) { errors.month = "El campo Mes es requerido"; monthRef.current.focus(); }
        if (!form.day.trim()) { errors.day = "El campo Día es requerido"; dayRef.current.focus(); }

        if (form.day && form.month && form.year) {
            if (esBisiesto(form.year) && form.month === "02" && form.day > "29") { errors.day = "El campo Día es inválido"; }
            if (!esBisiesto(form.year) && form.month === "02" && form.day > "28") { errors.day = "El campo Día es inválido"; }
            if (["04", "06", "09", "11"].includes(form.month) && form.day === "31") { errors.day = "El campo Día es inválido"; }
        }

        if (!form.dataTreatmentCheck) { errors.dataTreatmentCheck = "Debe aceptar el aviso de privacidad"; }
        if (!form.dataPrivacyCheck) { errors.dataPrivacyCheck = "Debe aceptar la política"; }
        if (!form.gender) { errors.gender = "El campo Género es requerido"; }

        if (!form.email.trim() || !regexEmail.test(form.email.trim())) { errors.email = "El correo electrónico es inválido"; emailRef.current.focus(); }

        if (!form.phone.trim()) { errors.phone = "El campo Teléfono es requerido"; phoneRef.current.focus(); }
        if (form.phone.trim() && form.phone.length !== 10) { errors.phone = "El teléfono debe tener 10 dígitos"; phoneRef.current.focus(); }

        if (!form.nationality.trim()) { errors.nationality = "El campo Nacionalidad es requerido"; }

        if (!form.country.trim()) { errors.country = "El campo País de nacimiento es requerido"; countryRef.current.focus(); }

        if (!form.firstSurname.trim() || form.firstSurname.length <= 1) { errors.firstSurname = "El campo Primer apellido es requerido"; firstSurnameRef.current.focus(); }
        if (!form.firstName.trim() || form.firstName.length <= 1) { errors.firstName = "El campo Primer nombre es requerido"; firstNameRef.current.focus(); }

        return errors;
    };

    const { form, errors, handleChange, handleChecked, handleSubmit, handleChangeDate, handleChangeSelect } = useForm(initialForm, validateForm);

    const handleClosePrivacyModal = () => setShowPrivacyModal(false);
    const handleClosePersonalDataModal = () => setShowPersonalDataModal(false);
    const handleShowPrivacyModal = () => setShowPrivacyModal(true);
    const handleShowPersonalDataModal = () => setShowPersonalDataModal(true);

    useEffect(() => {
        // setLogoSize({ width: "240px", marginTop: "40px", marginBottom: "30px" });
        // setSending(true);

        // getCT().then(data => {
        //     setSending(false);
        //     setCountries(data.map(el => ({ value: el.value, name: el.OriginalName })));
        //     setPhoneCodes(data.map(el => ({ value: el.value, name: el.Phonecode })));
        // });

        // getEC().then(data => {
        //     const genderOptions = data.find(el => el.EnumerationId === "cusEnumGender");
        //     if (genderOptions) {
        //         setGenders(genderOptions.EnumerationValues.map(el => ({ value: el.value, name: el.label })));
        //     }
        // });
    }, []);

    const submitForm = (form, error) => {
        if (!error) {
            // setSending(true);

            // let body = {
            //     cusType: request.cusType ?? "REG",
            //     cusDocumentType: request.cusDocumentType,
            //     cusDocument: request.cusDocument,
            //     cusFirstName: form.firstName,
            //     cusSecondName: form.secondName,
            //     cusFirstSurname: form.firstSurname,
            //     cusSecondSurname: form.secondSurname,
            //     cusGender: form.gender,
            //     cusBirthDate: `${form.year}-${('00' + form.month).slice(-2)}-${('00' + form.day).slice(-2)}`,
            //     cusNationality: form.nationality,
            //     cusBirthCountry: form.country,
            //     cusEmail: form.email,
            //     cusPhoneCountry: form.phoneCode[0].value,
            //     cusPhone: form.phone,
            //     cusConditionsAcceptance: true,
            // };

            // newCustomer(body).then(resCus => {
            //     setSending(false);

            //     if (resCus.status === 201) {
            //         let data = JSON.parse(resCus.data.data);

            //         setStorage("request", {
            //             ...request,
            //             cusPhone: form.phone,
            //             cusPhoneCountry: form.phoneCode[0].value,
            //             phoneCodeTemp: "+" + form.phoneCode[0].name,
            //             cusEmail: form.email,
            //             cusFirstName: form.firstName,
            //             cusSecondName: form.secondName,
            //             cusFirstSurname: form.firstSurname,
            //             cusSecondSurname: form.secondSurname,
            //             year: form.year,
            //             month: form.month,
            //             day: form.day,
            //             cusNationality: form.nationality,
            //             cusBirthCountry: form.country,
            //             cusGender: form.gender,
            //             state: data.ValidCommunication ? 4 : 2,
            //         });
            //         handleChangeStep(2);
            //     } else {
            //         MySwal.fire({
            //             html: resCus.data.message,
            //             icon: 'error',
            //             customClass: {
            //                 container: 'swal2-container'
            //             }
            //         });
            //     }
            // });


            setStorage("request", {
                ...request,
                cusPhone: form.phone,
                cusPhoneCountry: form.phoneCode[0].value,
                phoneCodeTemp: "+" + form.phoneCode[0].name,
                cusEmail: form.email,
                cusFirstName: form.firstName,
                cusSecondName: form.secondName,
                cusFirstSurname: form.firstSurname,
                cusSecondSurname: form.secondSurname,
                year: form.year,
                month: form.month,
                day: form.day,
                cusNationality: form.nationality,
                cusBirthCountry: form.country,
                cusGender: form.gender,
                state: 2,
            });
            handleChangeStep(2);

        } else {
            MySwal.fire({
                html: "Completa todos los campos",
                icon: 'warning',
                customClass: {
                    container: 'swal2-container'
                }
            });
        }
    };

    const modalStyle = {
        margin: "50px auto",
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        pt: 2,
        px: 4,
        pb: 3,
    };

    return (
        <>
            <Box
                sx={{
                    ...modalStyle, width: '80%'
                }}
            >
                <Grid item xs={12} sm={12} md={12} style={{ textAlign: "right", marginTop: "0px", paddingTop: "0px" }}>
                    <button style={{ border: "none", backgroundColor: "transparent", fontWeight: "bold", fontSize: "30px", cursor: "pointer", marginTop: "0px" }} onClick={handleCloseModal} ><Iconify sx={{ width: "30px", height: "30px" }} icon="eva:close-outline" /></button>

                </Grid>

                <form onSubmit={(e) => handleSubmit(e, submitForm)}>
                    <Box sx={{ width: { xs: '100%', sm: '80%', md: '60%' }, margin: '0 auto' }}>

                        <Grid container spacing={2}>
                            <Grid item xs={12} textAlign="center">
                                <Typography variant="body2" color="gray">
                                    {request.cusDocumentTypeTemp} {request.cusDocument}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} textAlign="center">
                                <Typography variant="h5" color="gray">
                                    <b style={{ color: "black" }}>1</b>/4
                                </Typography>
                            </Grid>

                            {/* Nombres */}
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    disabled={request.disableName}
                                    inputRef={firstNameRef}
                                    name="firstName"
                                    value={form.firstName}
                                    onChange={handleChange}
                                    label="Primer nombre"
                                    variant="outlined"
                                    fullWidth
                                    error={!!errors.firstName}
                                    helperText={errors.firstName}
                                    autoComplete="off"
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    disabled={request.disableName}
                                    name="secondName"
                                    value={form.secondName}
                                    onChange={handleChange}
                                    label="Segundo nombre"
                                    variant="outlined"
                                    fullWidth
                                    error={!!errors.secondName}
                                    helperText={errors.secondName}
                                    autoComplete="off"
                                />
                            </Grid>

                            {/* Apellidos */}
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    disabled={request.disableName}
                                    inputRef={firstSurnameRef}
                                    name="firstSurname"
                                    value={form.firstSurname}
                                    onChange={handleChange}
                                    label="Primer apellido"
                                    variant="outlined"
                                    fullWidth
                                    error={!!errors.firstSurname}
                                    helperText={errors.firstSurname}
                                    autoComplete="off"
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    disabled={request.disableName}
                                    inputRef={secondSurnameRef}
                                    name="secondSurname"
                                    value={form.secondSurname}
                                    onChange={handleChange}
                                    label="Segundo apellido"
                                    variant="outlined"
                                    fullWidth
                                    error={!!errors.secondSurname}
                                    helperText={errors.secondSurname}
                                    autoComplete="off"
                                />
                            </Grid>

                            {/* País de nacimiento */}
                            <Grid item xs={12} sx={{ mt: "0px" }}>
                                <CustomSelect
                                    ref={countryRef}
                                    name="country"
                                    value={form.country}
                                    options={countries.map(el => { return { value: el.value, name: el.label } })}
                                    text="País de nacimiento"
                                    error={errors.country}
                                    handleChangeSelect={handleChangeSelect}
                                />
                            </Grid>

                            {/* Nacionalidad (si aplica) */}
                            {request.cusDocumentType !== "CCC" && (
                                <Grid item xs={12}>
                                    <CustomSelect
                                        name="nationality"
                                        value={form.nationality}
                                        options={countries.map(el => { return { value: el.value, name: el.label } })}
                                        text="Nacionalidad"
                                        error={errors.nationality}
                                        handleChangeSelect={handleChangeSelect}
                                    />
                                </Grid>
                            )}

                            {/* Teléfono */}
                            <Grid item xs={12}>
                                <TextField
                                    inputRef={phoneRef}
                                    name="phone"
                                    value={form.phone}
                                    onChange={handleChange}
                                    label="Teléfono"
                                    variant="outlined"
                                    fullWidth
                                    error={!!errors.phone}
                                    helperText={errors.phone}
                                    inputProps={{ maxLength: 10 }}
                                    autoComplete="off"
                                />
                            </Grid>

                            {/* Email */}
                            <Grid item xs={12}>
                                <TextField
                                    inputRef={emailRef}
                                    name="email"
                                    value={form.email}
                                    onChange={handleChange}
                                    label="Correo electrónico"
                                    variant="outlined"
                                    fullWidth
                                    error={!!errors.email}
                                    helperText={errors.email}
                                    autoComplete="off"
                                />
                            </Grid>

                            {/* Fecha de nacimiento */}
                            <Grid item xs={12}>
                                <Typography variant="h6" color="gray" textAlign="center">
                                    Fecha de nacimiento
                                </Typography>
                                <Grid container spacing={2}>
                                    <Grid item xs={4}>
                                        <TextField
                                            inputRef={dayRef}
                                            name="day"
                                            value={form.day}
                                            onChange={handleChangeDate}
                                            label="Día"
                                            variant="outlined"
                                            fullWidth
                                            error={!!errors.day}
                                            helperText={errors.day}
                                            inputProps={{ inputMode: 'numeric', min: 1, max: 31 }}
                                            autoComplete="off"
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <TextField
                                            inputRef={monthRef}
                                            name="month"
                                            value={form.month}
                                            onChange={handleChangeDate}
                                            label="Mes"
                                            variant="outlined"
                                            fullWidth
                                            error={!!errors.month}
                                            helperText={errors.month}
                                            inputProps={{ inputMode: 'numeric', min: 1, max: 12 }}
                                            autoComplete="off"
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <TextField
                                            inputRef={yearRef}
                                            name="year"
                                            value={form.year}
                                            onChange={handleChange}
                                            label="Año"
                                            variant="outlined"
                                            fullWidth
                                            error={!!errors.year}
                                            helperText={errors.year}
                                            inputProps={{ inputMode: 'numeric', min: 1900, max: new Date().getFullYear() - 18 }}
                                            autoComplete="off"
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>

                            {/* Género */}
                            <Grid item xs={12}>
                                <Typography variant="h6" color="gray" textAlign="center">
                                    Género
                                </Typography>
                                <RadioGroup
                                    row
                                    aria-label="gender"
                                    name="gender"
                                    value={form.gender || ''}
                                    onChange={handleChange}
                                    sx={{ justifyContent: 'center' }}
                                >
                                    {genders.map((el) => (
                                        <FormControlLabel
                                            key={el.value}
                                            value={el.value}
                                            control={<Radio required />}
                                            label={el.name}
                                        />
                                    ))}
                                </RadioGroup>
                                {errors.gender && <Typography color="error" variant="body2">{errors.gender}</Typography>}
                            </Grid>

                            {/* Política de privacidad */}
                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={form.dataPrivacyCheck}
                                            onChange={
                                                (e) => {
                                                    handleChecked({ target: { name: "dataTreatmentCheck", checked: e.target.checked } });
                                                    handleChecked({ target: { name: "dataPrivacyCheck", checked: e.target.checked } });
                                                }
                                            }
                                            name="dataPrivacyCheck"
                                        />
                                    }
                                    label={
                                        <p style={{ textAlign: "justify" }}>
                                            Al diligenciar la presente solicitud de crédito, en calidad de aliado de Meddipay, manifestamos nuestra responsabilidad en relación con el diligenciamiento de la solicitud de crédito en nombre del cliente y responderemos a Meddipay por cualquier suplantación o inexactitud, para lo cual autorizamos el descuento de dicho crédito de los siguientes desembolsos debidamente aprobados.
                                        </p>
                                    }
                                />
                                {errors.dataPrivacyCheck && <Typography color="error" variant="body2">{errors.dataPrivacyCheck}</Typography>}
                            </Grid>

                            {/* Aviso de privacidad */}
                            {/* <Grid item xs={12}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={form.dataTreatmentCheck}
                                            onChange={handleChecked}
                                            name="dataTreatmentCheck"
                                        />
                                    }
                                    label={
                                        <>
                                            Acepto el{' '}
                                            <u style={{ color: 'blue', cursor: 'pointer' }} onClick={handleShowPrivacyModal}>
                                                Aviso de privacidad
                                            </u>{' '}
                                            y a ser contactado por los siguientes canales de cobranza: SMS, Correo electrónico, Llamada telefónica, Mensaje por aplicaciones
                                        </>
                                    }
                                />
                                {errors.dataTreatmentCheck && <Typography color="error" variant="body2">{errors.dataTreatmentCheck}</Typography>}
                            </Grid> */}

                            {/* Botones */}
                            <Grid item xs={12} textAlign="center">
                                <Button
                                    variant="outlined"
                                    color="secondary"
                                    sx={{ marginRight: 2 }}
                                    disabled={sending}
                                    onClick={() => {
                                        setStorage("request", { ...request, state: 0 })
                                        handleChangeStep(0);
                                    }}
                                    style={{ textDecoration: 'none' }}
                                >
                                    Regresar
                                </Button>
                                <Button type="submit" variant="contained" color="primary" disabled={sending}>
                                    Continuar
                                </Button>
                            </Grid>

                            {/* Loader */}
                            {sending && (
                                <Grid item xs={12} textAlign="center">
                                    <CircularProgress />
                                </Grid>
                            )}
                        </Grid>
                    </Box>
                </form>

                {/* Modal de Aviso de Privacidad */}
                <Modal
                    open={showPrivacyModal}
                    onClose={handleClosePrivacyModal}
                    aria-labelledby="modal-privacy-title"
                    aria-describedby="modal-privacy-description"
                >
                    <Box sx={{ maxWidth: '80%', margin: 'auto', mt: 5, bgcolor: 'background.paper', p: 4 }}>
                        <Button onClick={handleClosePrivacyModal} sx={{ float: 'right' }}>Cerrar</Button>
                        <Box sx={{ overflowY: 'scroll', maxHeight: '500px' }}>
                            <Privacy />
                        </Box>
                        <Box sx={{ textAlign: 'center', mt: 2 }}>
                            <Button variant="contained" onClick={() => { handleChecked({ target: { name: "dataTreatmentCheck", checked: true } }); handleClosePrivacyModal(); }}>
                                He leído y acepto
                            </Button>
                            <Button variant="outlined" onClick={() => { handleChecked({ target: { name: "dataTreatmentCheck", checked: false } }); handleClosePrivacyModal(); }} sx={{ ml: 2 }}>
                                No acepto
                            </Button>
                        </Box>
                    </Box>
                </Modal>

                {/* Modal de Política de Privacidad */}
                <Modal
                    open={showPersonalDataModal}
                    onClose={handleClosePersonalDataModal}
                    aria-labelledby="modal-personal-data-title"
                    aria-describedby="modal-personal-data-description"
                >
                    <Box sx={{ maxWidth: '80%', margin: 'auto', mt: 5, bgcolor: 'background.paper', p: 4 }}>
                        <Button onClick={handleClosePersonalDataModal} sx={{ float: 'right' }}>Cerrar</Button>
                        <Box sx={{ overflowY: 'scroll', maxHeight: '500px' }}>
                            <PersonalData />
                        </Box>
                        <Box sx={{ textAlign: 'center', mt: 2 }}>
                            <Button variant="contained" onClick={() => { handleChecked({ target: { name: "dataPrivacyCheck", checked: true } }); handleClosePersonalDataModal(); }}>
                                He leído y acepto
                            </Button>
                            <Button variant="outlined" onClick={() => { handleChecked({ target: { name: "dataPrivacyCheck", checked: false } }); handleClosePersonalDataModal(); }} sx={{ ml: 2 }}>
                                No acepto
                            </Button>
                        </Box>
                    </Box>
                </Modal>
            </Box>
        </>
    );
};

export default NewCustomerS2;
