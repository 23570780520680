import axios from "axios";
import { useNavigate } from 'react-router-dom';
import { BackEndAzureApi } from "../Config";
import { RefreshToken } from './SoraService'
import { useCreditRequestAuth } from "../Context/useCreditRequestAuth";



const TarsService = () => {

    const navigate = useNavigate();
    const { getStorage } = useCreditRequestAuth();

    const axiosInstance = axios.create({
        baseURL: `${process.env.REACT_APP_URL_TARS}`, // Replace with your API base URL
    });



    axiosInstance.interceptors.request.use(
        async (config) => {

            const token = localStorage.getItem("token");
            const expiredTime = localStorage.getItem("expiredTime");
            const remember = localStorage.getItem("remember");

            if (expiredTime && new Date().getTime() > expiredTime) {


                if (remember === "1") {

                    const res = await RefreshToken({ AccessToken: localStorage.getItem("token"), RefreshToken: localStorage.getItem("refreshToken") })

                    if (res.status === 200) {
                        const data = res.data.data
                        localStorage.setItem("token", data.token);
                        localStorage.setItem("refreshToken", data.refreshToken);
                        localStorage.setItem("expiredTime", Date.parse(data.expiredTime));
                        localStorage.setItem("userName", data.userName);


                        config.headers.authorization = `Bearer ${data.token}`
                    } else {
                        localStorage.removeItem("token");
                        localStorage.removeItem("refreshToken");
                        localStorage.removeItem("expiredTime");
                        localStorage.removeItem("userName");
                        localStorage.removeItem("fullName");
                        localStorage.removeItem("email");
                        localStorage.removeItem("phone");
                        localStorage.removeItem("avatar");
                        localStorage.removeItem("firstLogin");
                        localStorage.removeItem("remember");
                        navigate('/', { replace: true });
                    }
                } else {
                    localStorage.removeItem("token");
                    localStorage.removeItem("refreshToken");
                    localStorage.removeItem("expiredTime");
                    localStorage.removeItem("userName");
                    localStorage.removeItem("fullName");
                    localStorage.removeItem("email");
                    localStorage.removeItem("phone");
                    localStorage.removeItem("avatar");
                    localStorage.removeItem("firstLogin");
                    localStorage.removeItem("remember");
                    navigate('/', { replace: true });
                }




            }
            else if (token) {
                if (config.headers) config.headers.authorization = `Bearer ${token}`;
            }

            return config;
        },
        (error) => {
            // Handle request errors here

            return Promise.reject(error);
        }
    );

    const SearchInvitations = async () => {

        const respose = await axiosInstance.get('AGORA/Invitation/SearchInvitations',
            {
                headers: {
                    'content-type': 'application/json'
                }
            })
            .then(data => {
                return data;
            })
            .catch((error) => {

                if (error.response) {
                    return error.response; // => the response payload 
                }
                return { status: 500, data: { message: "ERROR" } }


            });
        return respose;
    };

    const GetEnumsCustomer = async () => {

        const respose = await axiosInstance.get('CacheData/GetEnumsCustomer',
            {
                headers: {
                    'content-type': 'application/json'
                }
            })
            .then(data => {
                return data;
            })
            .catch((error) => {

                if (error.response) {
                    return error.response; // => the response payload 
                }
                return { status: 500, data: { message: "ERROR" } }


            });
        return respose;
    };

    const GetTreatments = async () => {

        const respose = await axiosInstance.get('AGORA/POS/GetTreatments',
            {
                headers: {
                    'content-type': 'application/json'
                }
            })
            .then(data => {
                return data;
            })
            .catch((error) => {

                if (error.response) {
                    return error.response; // => the response payload 
                }
                return { status: 500, data: { message: "ERROR" } }


            });
        return respose;
    };

    const SaveChangesInvitation = async (body, IdInvitation) => {

        const respose = await axiosInstance.post(`AGORA/Invitation/SaveChangesInvitation${(IdInvitation ? `?IdInvitation=${IdInvitation}` : "")}`,
            body,
            {
                headers: {
                    'content-type': 'application/json'
                }
            })
            .then(data => {
                return data;
            })
            .catch((error) => {

                if (error.response) {
                    return error.response; // => the response payload 
                }
                return { status: 500, data: { message: "ERROR" } }


            });
        return respose;
    };

    const CancelInvitation = async (IdInvitation) => {

        const respose = await axiosInstance.delete(`AGORA/Invitation/CancelInvitation?IdInvitation=${IdInvitation}`,

            {
                headers: {
                    'content-type': 'application/json'
                }
            })
            .then(data => {
                return data;
            })
            .catch((error) => {

                if (error.response) {
                    return error.response; // => the response payload 
                }
                return { status: 500, data: { message: "ERROR" } }


            });
        return respose;
    };


    const GraphicsData = async (IdGraphic) => {

        const respose = await axiosInstance.get(`AGORA/Invitations/GraphicsData?IdGraphic=${IdGraphic}`,
            {
                headers: {
                    'content-type': 'application/json'
                }
            })
            .then(data => {
                return data;
            })
            .catch((error) => {

                if (error.response) {
                    return error.response; // => the response payload 
                }
                return { status: 500, data: { message: "ERROR" } }


            });
        return respose;

    };


    const GetPosById = async () => {

        const respose = await axiosInstance.get(`AGORA/POS/GetPosById?UserName=${localStorage.getItem("userName")}`,
            {
                headers: {
                    'content-type': 'application/json'
                }
            })
            .then(data => {
                return data;
            })
            .catch((error) => {

                if (error.response) {
                    return error.response; // => the response payload 
                }
                return { status: 500, data: { message: "ERROR" } }


            });
        return respose;
    };

    const GetCountries = async () => {

        const respose = await axiosInstance.get('CacheData/GetCountries',
            {
                headers: {
                    'content-type': 'application/json'
                }
            })
            .then(data => {
                return data;
            })
            .catch((error) => {

                if (error.response) {
                    return error.response; // => the response payload 
                }
                return { status: 500, data: { message: "ERROR" } }


            });
        return respose;
    };

    const GetGeographic = async () => {

        const respose = await axiosInstance.get('CacheData/GetGeographic',
            {
                headers: {
                    'content-type': 'application/json'
                }
            })
            .then(data => {
                return data;
            })
            .catch((error) => {

                if (error.response) {
                    return error.response; // => the response payload 
                }
                return { status: 500, data: { message: "ERROR" } }


            });
        return respose;
    };

    const GetAdminCache = async () => {

        const respose = await axiosInstance.get('CacheData/GetAdminCache',
            {
                headers: {
                    'content-type': 'application/json'
                }
            })
            .then(data => {
                return data;
            })
            .catch((error) => {

                if (error.response) {
                    return error.response; // => the response payload 
                }
                return { status: 500, data: { message: "ERROR" } }


            });
        return respose;
    };

    const SaveMainPosIconAgora = async (body) => {

        const respose = await axiosInstance.put("Files/SaveMainPosIconAgora",
            body,
            {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            })
            .then((data) => {
                return data;
            })
            .catch((error) => {
                if (error.response) {
                    return error.response; // => the response payload 
                }
                return { status: 500, data: { message: "ERROR" } }
            })

        return respose;

    };


    const GetPosOriginations = async (DayFrom, DayTo) => {

        const respose = await axiosInstance.get(`AGORA/POS/GetPosOriginations${DayFrom && DayTo ? `?DayFrom=${DayFrom}&DayTo=${DayTo}` : ""}`,
            {
                headers: {
                    'content-type': 'application/json'
                }
            })
            .then(data => {
                return data;
            })
            .catch((error) => {

                if (error.response) {
                    return error.response; // => the response payload 
                }
                return { status: 500, data: { message: "ERROR" } }


            });
        return respose;
    };

    const GetOpenDisbursementsByIdPos = async (DayFrom, DayTo) => {

        const respose = await axiosInstance.get(`AGORA/POS/GetOpenDisbursementsByIdPos${DayFrom && DayTo ? `?DayFrom=${DayFrom}&DayTo=${DayTo}` : ""}`,
            {
                headers: {
                    'content-type': 'application/json'
                }
            })
            .then(data => {
                return data;
            })
            .catch((error) => {

                if (error.response) {
                    return error.response; // => the response payload 
                }
                return { status: 500, data: { message: "ERROR" } }


            });
        return respose;
    };

    const GetClosedDisbursementsByIdPos = async (DayFrom, DayTo) => {

        const respose = await axiosInstance.get(`AGORA/POS/GetClosedDisbursementsByIdPos${DayFrom && DayTo ? `?DayFrom=${DayFrom}&DayTo=${DayTo}` : ""}`,
            {
                headers: {
                    'content-type': 'application/json'
                }
            })
            .then(data => {
                return data;
            })
            .catch((error) => {

                if (error.response) {
                    return error.response; // => the response payload 
                }
                return { status: 500, data: { message: "ERROR" } }


            });
        return respose;
    };


    const UpdatePos = async (body) => {

        const respose = await axiosInstance.put(`AGORA/POS/UpdatePos`,
            body,
            {
                headers: {
                    'content-type': 'application/json'
                }
            })
            .then(data => {
                return data;
            })
            .catch((error) => {

                if (error.response) {
                    return error.response; // => the response payload 
                }
                return { status: 500, data: { message: "ERROR" } }


            });
        return respose;
    };


    const UpdateMovement = async (IdMovement, body) => {

        const respose = await axiosInstance.put(`/PointOfSell/UpdateMovement?IdMovement=${IdMovement}`,
            body,
            {
                headers: {
                    'content-type': 'application/json'
                }
            })
            .then(data => {
                return data;
            })
            .catch((error) => {

                if (error.response) {
                    return error.response; // => the response payload 
                }
                return { status: 500, data: { message: "ERROR" } }


            });
        return respose;
    };


    const GetReportData = async (body) => {

        const respose = await axiosInstance.post(`/AGORA/POS/GetReportData`,
            body,
            {
                headers: {
                    'content-type': 'application/json'
                }
            })
            .then(data => {
                return data;
            })
            .catch((error) => {

                if (error.response) {
                    return error.response; // => the response payload 
                }
                return { status: 500, data: { message: "ERROR" } }


            });
        return respose;
    };

    const GetUrlSas = async (fileName) => {

        const respose = await axiosInstance.get(`Files/GetUrlSas?fileName=${fileName}`,
            {
                headers: {
                    'content-type': 'application/json'
                }
            })
            .then(data => {
                return data;
            })
            .catch((error) => {

                if (error.response) {
                    return error.response; // => the response payload 
                }
                return { status: 500, data: { message: "ERROR" } }


            });
        return respose;
    };

    const CreateCustomerANI = async (body) => {

        let respose = await axios.post(BackEndAzureApi("CHAN/Customer/CreateCustomerANI"),
            body,
            {
                headers: {
                    'content-type': 'application/json',
                    "Authorization": "Bearer " + getStorage("creditRequestToken")
                }
            })
            .then((data) => {
                return data;
            })
            .catch((error) => {
                if (error.response) {
                    return error.response; // => the response payload 
                }
                return { status: 500, data: { message: "ERROR" } }
            })

        return respose;

    };

    const newCustomer = async (body) => {

        let respose = await axios.post(BackEndAzureApi("CHAN/Customer/SaveCustomer"),
            body,
            {
                headers: {
                    'content-type': 'application/json',
                    "Authorization": "Bearer " + getStorage("creditRequestToken")
                }
            })
            .then((res) => {
                return res;
            })
            .catch((error) => {
                if (error.response) {
                    return error.response; // => the response payload 
                }
                return { error: 1 }
            })

        return respose;

    };

    const SavePhotoCustomerFromJWT = async (body) => {

        let respose = await axios.post(BackEndAzureApi("Files/SavePhotoCustomerFromJWT"),
            body,
            {
                headers: {
                    'content-type': 'multipart/form-data',
                    "Authorization": "Bearer " + getStorage("creditRequestToken")
                }
            })
            .then((data) => {
                return data;
            })
            .catch((error) => {
                if (error.response) {
                    return error.response; // => the response payload 
                }
                return { status: 500, data: { message: "ERROR" } }
            })

        return respose;

    };

    const IdentityValidation = async (body) => {

        let respose = await axios.post(BackEndAzureApi("Customer/IdentityValidation"),
            body,
            {
                headers: {
                    'content-type': 'application/json',
                    "Authorization": "Bearer " + getStorage("creditRequestToken")
                }
            })
            .then((data) => {
                return data;
            })
            .catch((error) => {
                if (error.response) {
                    return error.response; // => the response payload 
                }
                return { status: 500, data: { message: "ERROR" } }
            })

        return respose;

    };

    const SetManualValidationCustomerFromJWT = async (cusEmail, cusFullName, Cedula) => {

        let respose = await axios.put(BackEndAzureApi(`Files/SetManualValidationCustomerFromJWT?cusEmail=${cusEmail}&cusFullName=${cusFullName}&Cedula=${Cedula}`),
            null,
            {
                headers: {
                    'content-type': 'multipart/form-data',
                    "Authorization": "Bearer " + getStorage("creditRequestToken")
                }
            })
            .then((data) => {
                return data;
            })
            .catch((error) => {
                if (error.response) {
                    return error.response; // => the response payload 
                }
                return { status: 500, data: { message: "ERROR" } }
            })

        return respose;

    };


    const GetCustomerANI = async (body) => {

        let respose = await axios.post(BackEndAzureApi("AGORA/Customer/GetCustomerANI"),
            body,
            {
                headers: {
                    'content-type': 'application/json',
                    "Authorization": "Bearer " + getStorage("creditRequestToken")
                }
            })
            .then((data) => {
                return data;
            })
            .catch((error) => {
                if (error.response) {
                    return error.response; // => the response payload 
                }
                return { status: 500, data: { message: "ERROR" } }
            })

        return respose;

    };

    const CalculateCreditLimit = async (IdCustomer) => {

        let respose = await axiosInstance.get(BackEndAzureApi("CHAN/CreditLimit/CalculateCreditLimit?idCustomer=" + IdCustomer),

            {
                headers: {
                    'content-type': 'application/json'
                }
            })
            .then((data) => {
                return data;
            })
            .catch((error) => {

                if (error.response) {
                    return error.response; // => the response payload 
                }
                return { status: 500, data: { message: "ERROR" } }
            })

        return respose;

    };


    const GetQuestions = async (body) => {

        let respose = await axios.post(BackEndAzureApi("CHAN/Evidente/GetQuestions"),
            body,
            {
                headers: {
                    'content-type': 'application/json',
                    "Authorization": "Bearer " + getStorage("creditRequestToken")
                }
            })
            .then((data) => {
                return data;
            })
            .catch((error) => {
                if (error.response) {
                    return error.response; // => the response payload 
                }
                return { status: 500, data: { message: "ERROR" } }
            })

        return respose;

    };

    const SetAnswers = async (body) => {

        let respose = await axios.post(BackEndAzureApi("CHAN/Evidente/SetAnswers"),
            body,
            {
                headers: {
                    'content-type': 'application/json',
                    "Authorization": "Bearer " + getStorage("creditRequestToken")
                }
            })
            .then((data) => {
                return data;
            })
            .catch((error) => {
                if (error.response) {
                    return error.response; // => the response payload 
                }
                return { status: 500, data: { message: "ERROR" } }
            })

        return respose;

    };


    const GetCustomers = async (IdCustomer) => {

        let respose = await axiosInstance.get(BackEndAzureApi("AGORA/Customer/GetCustomers"),

            {
                headers: {
                    'content-type': 'application/json'
                }
            })
            .then((data) => {
                return data;
            })
            .catch((error) => {

                if (error.response) {
                    return error.response; // => the response payload 
                }
                return { status: 500, data: { message: "ERROR" } }
            })

        return respose;

    };

    return {
        SearchInvitations, GetEnumsCustomer, GetTreatments, SaveChangesInvitation, CancelInvitation,
        GraphicsData, GetPosById, GetCountries, GetGeographic, GetAdminCache, SaveMainPosIconAgora,
        GetPosOriginations, UpdatePos, GetOpenDisbursementsByIdPos, GetClosedDisbursementsByIdPos,
        UpdateMovement, GetReportData, GetUrlSas, CreateCustomerANI, newCustomer, SavePhotoCustomerFromJWT,
        IdentityValidation, SetManualValidationCustomerFromJWT, GetCustomerANI, CalculateCreditLimit,
        GetQuestions, SetAnswers, GetCustomers
    }
}


export default TarsService;