import { BackEndAzureApi } from "../Config";

export const getCountries = async () => {
  let res = await fetch(BackEndAzureApi("CacheData/GetCountries"));

  if (res.ok) {
    let data = await res.json();

    let cache = JSON.parse(data.data).cache;
    return cache;
  }
};

export const getCountriesState = async () => {
  let res = await fetch(BackEndAzureApi("CacheData/GetGeographic"));

  if (res.ok) {
    let data = await res.json();

    let cache = JSON.parse(data.data).cache;
    return cache;
  }
};