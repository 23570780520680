import { BackEndAzureApi } from "../Config";

export const getEnumsCustomer = async () => {
  let response = await fetch(BackEndAzureApi("CacheData/GetEnumsCustomer"));
  
  if (response.ok) {
    let data = await response.json();

    let cache = JSON.parse(data.data).cache;
    return cache;
  }
};

export const getEnumsPointOfSell = async () => {
  let res = await fetch(BackEndAzureApi("CacheData/GetEnumsPos"));
  if (res.ok) {
    let dataPos = await res.json();
    let cacheEnumsPos = JSON.parse(dataPos.data.cache);
    return cacheEnumsPos;
  }
};

export const getEnumsPointOfSellData = async () => {
  let res = await fetch(BackEndAzureApi("CacheData/GetPosData"));
  if (res.ok) {
    let dataPosData = await res.json();
    let cacheEnumsPosData = JSON.parse(dataPosData.data.cache);
    return cacheEnumsPosData;
  }
};
