
import React, { useState, useEffect } from 'react';

// @mui
import { LoadingButton } from '@mui/lab';
import { NumericFormat } from "react-number-format";
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import 'dayjs/locale/es';
import { styled } from '@mui/material/styles';

import {
    Modal,
    Box,
    Grid,
    Autocomplete,
    TextField,
    Chip,
    InputAdornment,
    Snackbar,
    Alert,
    Paper,
    Typography,
    Tooltip,
    Select,
    MenuItem,
    InputLabel,
    FormControl,
    FormControlLabel,
    Switch,
    ClickAwayListener,
    Icon,
    RadioGroup,
    Radio
} from '@mui/material';

import Iconify from '../components/iconify';


import TarsService from "../services/TarsService"
import BellService from "../services/BellService"
import { useGlobalProvider } from '../components/GlobalProvider'
import useResponsive from '../hooks/useResponsive';


export default function NewInvitation({ isUpdate, openModal, setOpenModal, invitationData, setInvitationData, initialInvitation, setOpen = () => { }, handleInvitations = () => { } }) {

    const [loadingButton, setLoadingButton] = useState(false);
    const [snackbarData, setSnackbarData] = useState({ message: "msg", severity: "warning" });
    const [openSnackbar, setOpenSnackbar] = useState(false);
    // const [treatments, setTreatments] = useState([]);
    // const [documentTypes, setDocumentTypes] = useState([]);
    const [errors, setErrors] = useState({});
    // const [sending, setSending] = useState(false);
    const [notifyClient, setNotifyClient] = useState(false);
    // const [patientSwitch, setpatientSwitch] = useState(invitationData.patientSwitch ?? "");
    const [patientsErrors, setpatientsErrors] = useState([]);
    const xsUp = useResponsive('up', 'md');
    // const [notificationTypes, setNotificationTypes] = useState(null);

    const { SaveChangesInvitation } = TarsService();
    const { SendTemplateEmail, SendTemplateSMS } = BellService();
    const [openTooltip1, setOpenTooltip1] = useState(false);

    const [posGroups, setPosGroups] = useState([]);

    const { posData, treatments, documentTypes, notificationTypes, invitationsStatus, sending,
        documentTypespatient, countries, patientRelations } = useGlobalProvider();

    useEffect(() => {




        // setSending(true);


        // const a = GetEnumsCustomer()

        // const c = GetTreatments();

        // Promise.all([a, c]).then(values => {


        //     setSending(false)
        //     const enumsCustomer = JSON.parse(values[0].data.data)

        //     const treatmentsTemp = JSON.parse(values[1].data.data)[0].PosTreatments

        //     const invitationsStatus = enumsCustomer.cache.find(s => s.EnumerationId === "cusEnumInvitationsStatus")
        //     const cusEnumDocumentType = enumsCustomer.cache.find(s => s.EnumerationId === "cusEnumDocumentType")


        //     // handleInvitations(invitationsStatus, treatmentsTemp)

        //     setDocumentTypes(cusEnumDocumentType.EnumerationValues)
        //     setNotificationTypes(enumsCustomer.cache.find(s => s.EnumerationId === "enumNotificationType").EnumerationValues)
        //     setTreatments(treatmentsTemp)
        //     setInvitationsStatus(invitationsStatus)

        // })

    }, []);

    useEffect(() => {

        if (posData) {
            // console.log(posData)

            // const tempPG = [{ Id: "NODISC_CUSRISKINT", DisplayText: "PRUEBA1" }, { Id: "NODISC_CUSRISKINT1", DisplayText: "PRUEBA2" }]

            setPosGroups(posData.posIdGroup)

            if (posData.posIdGroup === 1) {
                setInvitationData({ ...invitationData, posGroup: posData.posIdGroup[0].Id })
            }
        }

    }, [posData]);

    useEffect(() => {
        setNotifyClient(invitationData.notificationType === "EMA" || invitationData.notificationType === "SMS")
    }, [invitationData.notificationType]);


    const handleDeleteChip = (chipToDelete) => () => {

        const temp = invitationData.treatments.filter(t => t.IdTreatment !== chipToDelete.IdTreatment)

        setInvitationData({ ...invitationData, treatments: temp })
    };

    const handleChangeSwitch = (event) => {
        setNotifyClient(event.target.checked)
    }

    const handleChangeSwitchpatient = (event) => {
        setErrors(errors => { return { ...errors, customerIsPatient: false } })

        if (event.target.value === "NO" && invitationData.patients && invitationData.patients.length === 0) {
            const temppatients = invitationData.patients
            temppatients.push(getNewpatientData())
            setInvitationData({ ...invitationData, patients: temppatients, customerIsPatient: event.target.value })
        }
        else {
            setInvitationData({ ...invitationData, customerIsPatient: event.target.value })

        }
    }

    const handleAddChip = (chipToAdd) => () => {

        const tr = invitationData.treatments.find(t => t.IdTreatment === chipToAdd.IdTreatment)

        if (!tr) {
            setInvitationData({ ...invitationData, treatments: [...invitationData.treatments, chipToAdd] })
        }

    };



    const handleCloseModal = (event, reason) => {

        if (reason && reason === "backdropClick")
            return;
        setOpenModal(false)
    }

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenSnackbar(false);
    };


    const addpatient = () => {

        if (invitationData.patients && invitationData.patients.length < 3) {
            const temppatients = invitationData.patients
            temppatients.push(getNewpatientData())
            setInvitationData({ ...invitationData, patients: temppatients })
        }

    }

    const getNewpatientData = () => {
        return {
            id: crypto.randomUUID(), documentType: "CCC", document: "", DOB: "", DOBo: null, phone: "", patientRelationship: "",
            phoneCountry: {
                value: "CO",
                label: "COLOMBIA",
                OriginalName: "Colombia",
                ISO3: "COL",
                Numcode: 170,
                Phonecode: 57
            },
            firstName: "",
            secondName: "",
            firstLastName: "",
            secondLastName: "",
        }
    }

    const removepatient = (ip) => {

        if (invitationData.customerIsPatient === "NO" && invitationData.patients && invitationData.patients.length === 1) return;
        if (invitationData.patients && invitationData.patients.length > 0) {
            // const temppatients = invitationData.patients
            // temppatients.pop();
            const temppatients = invitationData.patients.filter(p => p.id !== ip.id);
            setInvitationData({ ...invitationData, patients: temppatients })
        }

    }

    const saveInvitation = (close) => {



        // const bodyPrueba = {
        //     "documentType": invitationData.documentType,
        //     "document": invitationData.document,
        //     "treatments": invitationData.treatments.map(t => { return t.IdTreatment }),
        //     "amountTreatment": String(invitationData.amountTreatment),
        //     "amountFinance": String(invitationData.amountFinance),
        //     "phoneCountry": "CO",
        //     "phone": invitationData.phone,
        //     "email": invitationData.email,
        //     "expirationDate": invitationData.expirationDate,
        //     "reference": invitationData.reference,
        //     "notificationType": notifyClient ? invitationData.notificationType : null,
        //     "customerIsPatient": patientSwitch ==="SI",
        //     "patients": invitationData.patients.map(p => {
        //         return {
        //             "patientDocumentType": p.documentType,
        //             "patientDocument": p.document,
        //             "patientPhoneCountry": p.phoneCountry.value,
        //             "patientPhone": p.phone,
        //             "patientBirthDate": p.DOB,
        //             "patientCustomerRelationship": p.patientRelationship,
        //             "patientFirstName": p.firstName,
        //             "patientSecondName": p.secondName,
        //             "patientFirstSurname": p.firstLastName,
        //             "patientSecondSurname": p.secondLastName,
        //         }
        //     })
        // }
        // console.log(bodyPrueba)
        // return;
        /* eslint-disable no-unreachable */
        setLoadingButton(true);

        setErrors({})
        let errorsB = false;


        if (!isUpdate && posGroups.length > 1 && !invitationData.posGroup) {
            setOpenSnackbar(true)
            setErrors(errors => { return { ...errors, posGroup: true } })
            setSnackbarData({ message: "El campo Tarifa es obligatorio", severity: "warning" })
            errorsB = true;
        }

        if (!invitationData.documentType) {
            setOpenSnackbar(true)
            setErrors(errors => { return { ...errors, documentType: true } })
            setSnackbarData({ message: "El campo Tipo de documento es obligatorio", severity: "warning" })
            errorsB = true;
        }

        if (!invitationData.document) {
            setOpenSnackbar(true)
            setErrors(errors => { return { ...errors, document: true } })
            setSnackbarData({ message: "El campo Documento es obligatorio", severity: "warning" })
            errorsB = true;
        }

        if (invitationData.documentType && invitationData.document && ((invitationData.documentType === "CCC" && (invitationData.document.length < 6 || invitationData.document.length > 11)) || (invitationData.documentType === "CEC" && (invitationData.document.length < 4 || invitationData.document.length > 8)))) {
            setOpenSnackbar(true)
            setErrors(errors => { return { ...errors, document: true } })
            setSnackbarData({ message: "El campo Documento no tiene la logitud adecuada", severity: "warning" })
            errorsB = true;
        }

        if (!invitationData.amountTreatment) {
            setOpenSnackbar(true)
            setErrors(errors => { return { ...errors, amountTreatment: true } })
            setSnackbarData({ message: "El campo Valor presupuesto es obligatorio", severity: "warning" })
            errorsB = true;
        }

        if (!invitationData.amountFinance) {
            setOpenSnackbar(true)
            setErrors(errors => { return { ...errors, amountFinance: true } })
            setSnackbarData({ message: "El campo Valor a financiar es obligatorio", severity: "warning" })
            errorsB = true;
        }

        if (invitationData.amountTreatment && invitationData.amountTreatment < posData.posMinPurchaseAmt) {
            setOpenSnackbar(true)
            setErrors(errors => { return { ...errors, amountTreatment: true } })
            setSnackbarData({ message: "El campo Valor presupuesto esta por debajo del mínimo", severity: "warning" })
            errorsB = true;
        }

        if (invitationData.amountFinance && invitationData.amountFinance < posData.posMinPurchaseAmt) {
            setOpenSnackbar(true)
            setErrors(errors => { return { ...errors, amountFinance: true } })
            setSnackbarData({ message: "El campo Valor a financiar esta por debajo del mínimo", severity: "warning" })
            errorsB = true;
        }

        if (invitationData.amountTreatment && invitationData.amountTreatment > posData.posMaxPurchaseAmt) {
            setOpenSnackbar(true)
            setErrors(errors => { return { ...errors, amountTreatment: true } })
            setSnackbarData({ message: "El campo Valor presupuesto esta por encima del máximo", severity: "warning" })
            errorsB = true;
        }

        if (invitationData.amountFinance && invitationData.amountFinance > posData.posMaxPurchaseAmt) {
            setOpenSnackbar(true)
            setErrors(errors => { return { ...errors, amountFinance: true } })
            setSnackbarData({ message: "El campo Valor a financiar esta por encima del máximo", severity: "warning" })
            errorsB = true;
        }

        if (invitationData.amountTreatment && invitationData.amountFinance && (invitationData.amountFinance > invitationData.amountTreatment)) {
            setOpenSnackbar(true)
            setErrors(errors => { return { ...errors, amountFinance: true } })
            setSnackbarData({ message: "El campo Valor a financiar no puede ser mayor al presupuesto", severity: "warning" })
            errorsB = true;
        }

        if (!invitationData.expirationDate) {
            setOpenSnackbar(true)
            setErrors(errors => { return { ...errors, expirationDate: true } })
            setSnackbarData({ message: "El campo Fecha de vencimiento es obligatorio", severity: "warning" })
            errorsB = true;
        }

        if (invitationData.treatments.length === 0) {
            setOpenSnackbar(true)
            setErrors(errors => { return { ...errors, treatments: true } })
            setSnackbarData({ message: "El campo Tratamientos es obligatorio", severity: "warning" })
            errorsB = true;
        }

        if (!invitationData.customerIsPatient) {
            setOpenSnackbar(true)
            setErrors(errors => { return { ...errors, customerIsPatient: true } })
            setSnackbarData({ message: "El campo ¿Paciente es el tomador del crédito? es obligatorio", severity: "warning" })
            errorsB = true;
        }


        if (invitationData.patients && invitationData.patients.length > 0) {

            const patientsErrorsTemp = []
            invitationData.patients.forEach(element => {


                const errorTemp = {
                    id: element.id
                }

                if (element.documentType && element.document && ((element.documentType === "CCC" && (element.document.length < 6 || element.document.length > 11)) || (element.documentType === "CEC" && (element.document.length < 4 || element.document.length > 8)))) {
                    setOpenSnackbar(true)
                    errorTemp.document = true;
                    setSnackbarData({ message: "El campo Documento del paciente no tiene la logitud adecuada", severity: "warning" })
                    errorsB = true;
                }

                if (!element.document) {
                    setOpenSnackbar(true)
                    errorTemp.document = true;
                    setSnackbarData({ message: "El campo Documento del paciente es obligatorio", severity: "warning" })
                    errorsB = true;
                }

                if (!element.DOB) {
                    setOpenSnackbar(true)
                    errorTemp.DOB = true;
                    setSnackbarData({ message: "El campo Fecha de nacimiento del paciente es obligatorio", severity: "warning" })
                    errorsB = true;
                }

                if (!element.firstName) {
                    setOpenSnackbar(true)
                    errorTemp.firstName = true;
                    setSnackbarData({ message: "El campo Primer nombre del paciente es obligatorio", severity: "warning" })
                    errorsB = true;
                }

                if (!element.firstLastName) {
                    setOpenSnackbar(true)
                    errorTemp.firstLastName = true;
                    setSnackbarData({ message: "El campo Primer apellido del paciente es obligatorio", severity: "warning" })
                    errorsB = true;
                }

                if (!element.patientRelationship) {
                    setOpenSnackbar(true)
                    errorTemp.patientRelationship = true;
                    setSnackbarData({ message: "El campo Relación del paciente es obligatorio", severity: "warning" })
                    errorsB = true;
                }

                if (!element.phone || element.phone.length < 10) {
                    setOpenSnackbar(true)
                    errorTemp.phone = true;
                    setSnackbarData({ message: "El campo Celular del paciente es obligatorio", severity: "warning" })
                    errorsB = true;
                }

                patientsErrorsTemp.push(errorTemp)
            });

            setpatientsErrors(patientsErrorsTemp);
        }


        if (notifyClient && !invitationData.notificationType) {
            setOpenSnackbar(true)
            setErrors(errors => { return { ...errors, notificationType: true } })
            setSnackbarData({ message: "El campo Medio de comunicación es obligatorio", severity: "warning" })
            errorsB = true;
        }

        const regexEmail = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        if (notifyClient && invitationData.notificationType && invitationData.notificationType === "EMA" && (!invitationData.email || !regexEmail.test(invitationData.email))) {
            setOpenSnackbar(true)
            setErrors(errors => { return { ...errors, email: true } })
            setSnackbarData({ message: "El campo Correo es obligatorio", severity: "warning" })
            errorsB = true;
        }


        if (notifyClient && invitationData.notificationType && invitationData.notificationType === "SMS" && (!invitationData.phone || invitationData.phone.length < 10)) {
            setOpenSnackbar(true)
            setErrors(errors => { return { ...errors, phone: true } })
            setSnackbarData({ message: "El campo Celular es obligatorio", severity: "warning" })
            errorsB = true;
        }




        if (errorsB) { setLoadingButton(false); return };

        const body = {
            "idGroup": invitationData.posGroup,
            "documentType": invitationData.documentType,
            "document": invitationData.document,
            "treatments": invitationData.treatments.map(t => { return t.IdTreatment }),
            "amountTreatment": String(invitationData.amountTreatment),
            "amountFinance": String(invitationData.amountFinance),
            "phoneCountry": "CO",
            "phone": invitationData.phone,
            "email": invitationData.email,
            "expirationDate": invitationData.expirationDateO.format("DD-MM-YYYY"),
            "reference": invitationData.reference,
            "notificationType": notifyClient ? invitationData.notificationType : null,
            "customerIsPatient": invitationData.customerIsPatient === "SI",
            "patients": invitationData.patients && invitationData.patients.length > 0 ? invitationData.patients.map(p => {
                return {
                    "idInvitationPatient": p.isExisting ? p.id : null,
                    "patientDocumentType": p.documentType,
                    "patientDocument": p.document,
                    "patientPhoneCountry": p.phoneCountry.value,
                    "patientPhone": p.phone,
                    "patientBirthDate": p.DOBo.format("DD-MM-YYYY"),
                    "patientCustomerRelationship": p.patientRelationship,
                    "patientFirstName": p.firstName,
                    "patientSecondName": p.secondName,
                    "patientFirstSurname": p.firstLastName,
                    "patientSecondSurname": p.secondLastName,
                }
            }) : null
        }

        SaveChangesInvitation(body, isUpdate ? invitationData.IdInvitation : null).then(res => {
            setLoadingButton(false);
            if (res.status === 201) {

                setOpenSnackbar(true)
                setSnackbarData({ message: isUpdate ? "Invitación actualizada satisfactoriamente" : "Invitación creada satisfactoriamente", severity: "success" })

                if (close === 1) {
                    setInvitationData(initialInvitation);
                    setOpenModal(false);
                    setOpen(null);
                }

                handleInvitations()

                if (!isUpdate && notifyClient) {
                    if (invitationData.notificationType === "EMA") {

                        const templateBody = {
                            templateId: "INV",
                            variables: {
                                oriPosCommercialName: posData.posCommercialName,
                                oriPurchaseAmount: `$${invitationData.amountTreatmentF}`,
                                oriCreditAmount: `$${invitationData.amountFinanceF}`,
                                oriTreatments: invitationData.treatments.map(t => { return t.TreatmentName }).join(", "),
                                oriInvitationExpirationDate: invitationData.expirationDate
                            },
                            to: [
                                {
                                    eMail: invitationData.email
                                }
                            ],
                            region: "co-co",
                            lang: "co"
                        }



                        SendTemplateEmail(templateBody).then(resEMA => {

                        })

                    }
                    if (invitationData.notificationType === "SMS") {
                        const templateBody = {
                            templateId: "INV",
                            variables: {
                                oriPosCommercialName: posData.posCommercialName,// .normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
                                oriPurchaseAmount: `$${invitationData.amountTreatmentF}`,
                                oriCreditAmount: `$${invitationData.amountFinanceF}`,
                                oriTreatments: invitationData.treatments.map(t => { return t.TreatmentName }).join(", "),
                                oriInvitationExpirationDate: invitationData.expirationDate
                            },
                            destination: `+57${invitationData.phone}`,
                            region: "co-co",
                            lang: "co"
                        }



                        SendTemplateSMS(templateBody).then(resSMS => {

                        })
                    }
                }

            } else {
                setOpenSnackbar(true)
                setSnackbarData({ message: "Error creando la invitación", severity: "error" })
            }

        })





    }

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    // const MenuProps = {
    //     PaperProps: {
    //         style: {
    //             maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    //             width: 250,
    //         },
    //     },
    // };

    const modalStyle = {
        margin: "50px auto",
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        pt: 2,
        px: 4,
        pb: 3,
    };

    return <>
        <Snackbar open={openSnackbar} autoHideDuration={5000} onClose={handleCloseSnackbar} anchorOrigin={{ horizontal: "center", vertical: "bottom" }}>
            <Alert onClose={handleCloseSnackbar} severity={snackbarData.severity} sx={{ width: '100%' }}>
                {snackbarData.message}
            </Alert>
        </Snackbar>

        <Modal
            open={openModal}
            onClose={handleCloseModal}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
            sx={{

                overflow: 'scroll',

            }}

        >
            <Box sx={{ ...modalStyle, width: '90%' }}>




                <Grid container spacing={2}>

                    <Grid item xs={10} sm={10} md={10}> <h2 id="parent-modal-title">{isUpdate ? "Editar invitación" : "Crear invitación de crédito"}</h2></Grid>
                    <Grid item xs={2} sm={2} md={2} style={{ textAlign: "right" }}><button style={{ border: "none", backgroundColor: "transparent", fontWeight: "bold", fontSize: "30px", cursor: "pointer", marginTop: "20px" }} onClick={() => { setInvitationData(initialInvitation); setOpenModal(false); setErrors({}); setOpen(null); }}><Iconify sx={{ width: "30px", height: "30px" }} icon="eva:close-outline" /></button></Grid>

                    {posGroups.length > 1 && <>
                        <Grid item xs={12} sm={12} md={12} style={{ paddingTop: "0px" }}>
                            <Typography variant="h6">
                                Tarifa de financiación
                            </Typography>
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} xl={12}>
                            <FormControl sx={{ width: "100%" }} disabled={isUpdate}>
                                <InputLabel htmlFor="tarifa">Tarifa</InputLabel>
                                <Select
                                    label="Tarifa"
                                    id="tarifa"
                                    sx={{ width: "100%" }}
                                    value={invitationData.posGroup}
                                    onChange={(event) => { setInvitationData({ ...invitationData, posGroup: event.target.value }) }}
                                    error={errors && errors.posGroup}
                                    endAdornment={<>{isUpdate && <Tooltip title="Por motivos de concurrencia y seguridad, la tarifa de financiación no puede ser modificada en una invitación ya creada. Si se desea modificar la tarifa de financiación, se deberá generar una nueva invitación." enterTouchDelay={0}><InputAdornment position="start" style={{ marginRight: "20px" }}>?</InputAdornment></Tooltip>}
                                    </>}
                                >
                                    {posGroups.map(d => {
                                        return <MenuItem key={d.Id} value={d.Id}>{d.DisplayText}</MenuItem>
                                    })}
                                </Select>
                            </FormControl>
                            <br />
                            <br />

                        </Grid>
                    </>}

                    <Grid item xs={12} sm={12} md={12} style={{ paddingTop: "0px" }}>
                        <Typography variant="h6">
                            Datos del tomador del crédito
                        </Typography>
                    </Grid>


                    <Grid item xs={12} sm={6} md={6} xl={4}>
                        <FormControl sx={{ width: "100%" }}>
                            <InputLabel htmlFor="document-type">Tipo de documento</InputLabel>
                            <Select
                                label="Tipo de documento"
                                id="document-type"
                                sx={{ width: "100%" }}
                                value={invitationData.documentType}
                                onChange={(event) => { setInvitationData({ ...invitationData, documentType: event.target.value }) }}
                                error={errors && errors.documentType}
                            >
                                {documentTypes.map(d => {
                                    return <MenuItem key={d.value} value={d.value}>{d.label}</MenuItem>
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} xl={4}>
                        <TextField id="Documento" label="Documento" variant="outlined" sx={{ width: "100%" }} value={invitationData.document} onChange={(event) => { setInvitationData({ ...invitationData, document: event.target.value }); setErrors(errors => { return { ...errors, document: false } }) }}
                            type="number"
                            autoComplete="off"
                            error={errors && errors.document} />
                    </Grid>
                    {/* <Grid item xs={12} sm={6} md={6} xl={3}>

                        <TextField id="Celular" label="Celular" variant="outlined" sx={{ width: "100%" }} value={invitationData.phone} onChange={(event) => { setInvitationData({ ...invitationData, phone: event.target.value }) }}
                            type="number"
                            autoComplete="off"
                            error={errors && errors.phone}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} xl={5}>
                        <TextField id="Correo" label="Correo electrónico" variant="outlined" sx={{ width: "100%" }} value={invitationData.email} onChange={(event) => { setInvitationData({ ...invitationData, email: event.target.value }) }}
                            autoComplete="off"
                            error={errors && errors.email} />
                    </Grid> */}


                    <Grid item xs={12} sm={6} md={6} xl={4}>
                        <TextField id="Referencia" label="Referencia Presupuesto" variant="outlined" sx={{ width: "100%" }} value={invitationData.reference} onChange={(event) => { setInvitationData({ ...invitationData, reference: event.target.value }) }}
                            autoComplete="off"
                            error={errors && errors.reference} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} xl={4}>

                        <NumericFormat
                            customInput={TextField}
                            id="presupuesto"
                            label="Valor del presupuesto"
                            variant="outlined"
                            valueIsNumericString
                            thousandSeparator="."
                            decimalSeparator=","
                            value={invitationData.amountTreatment}
                            decimalScale={0}
                            onValueChange={(value) => { setInvitationData({ ...invitationData, amountTreatment: value.floatValue ?? "", amountTreatmentF: value.formattedValue ?? "" }); setErrors(errors => { return { ...errors, amountTreatment: false } }) }}
                            InputProps={{
                                startAdornment:
                                    <InputAdornment position="start">$</InputAdornment>,
                                endAdornment: <InputAdornment position="end" style={{ cursor: "pointer" }} onClick={() => { setInvitationData({ ...invitationData, amountTreatment: "", amountTreatmentF: "" }) }}>X</InputAdornment>
                            }}
                            autoComplete="off"
                            error={errors && errors.amountTreatment}
                            sx={{ width: "100%" }}
                        />

                    </Grid>
                    <Grid item xs={12} sm={6} md={6} xl={4}>


                        <NumericFormat
                            customInput={TextField}
                            id="financiar"
                            label="Valor a financiar"
                            variant="outlined"
                            valueIsNumericString
                            thousandSeparator="."
                            decimalSeparator=","
                            value={invitationData.amountFinance}
                            decimalScale={0}
                            onValueChange={(value) => { setInvitationData({ ...invitationData, amountFinance: value.floatValue, amountFinanceF: value.formattedValue ?? "" }); setErrors(errors => { return { ...errors, amountFinance: false } }) }}
                            InputProps={{
                                startAdornment:
                                    <InputAdornment position="start">$</InputAdornment>,
                                endAdornment:
                                    <>  <Tooltip title="Recuerde que el Valor a financiar debe ser igual o menor al Valor del presupuesto" enterTouchDelay={0}><InputAdornment position="start">?</InputAdornment></Tooltip>
                                        <InputAdornment position="end" style={{ cursor: "pointer" }} onClick={() => { setInvitationData({ ...invitationData, amountFinance: "", amountFinanceF: "" }) }}>X</InputAdornment></>

                            }}
                            autoComplete="off"
                            error={errors && errors.amountFinance}
                            sx={{ width: "100%" }}
                        />





                    </Grid>
                    <Grid item xs={12} sm={6} md={6} xl={4}>
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">

                            <DatePicker label="Fecha de vencimiento" sx={{ width: "100%" }} format="DD-MM-YYYY"
                                disablePast
                                value={invitationData.expirationDateO}
                                onChange={(a) => { setInvitationData({ ...invitationData, expirationDateO: a, expirationDate: a.format("DD-MM-YYYY") }) }}
                                slotProps={{
                                    textField: {

                                        variant: 'outlined',
                                        error: errors && errors.expirationDate,

                                    },
                                }}
                                maxDate={dayjs().add(2, 'month')}
                            />

                        </LocalizationProvider>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>

                        <div style={{ display: "inline-flex" }}>
                            <Typography variant="body1" sx={{ mt: 2, color: "#6c7b88" }}>
                                Tratamientos

                            </Typography>
                            <ClickAwayListener onClickAway={() => { setOpenTooltip1(false) }}>
                                <Tooltip disableFocusListener
                                    disableHoverListener
                                    disableTouchListener
                                    PopperProps={{
                                        disablePortal: true,
                                    }} open={openTooltip1} onClose={() => { setOpenTooltip1(false) }} title="Estos son los tratamientos que estas autorizados a ofrecer. En caso de que vayas a ofrecer nuevos tratamientos que no aparecen en esta lista, por favor, comunícate con nosotros para que te los habilitemos" enterTouchDelay={0}>
                                    <Icon style={{ color: "gray", cursor: "pointer", marginTop: "15px", marginLeft: "5px" }} onClick={() => { setOpenTooltip1(true) }}>help_outlined</Icon>
                                </Tooltip>
                            </ClickAwayListener>
                        </div>



                        <ul style={{
                            display: 'flex',
                            justifyContent: 'center',
                            flexWrap: 'wrap',
                            listStyle: 'none',

                        }}>
                            {treatments && treatments.map((t) => {


                                return (
                                    <li key={t.IdTreatment} style={{ margin: "24px 4px 4px" }}>
                                        <Chip
                                            color={invitationData && invitationData.treatments && invitationData.treatments.find(tr => tr.IdTreatment === t.IdTreatment) ? "primary" : undefined}
                                            label={t.TreatmentName}
                                            onClick={handleAddChip(t)}
                                            onDelete={invitationData && invitationData.treatments && invitationData.treatments.find(tr => tr.IdTreatment === t.IdTreatment) ? handleDeleteChip(t) : undefined}

                                        />
                                    </li>
                                );
                            })}
                        </ul>

                    </Grid>



                    <Grid item xs={12} sm={12} md={12} style={{ marginTop: "12px" }}>


                        <div style={{ display: xsUp ? "inline-flex" : "unset" }}>
                            <Typography variant="h6">
                                ¿Paciente es el tomador del crédito?
                            </Typography>

                            <FormControl sx={{ marginLeft: "15px", marginTop: "-5px" }}>
                                {/* <FormLabel id="demo-controlled-radio-buttons-group">Gender</FormLabel> */}
                                <RadioGroup row
                                    aria-labelledby="demo-controlled-radio-buttons-group"
                                    name="controlled-radio-buttons-group"
                                    value={invitationData.customerIsPatient}
                                    onChange={handleChangeSwitchpatient}
                                >
                                    <FormControlLabel value="SI" control={<Radio style={{ color: errors && errors.customerIsPatient ? "red" : "#637381" }} />} label="SI" />
                                    <FormControlLabel value="NO" control={<Radio style={{ color: errors && errors.customerIsPatient ? "red" : "#637381" }} />} label="NO" />
                                </RadioGroup>
                            </FormControl>

                        </div>


                    </Grid>


                    <Grid item xs={12} sm={12} md={12}>

                        <div style={{ display: "inline-flex" }}>
                            <Typography variant="h6">
                                Otros pacientes en el crédito
                            </Typography>


                            <Icon style={{ cursor: "pointer", marginLeft: "5px" }} onClick={addpatient}>add_circle_outline</Icon>

                        </div>
                    </Grid>

                    {invitationData.patients && invitationData.patients.map((ip, index) => {
                        return <React.Fragment key={ip.id}>

                            <Grid item xs={12} sm={12} md={12} xl={12} style={{ paddingTop: "0px" }}>
                                <div style={{ display: "inline-flex" }}>
                                    <Typography variant="body1" sx={{ mt: 2, color: "#6c7b88" }}>
                                        Paciente {index + 1}

                                    </Typography>
                                    <Icon style={{ cursor: "pointer", marginLeft: "5px", marginTop: "15px", color: "#6c7b88" }} onClick={() => removepatient(ip)}>remove_circle_outline</Icon>

                                </div>
                            </Grid>

                            <Grid item xs={12} sm={4} md={4} xl={4}>
                                <FormControl sx={{ width: "100%" }}>

                                    <InputLabel htmlFor="document-type-patient">Tipo de documento</InputLabel>
                                    <Select
                                        label="Tipo de documento"
                                        id="document-type-patient"
                                        sx={{ width: "100%" }}
                                        value={ip.documentType}
                                        onChange={
                                            (event) => {
                                                const patients = invitationData.patients.map(tempP => { if (tempP.id === ip.id) { return { ...tempP, documentType: event.target.value } } return tempP });
                                                setInvitationData({ ...invitationData, patients })
                                            }
                                        }
                                    // error={errors && errors.documentType}
                                    >
                                        {documentTypespatient.map(d => {
                                            return <MenuItem key={d.value} value={d.value}>{d.label}</MenuItem>
                                        })}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={4} md={4} xl={4}>
                                <TextField id="DocumentoP" label="Documento" variant="outlined" sx={{ width: "100%" }} value={ip.document}
                                    //  onChange={(event) => { setInvitationData({ ...invitationData, document: event.target.value }); setErrors(errors => { return { ...errors, document: false } }) }}
                                    onChange={
                                        (event) => {
                                            const patients = invitationData.patients.map(tempP => { if (tempP.id === ip.id) { return { ...tempP, document: event.target.value } } return tempP });
                                            setInvitationData({ ...invitationData, patients });
                                            setpatientsErrors(patientsErrors.map((el) => { if (el.id !== ip.id) { return el } return { ...el, document: false } }));
                                        }
                                    }
                                    type="number"
                                    autoComplete="off"
                                    error={patientsErrors && patientsErrors.find(el => el.id === ip.id) && patientsErrors.find(el => el.id === ip.id).document}
                                />
                            </Grid>

                            <Grid item xs={12} sm={4} md={4} xl={4}>
                                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">

                                    <DatePicker label="Fecha de nacimiento" sx={{ width: "100%" }} format="DD-MM-YYYY"

                                        value={ip.DOBo}
                                        // onChange={(a) => { setInvitationData({ ...invitationData, expirationDateO: a, expirationDate: a.format("DD-MM-YYYY") }) }}

                                        onChange={
                                            (a) => {
                                                const patients = invitationData.patients.map(tempP => { if (tempP.id === ip.id) { return { ...tempP, DOBo: a, DOB: a.format("DD-MM-YYYY") } } return tempP });
                                                setInvitationData({ ...invitationData, patients });
                                                setpatientsErrors(patientsErrors.map((el) => { if (el.id !== ip.id) { return el } return { ...el, DOB: false } }));
                                            }
                                        }

                                        slotProps={{
                                            textField: {

                                                variant: 'outlined',
                                                error: patientsErrors && patientsErrors.find(el => el.id === ip.id) && patientsErrors.find(el => el.id === ip.id).DOB

                                            },
                                        }}
                                        maxDate={dayjs()}
                                    />

                                </LocalizationProvider>
                            </Grid>



                            <Grid item xs={12} sm={3} md={3} xl={3}>
                                <TextField id="firstName" label="Primer nombre" variant="outlined" sx={{ width: "100%" }} value={ip.firstName}
                                    //  onChange={(event) => { setInvitationData({ ...invitationData, document: event.target.value }); setErrors(errors => { return { ...errors, document: false } }) }}
                                    onChange={
                                        (event) => {
                                            const patients = invitationData.patients.map(tempP => { if (tempP.id === ip.id) { return { ...tempP, firstName: event.target.value } } return tempP });
                                            setInvitationData({ ...invitationData, patients });
                                            setpatientsErrors(patientsErrors.map((el) => { if (el.id !== ip.id) { return el } return { ...el, firstName: false } }));
                                        }
                                    }
                                    type="text"
                                    autoComplete="off"
                                    error={patientsErrors && patientsErrors.find(el => el.id === ip.id) && patientsErrors.find(el => el.id === ip.id).firstName}
                                />
                            </Grid>

                            <Grid item xs={12} sm={3} md={3} xl={3}>
                                <TextField id="secondName" label="Segundo nombre" variant="outlined" sx={{ width: "100%" }} value={ip.secondName}
                                    //  onChange={(event) => { setInvitationData({ ...invitationData, document: event.target.value }); setErrors(errors => { return { ...errors, document: false } }) }}
                                    onChange={
                                        (event) => {
                                            const patients = invitationData.patients.map(tempP => { if (tempP.id === ip.id) { return { ...tempP, secondName: event.target.value } } return tempP });
                                            setInvitationData({ ...invitationData, patients });
                                            setpatientsErrors(patientsErrors.map((el) => { if (el.id !== ip.id) { return el } return { ...el, secondName: false } }));
                                        }
                                    }
                                    type="text"
                                    autoComplete="off"
                                    error={patientsErrors && patientsErrors.find(el => el.id === ip.id) && patientsErrors.find(el => el.id === ip.id).secondName}
                                />
                            </Grid>

                            <Grid item xs={12} sm={3} md={3} xl={3}>
                                <TextField id="firstLastName" label="Primer apellido" variant="outlined" sx={{ width: "100%" }} value={ip.firstLastName}
                                    //  onChange={(event) => { setInvitationData({ ...invitationData, document: event.target.value }); setErrors(errors => { return { ...errors, document: false } }) }}
                                    onChange={
                                        (event) => {
                                            const patients = invitationData.patients.map(tempP => { if (tempP.id === ip.id) { return { ...tempP, firstLastName: event.target.value } } return tempP });
                                            setInvitationData({ ...invitationData, patients });
                                            setpatientsErrors(patientsErrors.map((el) => { if (el.id !== ip.id) { return el } return { ...el, firstLastName: false } }));
                                        }
                                    }
                                    type="text"
                                    autoComplete="off"
                                    error={patientsErrors && patientsErrors.find(el => el.id === ip.id) && patientsErrors.find(el => el.id === ip.id).firstLastName}
                                />
                            </Grid>

                            <Grid item xs={12} sm={3} md={3} xl={3}>
                                <TextField id="secondLastName" label="Segundo apellido" variant="outlined" sx={{ width: "100%" }} value={ip.secondLastName}
                                    //  onChange={(event) => { setInvitationData({ ...invitationData, document: event.target.value }); setErrors(errors => { return { ...errors, document: false } }) }}
                                    onChange={
                                        (event) => {
                                            const patients = invitationData.patients.map(tempP => { if (tempP.id === ip.id) { return { ...tempP, secondLastName: event.target.value } } return tempP });
                                            setInvitationData({ ...invitationData, patients });
                                            setpatientsErrors(patientsErrors.map((el) => { if (el.id !== ip.id) { return el } return { ...el, secondLastName: false } }));
                                        }
                                    }
                                    type="text"
                                    autoComplete="off"
                                    error={patientsErrors && patientsErrors.find(el => el.id === ip.id) && patientsErrors.find(el => el.id === ip.id).secondLastName}
                                />
                            </Grid>




                            <Grid item xs={12} sm={4} md={4} xl={4}>



                                <FormControl sx={{ width: "100%" }}>

                                    <InputLabel htmlFor="relation">Relación tomador del credito</InputLabel>
                                    <Select
                                        label="Relación tomador del credito"
                                        id="relation"
                                        sx={{ width: "100%" }}
                                        value={ip.patientRelationship}
                                        onChange={
                                            (event) => {
                                                const patients = invitationData.patients.map(tempP => { if (tempP.id === ip.id) { return { ...tempP, patientRelationship: event.target.value } } return tempP });
                                                setInvitationData({ ...invitationData, patients });
                                                setpatientsErrors(patientsErrors.map((el) => { if (el.id !== ip.id) { return el } return { ...el, patientRelationship: false } }));
                                            }
                                        }
                                        error={patientsErrors && patientsErrors.find(el => el.id === ip.id) && patientsErrors.find(el => el.id === ip.id).patientRelationship}
                                    >
                                        {patientRelations.map(d => {
                                            return <MenuItem key={d.value} value={d.value}>{d.label}</MenuItem>
                                        })}
                                    </Select>
                                </FormControl>
                            </Grid>


                            <Grid item xs={12} sm={4} md={4} xl={4}>
                                <Autocomplete
                                    id="country-select-demo"
                                    sx={{ width: "100%" }}
                                    options={countries}
                                    autoHighlight
                                    isOptionEqualToValue={(option, value) => option.value === value.value}
                                    value={ip.phoneCountry}
                                    disableClearable
                                    // onChange={(event, value) => { console.log(value) }}
                                    onChange={
                                        (event, value) => {
                                            const patients = invitationData.patients.map(tempP => { if (tempP.id === ip.id) { return { ...tempP, phoneCountry: value } } return tempP });
                                            setInvitationData({ ...invitationData, patients })
                                        }
                                    }
                                    getOptionLabel={(option) => option.label}
                                    renderOption={(props, option) => (
                                        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                            <img
                                                loading="lazy"
                                                width="20"
                                                src={`https://flagcdn.com/w20/${option.value.toLowerCase()}.png`}
                                                srcSet={`https://flagcdn.com/w40/${option.value.toLowerCase()}.png 2x`}
                                                alt=""
                                            />
                                            {option.label} +{option.Phonecode}
                                        </Box>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}

                                            label="Código pais celular"
                                            inputProps={{
                                                ...params.inputProps,
                                                autoComplete: 'new-password', // disable autocomplete and autofill
                                            }}
                                        />
                                    )}
                                />
                            </Grid>

                            <Grid item xs={12} sm={4} md={4} xl={4}>
                                <TextField id="CelularP" label="Celular" variant="outlined" sx={{ width: "100%" }} value={ip.phone}
                                    // onChange={(event) => { setInvitationData({ ...invitationData, phone: event.target.value }); setErrors(errors => { return { ...errors, phone: false } }) }}
                                    onChange={
                                        (event) => {
                                            const patients = invitationData.patients.map(tempP => { if (tempP.id === ip.id) { return { ...tempP, phone: event.target.value } } return tempP });
                                            setInvitationData({ ...invitationData, patients });
                                            setpatientsErrors(patientsErrors.map((el) => { if (el.id !== ip.id) { return el } return { ...el, phone: false } }));
                                        }
                                    }
                                    type="number"
                                    autoComplete="off"
                                    InputProps={{

                                        endAdornment:
                                            <>
                                                <Tooltip title="Si paciente no dispone de numero celular, se deberá colocar un numero de contacto alternativo valido que pueda atender posibles comunicaciones" enterTouchDelay={0}><InputAdornment position="start">?</InputAdornment></Tooltip>
                                            </>

                                    }}
                                    error={patientsErrors && patientsErrors.find(el => el.id === ip.id) && patientsErrors.find(el => el.id === ip.id).phone}
                                />
                            </Grid>



                        </React.Fragment>
                    })}


                    <Grid item xs={12} sm={12} md={12} xl={12} style={{ marginTop: "12px" }}>
                        <div style={{ display: "inline-flex" }}>
                            <Typography variant="h6">
                                Notificar cliente
                            </Typography>
                            <FormControlLabel sx={{ marginTop: "-5px", marginLeft: "0px" }} control={<Switch checked={notifyClient} disabled={isUpdate}
                                onChange={handleChangeSwitch} />} />
                        </div>
                    </Grid>

                    {notifyClient && <Grid item xs={12} sm={6} md={6} xl={6}>
                        <FormControl sx={{ width: "100%" }} disabled={isUpdate}>
                            <InputLabel htmlFor="notification-type">Medio de comunicación</InputLabel>
                            <Select
                                label="Medio de comunicación"
                                id="notification-type"
                                sx={{ width: "100%" }}
                                value={invitationData.notificationType}
                                onChange={(event) => { setInvitationData({ ...invitationData, notificationType: event.target.value }); setErrors(errors => { return { ...errors, notificationType: false } }) }}
                                error={errors && errors.notificationType}
                            >
                                {notificationTypes.map(d => {
                                    return <MenuItem key={d.value} value={d.value}>{d.label}</MenuItem>
                                })}
                            </Select>
                        </FormControl>
                    </Grid>}

                    {notifyClient && invitationData.notificationType === "EMA" && <Grid item xs={12} sm={6} md={6} xl={6}>
                        <TextField disabled={isUpdate} id="Correo" label="Correo electrónico" variant="outlined" sx={{ width: "100%" }} value={invitationData.email} onChange={(event) => { setInvitationData({ ...invitationData, email: event.target.value }); setErrors(errors => { return { ...errors, email: false } }) }}
                            autoComplete="off"
                            error={errors && errors.email} />
                    </Grid>}

                    {notifyClient && invitationData.notificationType === "SMS" && <Grid item xs={12} sm={6} md={6} xl={6}>
                        <TextField disabled={isUpdate} id="Celular" label="Celular" variant="outlined" sx={{ width: "100%" }} value={invitationData.phone} onChange={(event) => { setInvitationData({ ...invitationData, phone: event.target.value }); setErrors(errors => { return { ...errors, phone: false } }) }}
                            type="number"
                            autoComplete="off"
                            error={errors && errors.phone}
                        />
                    </Grid>}

                    <Grid item xs={12} sm={12} md={12}><br /></Grid>
                    <Grid item xs={12} sm={12} md={12} xl={12} container justifyContent="flex-end">
                        <LoadingButton disabled={sending} color="success" variant="contained" onClick={() => saveInvitation(1)} loading={loadingButton}>
                            {isUpdate ? "Actualizar" : "Crear"}
                        </LoadingButton>
                    </Grid>





                </Grid>



            </Box>
        </Modal>
    </>
}