import { useEffect, useState } from 'react';
// @mui
import PropTypes from 'prop-types';
import { alpha, styled } from '@mui/material/styles';
import { Card, Skeleton, Typography } from '@mui/material';
// utils
import { fShortenNumber } from '../../../utils/formatNumber';
// components
import Iconify from '../../../components/iconify';
import TarsService from '../../../services/TarsService';


// ----------------------------------------------------------------------

const StyledIcon = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: 'center',
  marginBottom: theme.spacing(3),
}));

// ----------------------------------------------------------------------

AppWidgetSummary.propTypes = {
  // color: PropTypes.string,
  // icon: PropTypes.string,
  // title: PropTypes.string.isRequired,
  // total: PropTypes.number.isRequired,
  sx: PropTypes.object,
};

export default function AppWidgetSummary({ IdGraphic, sx, ...other }) {

  const { GraphicsData } = TarsService();
  const [GraphicData, setGraphicData] = useState(null);

  useEffect(() => {
    GraphicsData(IdGraphic).then(res => {
      setGraphicData(JSON.parse(res.data.data))
    })
  }, []);



  return GraphicData ? (<Card
    sx={{
      py: 2,
      boxShadow: 0,
      textAlign: 'center',
      color: GraphicData.Color,
      bgcolor: GraphicData.BgColor,
      ...sx,
    }}
    {...other}
  >

    <StyledIcon
      sx={{
        color: GraphicData.IconColor,
        backgroundImage: () =>
          `linear-gradient(135deg, ${alpha(GraphicData.IconColor, 0)} 0%, ${alpha(
            GraphicData.IconColor,
            0.24
          )} 100%)`,
        height: 35,
        width: 35,
        marginBottom: 1
      }}
    >
      <Iconify icon={GraphicData.Icon} width={20} height={20} />
    </StyledIcon>

    <Typography variant="h4">{GraphicData.Total}</Typography>

    <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
      {GraphicData.Title}
    </Typography>
  </Card>) : <Card><Skeleton variant="rounded" width="100%" height={130} /></Card> 



}
