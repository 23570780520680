import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
// @mui
import { styled, useTheme } from '@mui/material/styles';
import { Link, Container, Typography, Divider, Stack, Button, TextField, InputAdornment, IconButton, LinearProgress } from '@mui/material';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content'
// hooks
import { useNavigate, useSearchParams } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import ChangePasswordForm from '../sections/auth/login/ChangePasswordForm';
import useResponsive from '../hooks/useResponsive';
// components
import Logo from '../components/logo';
import Iconify from '../components/iconify';
import { VerifyTokenChangePassword } from '../services/SoraService';

// sections




// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        display: 'flex',
    },
}));

const StyledSection = styled('div')(({ theme }) => ({
    width: '100%',

    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    boxShadow: theme.customShadows.card,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white
}));



const Prueba = () => {

    const [showPasswordA, setShowPasswordA] = useState(false);


    const [passwordA, setPasswordA] = useState(null);

    return <TextField
        name="passwordA"
        type={showPasswordA ? 'text' : 'password'}
        label="Nueva Contraseña2sa passwordA"
        InputProps={{
            endAdornment: (
                <InputAdornment position="end">
                    <IconButton onClick={() => setShowPasswordA(!showPasswordA)} edge="end">
                        <Iconify icon={showPasswordA ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                    </IconButton>
                </InputAdornment>
            ),
        }}

    />
}

// ----------------------------------------------------------------------

export default function ResponseChangePassword() {
    const xsUp = useResponsive('up', 'xs');
    const xlUp = useResponsive('up', 'xl');
    const navigate = useNavigate();
    const [passwordChanged, setPasswordChanged] = useState(false);
    const [validToken, setValidToken] = useState(true);
    const MySwal = withReactContent(Swal)
    const theme = useTheme();
    const [searchParams] = useSearchParams();
    const [loadingPage, setLoadingPage] = useState(true);
    const [userEmail, setUserEmail] = useState("");
    const token = searchParams.get("token");

    const StyledContent = styled('div')(({ theme }) => ({
        maxWidth: 480,
        margin: 'auto',
        minHeight: "100vh",

        justifyContent: 'center',
        flexDirection: 'column',
        padding: xlUp ? theme.spacing(12, 0) : theme.spacing(5, 0),
    }));

    useEffect(() => {
        setLoadingPage(true);


        if (token) {

            VerifyTokenChangePassword(token).then(res => {
                setLoadingPage(false);
                if (res.status === 200) {
                    setValidToken(true)
                    const data = JSON.parse(res.data.data)
                    setUserEmail(data.UserName)
                }
                else {
                    setValidToken(false)
                    MySwal.fire({
                        html: res.data.message,
                        icon: 'error',
                        confirmButtonColor: theme.palette.primary.main
                    })
                }
            })

        }
        else {
            setLoadingPage(false);
            setValidToken(false)
        }


    }, []);



    return (
        <>
            <Helmet>
                <title> Cambiar contraseña | Aliados Meddipay </title>
            </Helmet>

            <StyledRoot>

                <StyledSection>
                    <Logo
                        sx={{


                            width: { xs: "70%", sm: "70%", md: "70%", xl: "100%" },

                        }}
                        src="/assets/images/logo1.png"
                    />

                    <Typography variant="h3" sx={{ px: 5, mt: 0, mb: 5, alignSelf: "center" }}>
                        Tu aliado perfecto
                    </Typography>
                    {/* <img src="/assets/illustrations/illustration_login.png" alt="login" /> */}
                </StyledSection>


                {!passwordChanged && validToken && !loadingPage && (<Container maxWidth="sm" >
                    <StyledContent>
                        <Typography variant="h2" gutterBottom style={{ color: "#00a9eb" }}>
                            Nueva Contraseña
                        </Typography>

                        <Typography variant="body1" sx={{ mb: 5 }}>
                            A continuación va a proceder a cambiar la contraseña asociada al usuario <span style={{ fontWeight: "bold" }}>{userEmail}</span>
                        </Typography>

                        <ChangePasswordForm token={token} setPasswordChanged={setPasswordChanged} />

                    </StyledContent>
                </Container>)}

                {passwordChanged && validToken && !loadingPage && (<Container maxWidth="sm" >
                    <StyledContent style={{ paddingTop: "50px" }}>
                        <Typography variant="h2" gutterBottom style={{ color: "#00a9eb" }}>
                            ¡Contraseña restablecida!
                        </Typography>



                        <div>
                            <img src='/assets/images/check.svg' alt='check' style={{ margin: "50px auto" }} />
                        </div>


                        <Typography variant="body2" sx={{ mb: 5, mt: 5 }}>
                            La contraseña del correo electrónico {userEmail} ha sido restablecida exitosamente, puedes proceder con el inicio de sesión con la nueva contraseña.
                        </Typography>

                        <Stack spacing={3} sx={{ my: 2 }}>



                            <LoadingButton color="success" fullWidth size="large" type="button" variant="contained" onClick={() => { navigate("/") }} >
                                Iniciar Sesión
                            </LoadingButton>

                        </Stack>


                    </StyledContent>
                </Container>)}



                {!validToken && !loadingPage && (<Container maxWidth="sm" >
                    <StyledContent>
                        <Typography variant="h2" gutterBottom style={{ color: "#00a9eb" }}>
                            Ups...<br />
                            No tienes acceso a esta pagina
                        </Typography>
                    </StyledContent>
                </Container>)}

                {loadingPage && (<Container maxWidth="sm" >
                    <StyledContent>
                        <LinearProgress />
                    </StyledContent>
                </Container>)}

            </StyledRoot>
        </>
    );
}
