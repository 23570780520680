import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { alpha } from '@mui/material/styles';
import { Box, Divider, Typography, Stack, MenuItem, IconButton, Popover, Tooltip, Icon, Snackbar, Alert, CircularProgress } from '@mui/material';
import AvatarM from '@mui/material/Avatar';
// mocks_
import Avatar from 'avataaars'
import account from '../../../_mock/account';
import TarsService from '../../../services/TarsService'
import useResponsive from '../../../hooks/useResponsive';
import { useGlobalProvider } from '../../../components/GlobalProvider'
import Iconify from '../../../components/iconify'
import ProfileModal from '../../../pages/ProfileModal'
import PosDataModal from '../../../pages/PosDataModal'
import ResetPasswordModal from '../../../pages/ResetPasswordModal'
import Logo from '../../../components/logo';
// ----------------------------------------------------------------------



// ----------------------------------------------------------------------

export default function AccountPopover() {
  const [open, setOpen] = useState(null);
  const navigate = useNavigate();
  // const [posData, setPosData] = useState(null);
  const xsUp = useResponsive('up', 'md');
  const { posData, avatarData, setAvatarData, setPosData } = useGlobalProvider();
  const { SaveMainPosIconAgora, GetPosById } = TarsService();
  const [openProfileModal, setOpenProfileModal] = useState(false);
  const [openPosDataModal, setOpenPosDataModal] = useState(false);
  const [openResetPasswordModal, setOpenResetPasswordModal] = useState(false);
  const [snackbarData, setSnackbarData] = useState({ message: "msg", severity: "warning" });
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [sending, setSending] = useState(false);

  useEffect(() => {

    const avatar = JSON.parse(localStorage.getItem("avatar"))
    const firstLogin = JSON.parse(localStorage.getItem("firstLogin"))

    if (avatar) {
      setAvatarData(avatar);
    }

    if (firstLogin) {
      setOpenProfileModal(true);
    }

  }, []);


  const getPosData = () => {

    GetPosById().then(res => {
      const data = JSON.parse(res.data.data)[0]
      setPosData(data);
    })
  }



  const calcColor = (color) => {

    color = +(`0x${color.slice(1).replace(color.length < 5 && /./g, '$&$&')}`);

    const r = color > 16;
    const g = color > 8 && 255;
    const b = color && 255;

    const hsp = Math.sqrt(
      0.299 * (r * r) +
      0.587 * (g * g) +
      0.114 * (b * b)
    );

    if (hsp > 127.5) {

      return 'white';
    }
    return 'black';
  }

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const logout = () => {
    setOpen(null);
    localStorage.removeItem("token");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("expiredTime");
    localStorage.removeItem("userName");
    localStorage.removeItem("remember");
    localStorage.removeItem("fullName");
    localStorage.removeItem("email");
    localStorage.removeItem("phone");
    localStorage.removeItem("avatar");
    localStorage.removeItem("firstLogin");
    navigate('/', { replace: true });
  };

  const handleCloseSnackbar = (event, reason) => {
    setOpenSnackbar(false)
  }

  const saveIcon = (event) => {
    setSending(true)
    setSelectedImage(event.target.files[0]);

    let image1 = null;

    if (typeof event.target.files[0] === "object") {
      image1 = event.target.files[0];

      const bodyFormData1 = new FormData();
      bodyFormData1.append('File', image1);

      SaveMainPosIconAgora(bodyFormData1).then(res => {
        setSending(false)

        if (res.status === 200) {
          setOpenSnackbar(true)
          setPosData({ ...posData, IconURL: JSON.parse(res.data.data).IconUrl })
          setSnackbarData({ message: "OK", severity: "success" })
        }
        else {
          setOpenSnackbar(true)
          setSnackbarData({ message: "Error", severity: "warning" })
        }
      })
    }



  }

  const getSVG = (htmlString, color) => {
    const parser = new DOMParser();
    const html = parser.parseFromString(htmlString, 'text/html');
    const svg = html.body.getElementsByTagName("svg")[0];

    svg.setAttribute("width", "50px")
    svg.setAttribute("height", "50px")
    svg.setAttribute("fill", color)

    const serializedXml = new XMLSerializer().serializeToString(svg);
    return serializedXml;

  }


  // const MENU_OPTIONS = [

  //   {
  //     label: 'Mi perfil',
  //     icon: 'eva:person-fill',
  //     action: handleClose()
  //   }
  // ];

  return (
    <>

      <ProfileModal openModal={openProfileModal} setOpenModal={setOpenProfileModal} />
      <PosDataModal openModal={openPosDataModal} setOpenModal={setOpenPosDataModal} />
      <ResetPasswordModal openModal={openResetPasswordModal} setOpenModal={setOpenResetPasswordModal} />

      <IconButton
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              // bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >

        {/* {
          posData &&
          (
            !posData.IconURL && posData.Icon2Letters && posData.posMainColor ?
              <div style={{ backgroundColor: posData.posMainColor, width: xsUp ? "80px" : "60px", height: xsUp ? "80px" : "60px", borderRadius: "50%", textAlign: "center", paddingTop: "16px", paddingBottom: "16px" }}>
                <div style={{ fontSize: "40px", color: calcColor(posData.posMainColor) }}> {posData.Icon2Letters}</div>
              </div>
              // : <Avatar src={posData.IconURL} alt="photoURL" sx={{ width: xsUp ? 80 : 60, height: xsUp ? 80 : 60 }} />
              : <Avatar
                style={{ width: xsUp ? 80 : 60, height: xsUp ? 80 : 60 }}
                avatarStyle='Circle'
                topType={avatarData.topType}
                accessoriesType={avatarData.accessoriesType}
                hairColor={avatarData.hairColor}
                facialHairType={avatarData.facialHairType}
                facialHairColor={avatarData.facialHairColor}
                clotheType={avatarData.clotheType}
                clotheColor={avatarData.clotheColor}
                eyeType={avatarData.eyeType}
                eyebrowType={avatarData.eyebrowType}
                mouthType={avatarData.mouthType}
                skinColor={avatarData.skinColor}
                graphicType={avatarData.graphicType}
              />

          )} */}

        {avatarData.topType ? <Avatar
          style={{ width: xsUp ? 80 : 60, height: xsUp ? 80 : 60 }}
          avatarStyle='Circle'
          topType={avatarData.topType}
          hatColor={avatarData.hatColor}
          accessoriesType={avatarData.accessoriesType}
          hairColor={avatarData.hairColor}
          facialHairType={avatarData.facialHairType}
          facialHairColor={avatarData.facialHairColor}
          clotheType={avatarData.clotheType}
          clotheColor={avatarData.clotheColor}
          eyeType={avatarData.eyeType}
          eyebrowType={avatarData.eyebrowType}
          mouthType={avatarData.mouthType}
          skinColor={avatarData.skinColor}
          graphicType={avatarData.graphicType}
        /> : <AvatarM alt="profileA" />}

      </IconButton>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 0,
            mt: 1.5,
            ml: 0.75,
            width: 300,
            '& .MuiMenuItem-root': {
              typography: 'body2',
              borderRadius: 0.75,
            },
            overflowY: "auto"
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {localStorage.getItem("fullName")}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>

            <Tooltip title={localStorage.getItem("userName")}>



              <span>
                {localStorage.getItem("userName")}
                {/* {posData ? posData.posCommercialName && posData.posCommercialName.length > 20 ? `${posData.posCommercialName.slice(0, 20)}...` : (posData.posCommercialName ?? "---") : ""} */}

              </span>


            </Tooltip>


          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack sx={{ p: 1 }}>
          {/* {MENU_OPTIONS.map((option) => (
            <MenuItem key={option.label} onClick={option.action}>
              {option.label}
            </MenuItem>
          ))} */}
          {posData && <MenuItem key="Mi perfil" onClick={() => { setOpenProfileModal(true); handleClose(); }}>
            <Icon>{"person_outline"}</Icon> Mi Perfil
          </MenuItem>}

          {posData && <MenuItem key="Datos sede" onClick={() => { getPosData(); setOpenPosDataModal(true); handleClose(); }}>
            <Icon>{"store"}</Icon>Datos Sede
          </MenuItem>}

          <MenuItem key="Cambiar Contraseña" onClick={() => { setOpenResetPasswordModal(true); handleClose(); }}>
            <Icon>{"key"}</Icon>Cambiar Contraseña
          </MenuItem>

        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack sx={{ p: 1 }}>



          <div style={{ textAlign: "center", fontSize: "12px", width: "100%" }}>

            {posData && posData.IconURL && <img src={selectedImage ? URL.createObjectURL(selectedImage) : (posData ? posData.IconURL : "")} alt="logo" style={{ height: "150px", margin: "0 auto" }} />}

            {posData && !posData.IconURL && <div style={{ padding: "5px" }} dangerouslySetInnerHTML={{ __html: posData ? getSVG(posData.SectorIcon, posData.posMainColor) : <></> }} />}


            <label htmlFor="upload">
              {sending ? <CircularProgress style={{ width: "20px", height: "20px" }} /> : <u style={{ cursor: "pointer", color: "#00a9eb" }}>Editar </u>}
              <input type="file" id="upload" multiple={false} style={{ display: "none" }} accept="image/*" onChange={(event) => saveIcon(event)} />
            </label>



          </div>

          <div style={{ textAlign: "center" }}>
            {posData ? posData.posCommercialName : ""}
          </div>
        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem onClick={logout} sx={{ m: 1 }}>
          <Icon>{"logout"}</Icon>  Salir
        </MenuItem>
      </Popover>


      <Snackbar open={openSnackbar} autoHideDuration={5000} onClose={handleCloseSnackbar} anchorOrigin={{ horizontal: "center", vertical: "bottom" }}>
        <Alert onClose={handleCloseSnackbar} severity={snackbarData.severity} sx={{ width: '100%' }}>
          {snackbarData.message}
        </Alert>
      </Snackbar>
    </>
  );
}
