import { createContext, useContext, useState } from 'react';
import { getEnumsCustomer } from '../services/CacheServices';
import { getCountries } from '../services/CountriesServices';

const CreditRequestContext = createContext();

export const CreditRequestProvider = ({ children }) => {

    const [enumsCustomer, setEnumsCustomer] = useState({});
    const [enumsCountries, setEnumsCountries] = useState([]);
    const [enumsPosData, setEnumsPosData] = useState([]);
    const [logoSize, setLogoSize] = useState({ width: "320px", marginTop: "40px", marginBottom: "30px" });
    const [resendTimeSMS, setResendTimeSMS] = useState(null);
    const [resendTimeEmail, setResendTimeEmail] = useState(null);
    const [resendTimeWA, setResendTimeWA] = useState(null);

    const getEC = () => {

        return new Promise((resolve, reject) => {
            if (Object.keys(enumsCustomer).length !== 0) {
                resolve(enumsCustomer)
            }
            else {
                getEnumsCustomer().then(result => {
                    setEnumsCustomer(result)
                    resolve(result);
                });
            }


        });
    }

    const getCT = () => {
        return new Promise((resolve, reject) => {

            if (Object.keys(enumsCountries).length !== 0) {
                resolve(enumsCountries)
            }
            else {
                getCountries().then(result => {
                    setEnumsCountries(result)
                    resolve(result);
                });
            }


        });
    }


    const value = {
        enumsPosData,
        setEnumsPosData,
        logoSize,
        setLogoSize,
        resendTimeSMS,
        setResendTimeSMS,
        resendTimeEmail,
        setResendTimeEmail,
        resendTimeWA,
        setResendTimeWA,
        getEC,
        getCT,
    }

    return (
        <CreditRequestContext.Provider value={value}>
            {children}
        </CreditRequestContext.Provider>
    )
};

export const useCreditRequest = () => {
    return useContext(CreditRequestContext)
};