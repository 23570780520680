import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import 'dayjs/locale/es';
import {
    Card,
    Table,
    Stack,
    Paper,
    Button,
    Popover,
    TableRow,
    MenuItem,
    TableBody,
    TableCell,
    Container,
    Typography,
    IconButton,
    Switch,
    TablePagination,
    Snackbar,
    Alert,
    CircularProgress,
    Dialog,
    DialogTitle,
    DialogContent,
    FormControlLabel,
    Chip,
    Select,
    Modal,
    Box,
    Grid,
    FormControl,
    InputLabel,
    TextField,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Brightness1Icon from '@mui/icons-material/Brightness1';
// import { exportToExcel } from 'react-json-to-excel';

import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

import Label from '../components/label';
import Iconify from '../components/iconify';
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';
import TarsService from "../services/TarsService"
import NewInvitation from './NewInvitation';
import useResponsive from '../hooks/useResponsive';
import BellService from "../services/BellService"
import { useGlobalProvider } from '../components/GlobalProvider'

export default function Reports() {

    const mdUp = useResponsive('up', 'md');
    const [reportRequest, setReportRequest] = useState({
        reportType: "",
        datesData: {
            date1: null,
            date1O: null,
            date2: dayjs().format("YYYY-MM-DD"),
            date2O: dayjs()
        },
        pos: []
    });
    const [pos, setPos] = useState();
    const [errors, setErrors] = useState({});
    const { posData, sending, setSending } = useGlobalProvider();
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarData, setSnackbarData] = useState({ message: "msg", severity: "warning" });
    const [allAdded, setAllAdded] = useState(false);
    const { GetReportData } = TarsService();


    const exportToExcel = (data, name) => {
        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

        // Buffer to store the generated Excel file
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });

        saveAs(blob, name);
    };

    useEffect(() => {




    }, []);

    useEffect(() => {
        // console.log(posData)
        if (posData)
            setPos(posData.posPointsOfSellByUser)

    }, [posData]);

    const queryReport = () => {

        setSending(true);
        setErrors({})

        let errorsB = false;

        if (!reportRequest.reportType) {
            setOpenSnackbar(true)
            setErrors(errors => { return { ...errors, reportType: true } })
            setSnackbarData({ message: "El campo Reporte es obligatorio", severity: "warning" })
            errorsB = true;
        }

        if (!reportRequest.datesData.date1) {
            setOpenSnackbar(true)
            setErrors(errors => { return { ...errors, date1: true } })
            setSnackbarData({ message: "El campo Fecha Desde es obligatorio", severity: "warning" })
            errorsB = true;
        }

        if (!reportRequest.datesData.date2) {
            setOpenSnackbar(true)
            setErrors(errors => { return { ...errors, date2: true } })
            setSnackbarData({ message: "El campo Fecha Hasta es obligatorio", severity: "warning" })
            errorsB = true;
        }

        if (reportRequest.datesData.date1O > reportRequest.datesData.date2O) {
            setOpenSnackbar(true)
            setErrors(errors => { return { ...errors, date2: true, date1: true } })
            setSnackbarData({ message: "La Fecha Hasta no puede ser menor a la Fecha Desde", severity: "warning" })
            errorsB = true;
        }

        if (reportRequest.pos.length === 0) {
            setOpenSnackbar(true)
            // setErrors(errors => { return { ...errors, date2: true } })
            setSnackbarData({ message: "El campo Sedes es obligatorio", severity: "warning" })
            errorsB = true;
        }



        if (errorsB) { setSending(false); return };


        const body = {
            idReport: reportRequest.reportType,
            userName: localStorage.getItem("userName"),
            dayFrom: reportRequest.datesData.date1,
            dayTo: reportRequest.datesData.date2,
            posData: JSON.stringify({ posList: reportRequest.pos.map(p => p.IdPos) })
        }



        GetReportData(body).then(res => {

            if (res.status === 200) {
                const data = JSON.parse(res.data.data)
                if (data && data.length > 0)
                    exportToExcel(data, `${posData.posReportsUser.find(r => r.Id === reportRequest.reportType).ReportName}_${reportRequest.datesData.date1}_${reportRequest.datesData.date2}`)
                else {
                    setOpenSnackbar(true)
                    setSnackbarData({ message: "No existe información en el rango de fechas y/o sedes seleccionadas", severity: "warning" })
                }
                setSending(false);
            }
            else {
                setOpenSnackbar(true)
                setSnackbarData({ message: res.data.message, severity: "error" })
                setSending(false);
            }
        })
    }

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenSnackbar(false);
    };


    const handleAddChip = (chipToAdd) => () => {

        const tr = reportRequest.pos.find(t => t.IdPos === chipToAdd.IdPos)

        if (!tr) {
            setReportRequest({ ...reportRequest, pos: [...reportRequest.pos, chipToAdd] })
        }

    };

    const handleDeleteChip = (chipToDelete) => () => {

        const temp = reportRequest.pos.filter(t => t.IdPos !== chipToDelete.IdPos)

        setReportRequest({ ...reportRequest, pos: temp })
    };

    // const addAll = () => {
    //     setAllAdded(true);


    //     setReportRequest({ ...reportRequest, pos })
    // }

    // const deleteAll = () => {
    //     setAllAdded(false);
    //     setReportRequest({ ...reportRequest, pos: [] })
    // }

    const handleChangePosSwitch = () => {

        if (allAdded) {
            setAllAdded(false);
            setReportRequest({ ...reportRequest, pos: [] })
        }
        else {
            setAllAdded(true);


            setReportRequest({ ...reportRequest, pos })
        }
    }

    return (
        <>
            <Helmet>
                <title> Reportes | Aliados Meddipay </title>
            </Helmet>

            <Snackbar open={openSnackbar} autoHideDuration={5000} onClose={handleCloseSnackbar} anchorOrigin={{ horizontal: "center", vertical: "bottom" }}>
                <Alert onClose={handleCloseSnackbar} severity={snackbarData.severity} sx={{ width: '100%' }}>
                    {snackbarData.message}
                </Alert>
            </Snackbar>
            {/* <Typography variant="h4" sx={{ mb: 5 }}>
                Reportes
            </Typography> */}

            <Box sx={{ width: mdUp ? "40%" : "90%", margin: "50px auto" }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12} xl={12} >
                        <FormControl sx={{ width: "100%" }}>
                            <InputLabel htmlFor="report-type">Reporte</InputLabel>
                            <Select
                                label="Reporte"
                                id="report-type"
                                sx={{ width: "100%" }}
                                value={reportRequest.reportType}
                                onChange={(event) => { setReportRequest({ ...reportRequest, reportType: event.target.value }) }}
                                error={errors && errors.reportType}
                            >

                                {posData && posData.posReportsUser && posData.posReportsUser.map(d => {
                                    return <MenuItem key={d.Id} value={d.Id}>{d.ReportName}</MenuItem>
                                })}

                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} xl={12}>
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">

                            <DatePicker label="Fecha Desde" sx={{ width: "100%" }} format="DD-MM-YYYY"

                                value={reportRequest.datesData.date1O}
                                onChange={(a) => { setReportRequest({ ...reportRequest, datesData: { ...reportRequest.datesData, date1O: a, date1: a.format("YYYY-MM-DD") } }) }}
                                slotProps={{
                                    textField: {
                                        variant: 'outlined',
                                        error: errors && errors.date1,
                                    },
                                    shortcuts: {
                                        items: [
                                            {
                                                label: 'Ayer',
                                                getValue: () => {
                                                    const today = dayjs();
                                                    return today.add(-1, 'day');
                                                },
                                            },
                                            {
                                                label: 'Inicio de semana',
                                                getValue: () => {
                                                    const today = dayjs();
                                                    return today.startOf('week').add(1, 'day');
                                                },
                                            },
                                            {
                                                label: 'Inicio de semana anterior',
                                                getValue: () => {
                                                    const today = dayjs();
                                                    return today.startOf('week').add(-6, 'day');
                                                },
                                            },
                                            {
                                                label: 'Inicio de mes',
                                                getValue: () => {
                                                    const today = dayjs();
                                                    return today.startOf('month');
                                                },
                                            },
                                            {
                                                label: 'Inicio de mes anterior',
                                                getValue: () => {
                                                    const today = dayjs();
                                                    return today.startOf('month').add(-1, 'day').startOf('month');
                                                },
                                            },
                                            {
                                                label: 'Inicio de año',
                                                getValue: () => {
                                                    const today = dayjs();
                                                    return today.startOf('year');
                                                },
                                            },
                                            {
                                                label: 'Inicio de año anterior',
                                                getValue: () => {
                                                    const today = dayjs();
                                                    return today.startOf('year').add(-1, 'day').startOf('year');
                                                },
                                            },
                                        ],
                                    },
                                }}
                                maxDate={dayjs()}
                            />
                        </LocalizationProvider>

                    </Grid>


                    <Grid item xs={12} sm={12} md={12} xl={12}>
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">

                            <DatePicker label="Fecha Hasta" sx={{ width: "100%" }} format="DD-MM-YYYY"

                                value={reportRequest.datesData.date2O}
                                onChange={(a) => { setReportRequest({ ...reportRequest, datesData: { ...reportRequest.datesData, date2O: a, date2: a.format("YYYY-MM-DD") } }) }}
                                slotProps={{
                                    textField: {

                                        variant: 'outlined',
                                        error: errors && errors.date2,

                                    },
                                    shortcuts: {
                                        items: [
                                            {
                                                label: 'Hoy',
                                                getValue: () => {
                                                    const today = dayjs();
                                                    return today;
                                                },
                                            },
                                            {
                                                label: 'Fin de semana anterior',
                                                getValue: () => {
                                                    const today = dayjs();
                                                    return today.startOf('week').add(-6, 'day').endOf('week').add(1, 'day');
                                                },
                                            },
                                            {
                                                label: 'Fin de mes anterior',
                                                getValue: () => {
                                                    const today = dayjs();
                                                    return today.startOf('month').add(-1, 'day');
                                                },
                                            },
                                            {
                                                label: 'Fin de año anterior',
                                                getValue: () => {
                                                    const today = dayjs();
                                                    return today.startOf('year').add(-1, 'day');
                                                },
                                            },
                                        ],
                                    },
                                }}
                                maxDate={dayjs()}
                                minDate={reportRequest.datesData.date1O}
                            />

                        </LocalizationProvider>

                    </Grid>

                    <Grid item xs={12} sm={12} md={12} xl={12}>

                        <Typography variant="body1" sx={{ mt: 2, color: "#6c7b88" }}>
                            Sedes

                        </Typography>



                        <FormControlLabel control={<Switch
                            checked={allAdded}
                            onChange={handleChangePosSwitch}
                            inputProps={{ 'aria-label': 'controlled' }}
                        />} label="Todas" />

                        <ul style={{
                            display: 'flex',
                            justifyContent: 'center',
                            flexWrap: 'wrap',
                            listStyle: 'none',

                        }}>

                            {/* <li style={{ margin: "24px 4px 4px" }}>
                                <Chip
                                    color={allAdded ? "primary" : undefined}
                                    label="TODAS"
                                    onClick={addAll}
                                    onDelete={allAdded ? deleteAll : undefined}

                                />
                            </li> */}

                            {pos && pos.map((t) => {
                                return (
                                    <li key={t.IdPos} style={{ margin: "24px 4px 4px" }}>
                                        <Chip
                                            color={reportRequest && reportRequest.pos && reportRequest.pos.find(tr => tr.IdPos === t.IdPos) ? "primary" : undefined}
                                            label={t.DisplayName}
                                            onClick={handleAddChip(t)}
                                            onDelete={reportRequest && reportRequest.pos && reportRequest.pos.find(tr => tr.IdPos === t.IdPos) ? handleDeleteChip(t) : undefined}

                                        />
                                    </li>
                                );
                            })}
                        </ul>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} xl={12} style={{ textAlign: "center" }}>

                        <LoadingButton disabled={sending} color="info" variant="contained" onClick={queryReport} loading={sending}>
                            Consultar
                        </LoadingButton>
                    </Grid>



                </Grid>
            </Box>
        </>
    );
}
