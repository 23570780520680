import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import React, { useEffect, useState } from "react";
import {
    Modal,
    Snackbar,
    Alert,
    Box,
    Grid,
    Typography,
    TextField,
    Button,
    CircularProgress
} from '@mui/material';
import { useCreditRequestAuth } from "../Context/useCreditRequestAuth";
import { useCreditRequest } from "../Context/useCreditRequest";
import { IsKnownCustomer } from '../services/SoraService';
import CustomSelect from "../components/CustomSelect";
import TarsService from "../services/TarsService";
import useForm from "../hooks/useForm";
import imgLoader from '../Images/MeddipayLoaderEllipsis.gif';
import CustomBox from 'src/components/CustomBox';
import '../StyleSheets/SwalStyle.css';
import Iconify from '../components/iconify';
import { useGlobalProvider } from '../components/GlobalProvider'

const NewCustomerS1 = ({ handleChangeStep, handleCloseModal }) => {

    const { GetCustomerANI } = TarsService();
    const { clearRequest, logout, getStorage, setStorage } = useCreditRequestAuth();

    const { setLogoSize, getEC, setResendTimeSMS, setResendTimeEmail } = useCreditRequest();

    let request = getStorage("request") ?? {}
    const expiredTime = getStorage("expiredTime")

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenSnackbar(false);
    };

    const initialForm = { idType: request.cusDocumentType ? request.cusDocumentType : "CCC", idNumber: request.cusDocument ? request.cusDocument : "" };
    // const [sending, setSending] = useState(false);
    // const [idTypes, setIdTypes] = useState([]);
    const [snackbarData, setSnackbarData] = useState({ message: "msg", severity: "warning" });
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const { sending, setSending,
        documentTypes } = useGlobalProvider();
    const MySwal = withReactContent(Swal);

    const validateForm = (form) => {

        const errors = {};

        if (!form.idType.trim()) { errors.idType = "El campo Tipo de documento es requerido" }
        if (!form.idNumber.trim()) { errors.idNumber = "El campo Número de documento es requerido" }


        return errors;

    };



    const { form, errors, handleChangeOnlyNumbers, handleSubmit, handleChangeSelectWithName, resetForm } = useForm(initialForm, validateForm);


    useEffect(() => {


        const isCookieEnabled = navigator.cookieEnabled;

        if (!isCookieEnabled) {

            MySwal.fire({
                html: "Esta pagina necesita cookies para su funcionamiento. Permite el uso de cookies y actualiza la pagina",
                showConfirmButton: false,
                icon: 'error',
                allowEnterKey: false,
                allowEscapeKey: false,
                allowOutsideClick: false,
                customClass: {
                    container: 'swal2-container'
                }
            });

            return;
        }

        // removeCookies();
        setLogoSize({ width: "320px", marginTop: "40px", marginBottom: "30px" })
        // setSending(true);


        // let a = login({ userName: "Invitado", password: "Us3r1nv1t4d0", app: "TARS" });

        // const b = getEC();

        // let c = getEnumsPointOfSellData();

        // Promise.all([b]).then((values) => {
        //     setSending(false);


        // setIdTypes(documentTypes);

        if (Object.keys(request).length !== 0 && request.state !== 0 && new Date().getTime() < expiredTime) {


            // if (request.state === 9) {
            //     handleCloseModal();
            // } else {
                Swal.fire({
                    html: `Atención!<br/><br/>
                                Usted cuenta con un proceso actualmente guardado 💾 y vigente de registro en Meddipay para el documento ${request.cusDocumentTypeTemp || ''} ${request.cusDocument || ''}${request.cusFirstName ? ` - ${request.cusFirstName || ''} ${request.cusSecondName || ''} ${request.cusFirstSurname || ''} ${request.cusSecondSurname || ''}` : ''}.<br/><br/>
                                ¿Desea continuar con el proceso donde lo dejó 🔙?`,
                    showDenyButton: true,
                    confirmButtonText: 'SI',
                    confirmButtonColor: "#00A9EB",
                    denyButtonText: 'NO',
                    icon: 'warning',
                    customClass: {
                        container: 'swal2-container'
                    }
                }).then((result) => {
                    if (result.isConfirmed) {
                        handleChangeStep(1);

                    } else if (result.isDenied) {
                        logout();
                        clearRequest();
                        setResendTimeSMS(0)
                        setResendTimeEmail(0)
                        resetForm({ idType: "CCC", idNumber: "" });
                    }
                })
            // }


        }
        else if (!expiredTime || new Date().getTime() > expiredTime) {
            logout();
            clearRequest();
            setResendTimeSMS(0)
            setResendTimeEmail(0)
            resetForm({ idType: "CCC", idNumber: "" });
        }




        // })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);




    const submitForm = (form, error) => {

        form.idNumber = form.idNumber.trim();
        if (!error) {
            setSending(true);

            if (request.cusDocument && request.cusDocument !== form.idNumber) {

                setStorage("request", {});
                request = {}
            }

            IsKnownCustomer(form.idType, form.idNumber).then(res => {

                if (res.status === 200) {
                    const data = JSON.parse(res.data.data);

                    if (!data.KnownCustomer) {

                        if (request && !request.updateCustomer) {
                            setStorage("creditRequestToken", data.token, { path: "/" })
                            setStorage("expiredTime", Date.parse(data.ExpiredTime), { path: "/" })
                        }

                        if (form.idType === "CCC") {

                            GetCustomerANI({
                                cusDocumentType: "CCC",
                                cusDocument: form.idNumber
                            }).then(resani => {
                                setSending(false);
                                if (resani.status === 200) {
                                    const data = resani.data.data;

                                    if (!data.isAni) {// NO EXISTE EN ANI
                                        MySwal.fire({
                                            html: "La cedula ingresada no existe en la Registraduría",
                                            icon: 'error',
                                            customClass: {
                                                container: 'swal2-container'
                                            }
                                        })
                                    }
                                    else if (data.isAni && !data.isValid) {// EXISTE PERO NO ES VALIDO
                                        MySwal.fire({
                                            html: "La cedula ingresada no tiene un estado valido",
                                            icon: 'error',
                                            zIndex: 300000,
                                            customClass: {
                                                container: 'swal2-container'
                                            }
                                        })
                                    }
                                    else {

                                        setStorage("request", {
                                            ...request,
                                            cusDocumentType: form.idType,
                                            cusDocumentTypeTemp: documentTypes.find(t => t.value === form.idType).label,

                                            cusDocument: form.idNumber,
                                            cusEmail: request.cusEmail && !request.cusEmail.includes("*") ? request.cusEmail : "",
                                            cusPhone: request.cusPhone && !request.cusPhone.includes("#") ? request.cusPhone : "",
                                            origin: 1,
                                            state: 1,

                                            disableName: (data.cusFirstName && data.cusFirstSurname) ? true : false,

                                            cusFirstName: data.cusFirstName,
                                            cusSecondName: data.cusSecondName,
                                            cusFirstSurname: data.cusFirstSurname,
                                            cusSecondSurname: data.cusSecondSurname,

                                            cusType: "ANIP"
                                        })
                                        handleChangeStep(1);
                                    }
                                }
                                else {
                                    setStorage("request", {
                                        ...request,
                                        cusType: "REGP",
                                        cusDocumentType: form.idType,
                                        cusDocumentTypeTemp: documentTypes.find(t => t.value === form.idType).label,

                                        cusDocument: form.idNumber,
                                        cusEmail: request.cusEmail && !request.cusEmail.includes("*") ? request.cusEmail : "",
                                        cusPhone: request.cusPhone && !request.cusPhone.includes("#") ? request.cusPhone : "",
                                        origin: 1,
                                        state: 1,

                                        cusFirstName: "",
                                        cusSecondName: "",
                                        cusFirstSurname: "",
                                        cusSecondSurname: "",


                                        disableName: false
                                    })

                                    handleChangeStep(1);
                                }


                            })
                        }
                        else {
                            setSending(false);
                            setStorage("request", {
                                ...request,
                                cusType: "REGP",
                                cusDocumentType: form.idType,
                                cusDocumentTypeTemp: documentTypes.find(t => t.value === form.idType).label,

                                cusDocument: form.idNumber,
                                cusEmail: request.cusEmail && !request.cusEmail.includes("*") ? request.cusEmail : "",
                                cusPhone: request.cusPhone && !request.cusPhone.includes("#") ? request.cusPhone : "",
                                origin: 1,
                                state: 1,

                                disableName: false
                            })

                            handleChangeStep(1);
                        }




                    }
                    else {
                        setSending(false);

                        Swal.fire({
                            html: `El cliente ya se encuentra registrado en Meddipay`,
                            showDenyButton: false,
                            confirmButtonText: 'OK',
                            confirmButtonColor: "#00A9EB",

                            icon: 'warning',
                        })
                        // setStorage("creditRequestToken", data.token, { path: "/" })
                        // setStorage("expiredTime", Date.parse(data.ExpiredTime), { path: "/" })

                        // setStorage("request", {
                        //     ...request,
                        //     cusType: "REG",
                        //     cusDocumentType: form.idType,
                        //     cusDocumentTypeTemp: documentTypes.find(t => t.value === form.idType).label,

                        //     cusDocument: form.idNumber,
                        //     cusEmail: data.methods.find(el => el.type === "EMAIL")?.label,
                        //     cusPhone: data.methods.find(el => el.type === "SMS")?.label ?? data.methods.find(el => el.type === "WA")?.label,

                        //     idMethodEmail: data.methods.find(el => el.type === "EMAIL")?.id,
                        //     idMethodSMS: data.methods.find(el => el.type === "SMS")?.id,
                        //     idMethodWA: data.methods.find(el => el.type === "WA")?.id,

                        //     origin: 2,
                        //     state: 2
                        // })

                        // handleChangeStep(2);
                    }
                }
                else {
                    setSending(false);
                    MySwal.fire({
                        html: res.data.message ?? "ERROR",
                        icon: 'error',
                        customClass: {
                            container: 'swal2-container'
                        }
                    })
                }


            })
        }
    }

    const modalStyle = {
        margin: "50px auto",
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        pt: 2,
        px: 4,
        pb: 3,
    };

    return (
        <>
            <Snackbar open={openSnackbar} autoHideDuration={5000} onClose={handleCloseSnackbar} anchorOrigin={{ horizontal: "center", vertical: "bottom" }}>
                <Alert onClose={handleCloseSnackbar} severity={snackbarData.severity} sx={{ width: '100%' }}>
                    {snackbarData.message}
                </Alert>
            </Snackbar>
            <Box
                sx={{
                    ...modalStyle, width: '80%'
                }}
            >

                <Grid item xs={12} sm={12} md={12} style={{ textAlign: "right", marginTop: "0px", paddingTop: "0px" }}>
                    <button style={{ border: "none", backgroundColor: "transparent", fontWeight: "bold", fontSize: "30px", cursor: "pointer", marginTop: "0px" }} onClick={handleCloseModal} ><Iconify sx={{ width: "30px", height: "30px" }} icon="eva:close-outline" /></button>

                </Grid>
                <Box sx={{ width: { xs: '100%', sm: '80%', md: '60%' }, margin: '0 auto' }}>
                    <form onSubmit={(e) => handleSubmit(e, submitForm)}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sx={{ textAlign: 'center' }}>
                                <Typography variant="h5" component="h2">
                                    Nuevo cliente
                                </Typography>
                            </Grid>

                            <Grid item xs={12}>
                                <CustomSelect
                                    name="idType"
                                    value={form.idType}
                                    options={documentTypes.map(el => { return { value: el.value, name: el.label } })}
                                    text="Tipo de documento"
                                    error={errors.idType}
                                    handleChangeSelect={handleChangeSelectWithName}
                                    disabled={false}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    id="idNumber"
                                    type="text"
                                    name="idNumber"
                                    value={form.idNumber}
                                    onChange={handleChangeOnlyNumbers}
                                    label="Número de documento"
                                    variant="outlined"
                                    fullWidth
                                    error={!!errors.idNumber}
                                    helperText={errors.idNumber}
                                    autoComplete="off"
                                />
                            </Grid>
                            <Grid item xs={12} sx={{ textAlign: 'center', padding: '10px' }}>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    disabled={sending}
                                    sx={{
                                        backgroundColor: '#00A9EB',
                                        '&:hover': {
                                            backgroundColor: '#00a0df',
                                        },
                                        color: 'white',
                                    }}
                                >
                                    Continuar
                                </Button>
                            </Grid>
                            {sending && (
                                <Grid item xs={12} sx={{ textAlign: 'center' }}>
                                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                        <CircularProgress />
                                    </Box>
                                </Grid>
                            )}
                        </Grid>
                    </form>
                </Box>
            </Box>
        </>

    );
}

export default NewCustomerS1;