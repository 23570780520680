import { useEffect, useState } from 'react';
import {
    Modal,
    Box,
    Grid,
    Autocomplete,
    TextField,
    Chip,
    InputAdornment,
    Snackbar,
    Alert,
    Paper,
    Typography,
    Tooltip,
    Select,
    MenuItem,
    InputLabel,
    FormControl,
    FormControlLabel,
    Switch,
    ClickAwayListener
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Avatar from 'avataaars';
import Iconify from '../components/iconify';
import useResponsive from '../hooks/useResponsive';
import { useGlobalProvider } from '../components/GlobalProvider'
import SoraServiceWithToken from '../services/SoraServiceWithToken'

export default function ProfileModal({ openModal, setOpenModal }) {

    const [profileData, setProfileData] = useState({ name: localStorage.getItem("fullName") ?? "", email: localStorage.getItem("email") ?? "", phone: localStorage.getItem("phone") ?? "" });


    const [openAvatarModal, setOpenAvatarModal] = useState(false);
    const [errors, setErrors] = useState({});
    const { posData, sending, setSending, avatarData, setAvatarData } = useGlobalProvider();
    const [snackbarData, setSnackbarData] = useState({ message: "msg", severity: "warning" });
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const { SaveProfileData } = SoraServiceWithToken();
    const [openTooltip1, setOpenTooltip1] = useState(false);
    const [openTooltip2, setOpenTooltip2] = useState(false);
    // const mdDown = useResponsive('down', 'md');
    const xlUp = useResponsive('up', 'xl');
    const mdUp = useResponsive('up', 'md');

    const tops = [
        {
            value: "NoHair",
            text: "Sin Pelo"
        },
        {
            value: "Eyepatch",
            text: "Parche en el ojo"
        },
        {
            value: "Hat",
            text: "Sombrero"
        },
        {
            value: "Hijab",
            text: "Hiyab"
        },
        {
            value: "Turban",
            text: "Turbante"
        },
        {
            value: "WinterHat1",
            text: "Gorro de Invierno 1"
        },
        {
            value: "WinterHat2",
            text: "Gorro de Invierno 2"
        },
        {
            value: "WinterHat3",
            text: "Gorro de Invierno 3"
        },
        {
            value: "WinterHat4",
            text: "Gorro de Invierno 4"
        },
        {
            value: "LongHairBigHair",
            text: "Cabello largo con Volumen"
        },
        {
            value: "LongHairBob",
            text: "Cabello largo Bob"
        },
        {
            value: "LongHairBun",
            text: "Cabello largo con moño"
        },
        {
            value: "LongHairCurly",
            text: "Cabello largo rizado"
        },
        {
            value: "LongHairCurvy",
            text: "Cabello largo con curvas"
        },
        {
            value: "LongHairDreads",
            text: "Cabello largo con rastas"
        },
        {
            value: "LongHairFrida",
            text: "Cabello estilo Frida"
        },
        {
            value: "LongHairFro",
            text: "Cabello largo afro"
        },
        {
            value: "LongHairFroBand",
            text: "Cabello largo afro con cinta"
        },
        {
            value: "LongHairNotTooLong",
            text: "Cabello medio largo"
        },
        {
            value: "LongHairShavedSides",
            text: "Cabello largo con lados afeitados"
        },
        {
            value: "LongHairMiaWallace",
            text: "Cabello a lo Mia Wallace"
        },
        {
            value: "LongHairStraight",
            text: "Cabello largo liso 1"
        },
        {
            value: "LongHairStraight2",
            text: "Cabello largo liso 2"
        },
        {
            value: "LongHairStraightStrand",
            text: "Cabello largo con capul"
        },
        {
            value: "ShortHairDreads01",
            text: "Cabello corto con rastas cortas"
        },
        {
            value: "ShortHairDreads02",
            text: "Cabello corto con rastas largas"
        },
        {
            value: "ShortHairFrizzle",
            text: "Cabello corto encrespado"
        },
        {
            value: "ShortHairShaggyMullet",
            text: "Cabello corto despeinado"
        },
        {
            value: "ShortHairShortCurly",
            text: "Cabello corto y rizado"
        },
        {
            value: "ShortHairShortFlat",
            text: "Cabello corto"
        },
        {
            value: "ShortHairShortRound",
            text: "Cabello corto y liso"
        },
        {
            value: "ShortHairShortWaved",
            text: "Cabello corto y ondulado"
        },
        {
            value: "ShortHairSides",
            text: "Cabello corto lateral"
        },
        {
            value: "ShortHairTheCaesar",
            text: "Cabello corto tipo militar"
        },
        {
            value: "ShortHairTheCaesarSidePart",
            text: "Cabello corto linea superior"
        }
    ]

    const accessories = [
        {
            value: "Blank",
            text: "Sin Accesorios"
        },
        {
            value: "Kurt",
            text: "Gafas de sol Blancas"
        },
        {
            value: "Prescription01",
            text: "Gafas de ver 1"
        },
        {
            value: "Prescription02",
            text: "Gafas de ver 2"
        },
        {
            value: "Round",
            text: "Gafas de ver redondas"
        },
        {
            value: "Sunglasses",
            text: "Gafas de sol"
        },
        {
            value: "Wayfarers",
            text: "Gafas de sol gruesas"
        }
    ]

    const hairs = [
        {
            value: "Auburn",
            text: "Castaño"
        },
        {
            value: "Black",
            text: "Negro"
        },
        {
            value: "Blonde",
            text: "Rubio"
        },
        {
            value: "BlondeGolden",
            text: "Rubio dorado"
        },
        {
            value: "Brown",
            text: "Marrón"
        },
        {
            value: "BrownDark",
            text: "Marrón oscuro"
        },
        {
            value: "PastelPink",
            text: "Rosa pastel"
        },
        {
            value: "Blue",
            text: "Azul"
        },
        {
            value: "Platinum",
            text: "Platino"
        },
        {
            value: "Red",
            text: "Rojo"
        },
        {
            value: "SilverGray",
            text: "Gris plata"
        }
    ]

    const facialHairs = [
        {
            value: "Blank",
            text: "Sin barba"
        },
        {
            value: "BeardMedium",
            text: "Barba mediana"
        },
        {
            value: "BeardLight",
            text: "Barba ligera"
        },
        {
            value: "BeardMajestic",
            text: "Barba majestuosa"
        },
        {
            value: "MoustacheFancy",
            text: "Bigote elegante"
        },
        {
            value: "MoustacheMagnum",
            text: "Bigote magnum"
        }
    ]

    const facialHairColors = [
        {
            value: "Auburn",
            text: "Castaño"
        },
        {
            value: "Black",
            text: "Negro"
        },
        {
            value: "Blonde",
            text: "Rubio"
        },
        {
            value: "BlondeGolden",
            text: "Rubio dorado"
        },
        {
            value: "Brown",
            text: "Marrón"
        },
        {
            value: "BrownDark",
            text: "Marrón oscuro"
        },
        {
            value: "Platinum",
            text: "Platino"
        },
        {
            value: "Red",
            text: "Rojo"
        }
    ]

    const clothes = [
        {
            value: "BlazerShirt",
            text: "Camisa americana"
        },
        {
            value: "BlazerSweater",
            text: "Suéter americano"
        },
        {
            value: "CollarSweater",
            text: "Suéter redondo"
        },
        {
            value: "GraphicShirt",
            text: "Camiseta con diseño"
        },
        {
            value: "Hoodie",
            text: "Hoodie"
        },
        {
            value: "Overall",
            text: "Enterizo"
        },
        {
            value: "ShirtCrewNeck",
            text: "Camiseta cuello redondo"
        },
        {
            value: "ShirtScoopNeck",
            text: "Camiseta abierta"
        },
        {
            value: "ShirtVNeck",
            text: "Camiseta en V"
        }
    ]

    const clotheColors = [
        {
            value: "Black",
            text: "Negro"
        },
        {
            value: "Blue01",
            text: "Azul claro"
        },
        {
            value: "Blue02",
            text: "Azul medio"
        },
        {
            value: "Blue03",
            text: "Azul oscuro"
        },
        {
            value: "Gray01",
            text: "Gris claro"
        },
        {
            value: "Gray02",
            text: "Gris medio"
        },
        {
            value: "Heather",
            text: "Gris oscuro"
        },
        {
            value: "PastelBlue",
            text: "Pastel azul"
        },
        {
            value: "PastelGreen",
            text: "Pastel Verde"
        },
        {
            value: "PastelOrange",
            text: "Pastel Naranja"
        },
        {
            value: "PastelRed",
            text: "Pastel Rojo"
        },
        {
            value: "PastelYellow",
            text: "Pastel Amarillo"
        },
        {
            value: "Pink",
            text: "Rosa"
        },
        {
            value: "Red",
            text: "Rojo"
        },
        {
            value: "White",
            text: "Blanco"
        }
    ]

    const GraphicShirts = [
        {
            value: "Bat",
            text: "Murciélago"
        },
        {
            value: "Cumbia",
            text: "Cumbia!"
        },
        {
            value: "Deer",
            text: "Ciervo"
        },
        {
            value: "Diamond",
            text: "Diamante"
        },
        {
            value: "Hola",
            text: "Hola!"
        },
        {
            value: "Pizza",
            text: "Pizza"
        },
        {
            value: "Resist",
            text: "Resist!"
        },
        {
            value: "Selena",
            text: "Selena"
        },
        {
            value: "Bear",
            text: "Oso"
        },
        {
            value: "SkullOutline",
            text: "Calavera contorno"
        },
        {
            value: "Skull",
            text: "Calavera solida"
        }
    ]

    const eyes = [
        {
            value: "Close",
            text: "Cerrados"
        },
        {
            value: "Cry",
            text: "Lágrima"
        },
        {
            value: "Default",
            text: "Normales"
        },
        {
            value: "Dizzy",
            text: "Mareados"
        },
        {
            value: "EyeRoll",
            text: "En blanco"
        },
        {
            value: "Happy",
            text: "Felices"
        },
        {
            value: "Hearts",
            text: "Corazones"
        },
        {
            value: "Side",
            text: "Sospechosos"
        },
        {
            value: "Squint",
            text: "Entre cerrados"
        },
        {
            value: "Surprised",
            text: "Sorpresa"
        },
        {
            value: "Wink",
            text: "Guiño derecho"
        },
        {
            value: "WinkWacky",
            text: "Guiño izquierdo"
        }
    ]

    const eyeBrows = [
        {
            value: "Angry",
            text: "Enfadada/o"
        },
        {
            value: "AngryNatural",
            text: "Enfadada/o natural"
        },
        {
            value: "Default",
            text: "Normal"
        },
        {
            value: "DefaultNatural",
            text: "Normal natural"
        },
        {
            value: "FlatNatural",
            text: "Normal derecho"
        },
        {
            value: "RaisedExcited",
            text: "Emocionada/o"
        },
        {
            value: "RaisedExcitedNatural",
            text: "Emocionada/o natural"
        },
        {
            value: "SadConcerned",
            text: "Triste"
        },
        {
            value: "SadConcernedNatural",
            text: "Triste natural"
        },
        {
            value: "UnibrowNatural",
            text: "Uniceja/o"
        },
        {
            value: "UpDown",
            text: "Arriba abajo"
        },
        {
            value: "UpDownNatural",
            text: "Arriba abajo natural"
        }
    ]

    const mouths = [
        {
            value: "Concerned",
            text: "Preocupada/o"
        },
        {
            value: "Default",
            text: "Abierta"
        },
        {
            value: "Disbelief",
            text: "Incredulidad"
        },
        {
            value: "Eating",
            text: "Comiendo"
        },
        {
            value: "Grimace",
            text: "Dientes"
        },
        {
            value: "Sad",
            text: "Triste"
        },
        {
            value: "ScreamOpen",
            text: "Gritando"
        },
        {
            value: "Serious",
            text: "Seria/o"
        },
        {
            value: "Smile",
            text: "Sonriendo"
        },
        {
            value: "Tongue",
            text: "Lengua"
        },
        {
            value: "Twinkle",
            text: "Contento"
        },
        {
            value: "Vomit",
            text: "Vomitando"
        }
    ]

    const skins = [
        {
            value: "Tanned",
            text: "Bronceada/o"
        },
        {
            value: "Yellow",
            text: "Amarilla/o"
        },
        {
            value: "Pale",
            text: "Palida/o"
        },
        {
            value: "Light",
            text: "Clara/o"
        },
        {
            value: "Brown",
            text: "Marrón"
        },
        {
            value: "DarkBrown",
            text: "Marrón Oscuro"
        },
        {
            value: "Black",
            text: "Negra/o"
        }
    ]

    const hatColors = [
        {
            value: "Black",
            text: "Negro"
        },
        {
            value: "Blue01",
            text: "Azul 1"
        },
        {
            value: "Blue02",
            text: "Azul 2"
        },
        {
            value: "Blue03",
            text: "Azul 3"
        },
        {
            value: "Gray01",
            text: "Gris 1"
        },
        {
            value: "Gray02",
            text: "Gris 2"
        },
        {
            value: "Heather",
            text: "Gris oscuro"
        },
        {
            value: "PastelBlue",
            text: "Azul pastel"
        },
        {
            value: "PastelGreen",
            text: "Verde pastel"
        },
        {
            value: "PastelOrange",
            text: "Naranja pastel"
        },
        {
            value: "PastelRed",
            text: "Rojo pastel"
        },
        {
            value: "PastelYellow",
            text: "Amarillo pastel"
        },
        {
            value: "Pink",
            text: "Rosado"
        },
        {
            value: "Red",
            text: "Rojo"
        },
        {
            value: "White",
            text: "Blanco"
        }
    ]

    useEffect(() => {

        const avatar = JSON.parse(localStorage.getItem("avatar"))

        if (avatar) {
            setAvatarData(avatar);
        }
        else {
            randomAvatar();
        }



    }, []);



    const handleCloseModal = (event, reason) => {

        if (reason && reason === "backdropClick")
            return;
        setOpenModal(false)
    }

    const handleCloseAvatarModal = (event, reason) => {

        if (reason && reason === "backdropClick")
            return;
        setOpenAvatarModal(false)
    }

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenSnackbar(false);
    };

    const saveData = () => {
        setSending(true);

        setErrors({})

        let errorsB = false;

        if (!profileData.name) {
            setOpenSnackbar(true)
            setErrors(errors => { return { ...errors, name: true } })
            setSnackbarData({ message: "El campo Nombre es obligatorio", severity: "warning" })
            errorsB = true;
        }

        const regexEmail = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        if (!profileData.email || !regexEmail.test(profileData.email)) {
            setOpenSnackbar(true)
            setErrors(errors => { return { ...errors, email: true } })
            setSnackbarData({ message: "El campo Correo es obligatorio", severity: "warning" })
            errorsB = true;
        }

        if (errorsB) { setSending(false); return };

        const body = {
            fullname: profileData.name,
            email: profileData.email,
            phone: profileData.phone,
            avatar: avatarData,
            idApp: 4
        }

        SaveProfileData(body, localStorage.getItem("userName")).then(res => {
            setSending(false);
            if (res.status === 200) {

                localStorage.setItem("fullName", profileData.name);
                localStorage.setItem("email", profileData.email);
                localStorage.setItem("phone", profileData.phone);
                localStorage.setItem("firstLogin", false);
                localStorage.setItem("avatar", JSON.stringify(avatarData));

                setOpenSnackbar(true)
                setSnackbarData({ message: "Datos guardados!", severity: "success" });
                setOpenModal(false);

            } else {
                setOpenSnackbar(true)
                setSnackbarData({ message: "Error guardando los datos", severity: "error" })
            }
        });
    }


    const randomAvatar = () => {

        const avatarTemp = {
            topType: tops[(Math.floor(Math.random() * tops.length))].value,
            hatColor: hatColors[(Math.floor(Math.random() * hatColors.length))].value,
            accessoriesType: accessories[(Math.floor(Math.random() * accessories.length))].value,
            hairColor: hairs[(Math.floor(Math.random() * hairs.length))].value,
            facialHairType: facialHairs[(Math.floor(Math.random() * facialHairs.length))].value,
            facialHairColor: facialHairColors[(Math.floor(Math.random() * facialHairColors.length))].value,
            clotheType: clothes[(Math.floor(Math.random() * clothes.length))].value,
            clotheColor: clotheColors[(Math.floor(Math.random() * clotheColors.length))].value,
            eyeType: eyes[(Math.floor(Math.random() * eyes.length))].value,
            eyebrowType: eyeBrows[(Math.floor(Math.random() * eyeBrows.length))].value,
            mouthType: mouths[(Math.floor(Math.random() * mouths.length))].value,
            skinColor: skins[(Math.floor(Math.random() * skins.length))].value,
            graphicType: GraphicShirts[(Math.floor(Math.random() * GraphicShirts.length))].value
        }

        setAvatarData(avatarTemp);

        // localStorage.setItem("avatar", JSON.stringify(avatarTemp))
    }

    const modalStyle = {
        margin: "20px auto",
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        pt: 2,
        px: 4,
        pb: 3,
    };

    return <>

        <Snackbar open={openSnackbar} autoHideDuration={5000} onClose={handleCloseSnackbar} anchorOrigin={{ horizontal: "center", vertical: "bottom" }}>
            <Alert onClose={handleCloseSnackbar} severity={snackbarData.severity} sx={{ width: '100%' }}>
                {snackbarData.message}
            </Alert>
        </Snackbar>

        <Modal
            open={openModal}
            onClose={handleCloseModal}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
            sx={{

                overflow: 'scroll',

            }}>

            <Box sx={{ ...modalStyle, width: mdUp ? "40%" : "90%", textAlign: "center" }}>



                <Grid container spacing={2}>
                    <Grid item xs={10} sm={10} md={10} xl={10} />
                    <Grid item xs={2} sm={2} md={2} style={{ textAlign: "right", padding: "0px" }}><button style={{ border: "none", backgroundColor: "transparent", fontWeight: "bold", fontSize: "30px", cursor: "pointer", marginTop: "15px" }} onClick={() => { setAvatarData(JSON.parse(localStorage.getItem("avatar")) ?? avatarData); setOpenModal(false); }}><Iconify sx={{ width: "30px", height: "30px" }} icon="eva:close-outline" /></button></Grid>
                    <Grid item xs={12} sm={12} md={12} xl={12} style={{ textAlign: "center", paddingTop: "0px" }}> <h2 id="parent-modal-title" style={{ margin: "0" }}>Personaliza tu perfil</h2></Grid>
                    <Grid item xs={12} sm={12} md={12} xl={12} style={{ paddingTop: "0px" }}>

                        {localStorage.getItem("userName") ?? ""}

                    </Grid>

                    {JSON.parse(localStorage.getItem("firstLogin")) ? <Grid item xs={12} sm={12} md={12} xl={12}>
                        <p style={{ margin: "0px 0px 15px 0px", fontSize: "15px" }}>
                            Conocemos tu nombre de usuario, pero necesitamos solamente 4 datos mas sobre ti. Este proceso solo se realiza la primera vez que inicias sesión y podrás cambiar estos datos mas adelante en cualquier momento.
                        </p>
                        <br />
                    </Grid> : <p><br /></p>}



                    <Grid container spacing={2} >

                        <Grid item xs={12} sm={12} md={12} xl={12}>
                            <TextField id="Nombre" label="Nombre completo*" variant="outlined" sx={{ width: "100%" }}
                                type="text"
                                autoComplete="off"
                                value={profileData.name}
                                onChange={(event) => { setProfileData({ ...profileData, name: event.target.value }); setErrors(errors => { return { ...errors, name: false } }) }}
                                error={errors && errors.name}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} xl={12}>
                            <TextField id="Correo" label="Correo*" variant="outlined" sx={{ width: "100%" }}
                                type="text"
                                autoComplete="off"
                                value={profileData.email}
                                onChange={(event) => { setProfileData({ ...profileData, email: event.target.value }); setErrors(errors => { return { ...errors, email: false } }) }}
                                error={errors && errors.email}
                                InputProps={{
                                    endAdornment:
                                        <ClickAwayListener onClickAway={() => { setOpenTooltip1(false) }}>
                                            <Tooltip disableFocusListener
                                                disableHoverListener
                                                disableTouchListener
                                                PopperProps={{
                                                    disablePortal: true,
                                                }} open={openTooltip1} onClose={() => { setOpenTooltip1(false) }} title="Necesitamos tu correo electrónico personal por motivos de seguridad. Lo utilizaremos principalmente para que puedas recuperar tu contraseña en caso de que te la olvides." enterTouchDelay={0}><InputAdornment style={{ cursor: "pointer" }} position="start" onClick={() => { setOpenTooltip1(true) }}>?</InputAdornment></Tooltip>
                                        </ClickAwayListener>
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} xl={12}>
                            <TextField id="Celular" label="Celular" variant="outlined" sx={{ width: "100%" }}
                                type="text"
                                autoComplete="off"
                                value={profileData.phone}
                                onChange={(event) => { setProfileData({ ...profileData, phone: event.target.value }); setErrors(errors => { return { ...errors, phone: false } }) }}
                                error={errors && errors.phone}

                                InputProps={{
                                    endAdornment:
                                        <ClickAwayListener onClickAway={() => { setOpenTooltip2(false) }}>
                                            <Tooltip disableFocusListener
                                                disableHoverListener
                                                disableTouchListener
                                                PopperProps={{
                                                    disablePortal: true,
                                                }} open={openTooltip2} onClose={() => { setOpenTooltip2(false) }} title="Tu numero de celular es opcional, pero recomendamos diligenciarlo por motivos de seguridad para poder restablecer la contraseña ademas de recibir comunicaciones importantes." enterTouchDelay={0}><InputAdornment style={{ cursor: "pointer" }} position="start" onClick={() => { setOpenTooltip2(true) }}>?</InputAdornment></Tooltip>
                                        </ClickAwayListener>
                                }}

                            />
                        </Grid>
                    </Grid>



                    <Grid container style={{ border: "1px solid", marginTop: "20px", borderRadius: "5px", borderColor: "#d9d9d9", boxShadow: "0px 11px 15px -7px rgba(145, 158, 171, 0.2),0px 24px 38px 3px rgba(145, 158, 171, 0.14),0px 9px 46px 8px rgba(145, 158, 171, 0.12)" }} >
                        <Grid item xs={12} sm={12} md={12} xl={12} style={{ textAlign: "center" }}> <h4 style={{ marginBottom: "0px", marginTop: "10px" }}>Avatar</h4></Grid>
                        <Grid item xs={6} sm={6} md={6} xl={6}>
                            {avatarData.topType && <Avatar
                                style={{ width: 100, height: 100, marginTop: "12px" }}
                                avatarStyle='Circle'
                                topType={avatarData.topType}
                                hatColor={avatarData.hatColor}
                                accessoriesType={avatarData.accessoriesType}
                                hairColor={avatarData.hairColor}
                                facialHairType={avatarData.facialHairType}
                                facialHairColor={avatarData.facialHairColor}
                                clotheType={avatarData.clotheType}
                                clotheColor={avatarData.clotheColor}
                                eyeType={avatarData.eyeType}
                                eyebrowType={avatarData.eyebrowType}
                                mouthType={avatarData.mouthType}
                                skinColor={avatarData.skinColor}
                                graphicType={avatarData.graphicType}
                            />}

                        </Grid>
                        <Grid item xs={6} sm={6} md={6} xl={6}>
                            {/* <LoadingButton style={{ width: mdUp ? "200px" : "120px", backgroundColor: "gray" }} disabled={sending} variant="contained" onClick={randomAvatar} loading={sending}>
                                Avatar aleatorio
                            </LoadingButton>
                            <br />     <br /> */}
                            <LoadingButton style={{ width: xlUp ? "200px" : "120px", marginTop: "20px" }} disabled={sending} color="success" variant="contained" onClick={() => { setOpenAvatarModal(true) }} loading={sending}>
                                Personalizar
                            </LoadingButton>
                            <br />     <br />
                            <LoadingButton style={{ width: xlUp ? "200px" : "120px", backgroundColor: "gray", marginBottom: "20px" }} disabled={sending} variant="contained" onClick={randomAvatar} loading={sending}>
                                Aleatorio
                            </LoadingButton>
                        </Grid>


                    </Grid>

                    <Grid item xs={12} sm={12} md={12} xl={12} container justifyContent="center" style={{ padding: "20px 0px 0px 0px" }}>
                        <LoadingButton disabled={sending} color="info" variant="contained" onClick={saveData} loading={sending}>
                            Guardar
                        </LoadingButton>
                    </Grid>
                </Grid>
            </Box>
        </Modal>



        <Modal
            open={openAvatarModal}
            onClose={handleCloseAvatarModal}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
            sx={{

                overflow: 'scroll',

            }}>

            <Box sx={{ ...modalStyle, width: mdUp ? "40%" : "90%", textAlign: "center", paddingRight: "20px" }}>
                <Grid container spacing={2}>
                    <Grid item xs={10} sm={10} md={10} xl={10} />
                    <Grid item xs={2} sm={2} md={2} style={{ textAlign: "right", padding: "0px" }}><button style={{ border: "none", backgroundColor: "transparent", fontWeight: "bold", fontSize: "30px", cursor: "pointer", marginTop: "15px" }} onClick={() => { setAvatarData(JSON.parse(localStorage.getItem("avatar")) ?? {}); setOpenAvatarModal(false); }}><Iconify sx={{ width: "30px", height: "30px" }} icon="eva:close-outline" /></button></Grid>
                    <Grid item xs={12} sm={12} md={12} xl={12} style={{ textAlign: "center", paddingTop: "0px" }}> <h2 id="parent-modal-title" style={{ margin: "0" }}>Personaliza tu Avatar</h2></Grid>








                    <Grid container  >

                        <Grid item xs={12} sm={12} md={12} xl={12}>
                            <Avatar
                                style={{ width: 100, height: 100 }}
                                avatarStyle='Circle'
                                topType={avatarData.topType}
                                hatColor={avatarData.hatColor}
                                accessoriesType={avatarData.accessoriesType}
                                hairColor={avatarData.hairColor}
                                facialHairType={avatarData.facialHairType}
                                facialHairColor={avatarData.facialHairColor}
                                clotheType={avatarData.clotheType}
                                clotheColor={avatarData.clotheColor}
                                eyeType={avatarData.eyeType}
                                eyebrowType={avatarData.eyebrowType}
                                mouthType={avatarData.mouthType}
                                skinColor={avatarData.skinColor}
                                graphicType={avatarData.graphicType}
                            />

                        </Grid>
                        <Grid item xs={12} sm={12} md={12} xl={12}>
                            <br />
                            <LoadingButton style={{ width: "200px", backgroundColor: "gray" }} disabled={sending} variant="contained" onClick={randomAvatar} loading={sending}>
                                Avatar aleatorio
                            </LoadingButton>
                        </Grid>

                        <Grid container spacing={2} style={{ marginTop: "10px", maxHeight: "40vh", overflowY: "scroll" }}>
                            <Grid item xs={12} sm={12} md={12} xl={12} style={{}} >
                                <FormControl sx={{ width: "100%" }}>
                                    <InputLabel htmlFor="topType">🧑‍🦲 Cabeza</InputLabel>
                                    <Select
                                        label="🧑‍🦲 Cabeza"
                                        id="topType"
                                        sx={{ width: "100%" }}
                                        value={avatarData.topType ?? ""}
                                        onChange={(event) => { setAvatarData({ ...avatarData, topType: event.target.value }) }}>

                                        {tops.map(a => { return <MenuItem key={a.value} value={a.value}>{a.text}</MenuItem> })}

                                    </Select>
                                </FormControl>


                            </Grid>


                            <Grid item xs={12} sm={12} md={12} xl={12}>
                                <FormControl sx={{ width: "100%" }}>
                                    <InputLabel htmlFor="hairColor">🖌️ Color del cabello</InputLabel>
                                    <Select
                                        label="🖌️ Color del cabello"
                                        id="hairColor"
                                        sx={{ width: "100%" }}
                                        value={avatarData.hairColor ?? ""}
                                        disabled={["Hijab", "Turban", "WinterHat1", "WinterHat2", "WinterHat3", "WinterHat4", "NoHair", "Eyepatch"].includes(avatarData.topType)}
                                        onChange={(event) => { setAvatarData({ ...avatarData, hairColor: event.target.value }) }}>
                                        {hairs.map(a => { return <MenuItem key={a.value} value={a.value}>{a.text}</MenuItem> })}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={12} md={12} xl={12}>
                                <FormControl sx={{ width: "100%" }}>
                                    <InputLabel htmlFor="hatColor">🎨 Color del gorro</InputLabel>
                                    <Select
                                        label="🎨 Color del gorro"
                                        id="hatColor"
                                        sx={{ width: "100%" }}
                                        value={avatarData.hatColor ?? ""}
                                        disabled={!["Hijab", "Turban", "WinterHat1", "WinterHat2", "WinterHat3", "WinterHat4"].includes(avatarData.topType)}
                                        onChange={(event) => { setAvatarData({ ...avatarData, hatColor: event.target.value }) }}>
                                        {hatColors.map(a => { return <MenuItem key={a.value} value={a.value}>{a.text}</MenuItem> })}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={12} md={12} xl={12}>
                                <FormControl sx={{ width: "100%" }}>
                                    <InputLabel htmlFor="hairColor">💈 Vello facial</InputLabel>
                                    <Select
                                        label="💈 Vello facial"
                                        id="facialHairType"
                                        sx={{ width: "100%" }}
                                        value={avatarData.facialHairType ?? ""}
                                        onChange={(event) => { setAvatarData({ ...avatarData, facialHairType: event.target.value }) }}>
                                        {facialHairs.map(a => { return <MenuItem key={a.value} value={a.value}>{a.text}</MenuItem> })}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={12} md={12} xl={12}>
                                <FormControl sx={{ width: "100%" }}>
                                    <InputLabel htmlFor="facialHairColor">🖌️ Color vello facial</InputLabel>
                                    <Select
                                        label="🖌️ Color vello facial"
                                        id="facialHairColor"
                                        sx={{ width: "100%" }}
                                        value={avatarData.facialHairColor ?? ""}
                                        disabled={avatarData.facialHairType === "Blank"}
                                        onChange={(event) => { setAvatarData({ ...avatarData, facialHairColor: event.target.value }) }}>
                                        {facialHairColors.map(a => { return <MenuItem key={a.value} value={a.value}>{a.text}</MenuItem> })}

                                    </Select>
                                </FormControl>
                            </Grid>



                            <Grid item xs={12} sm={12} md={12} xl={12}>
                                <FormControl sx={{ width: "100%" }}>
                                    <InputLabel htmlFor="clotheType">👔 Ropa</InputLabel>
                                    <Select
                                        label="👔 Ropa"
                                        id="clotheType"
                                        sx={{ width: "100%" }}
                                        value={avatarData.clotheType ?? ""}
                                        onChange={(event) => { setAvatarData({ ...avatarData, clotheType: event.target.value }) }}>
                                        {clothes.map(a => { return <MenuItem key={a.value} value={a.value}>{a.text}</MenuItem> })}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={12} md={12} xl={12}>
                                <FormControl sx={{ width: "100%" }}>
                                    <InputLabel htmlFor="clotheColor">🎨 Color de ropa</InputLabel>
                                    <Select
                                        label="🎨 Color de ropa"
                                        id="clotheColor"
                                        sx={{ width: "100%" }}
                                        value={avatarData.clotheColor ?? ""}
                                        disabled={avatarData.clotheType === "BlazerShirt" || avatarData.clotheType === "BlazerSweater"}
                                        onChange={(event) => { setAvatarData({ ...avatarData, clotheColor: event.target.value }) }}>
                                        {clotheColors.map(a => { return <MenuItem key={a.value} value={a.value}>{a.text}</MenuItem> })}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={12} md={12} xl={12}>
                                <FormControl sx={{ width: "100%" }}>
                                    <InputLabel htmlFor="graphicType">🖼️ Diseño de camiseta</InputLabel>
                                    <Select
                                        label="🖼️ Diseño de camiseta"
                                        id="graphicType"
                                        sx={{ width: "100%" }}
                                        value={avatarData.graphicType ?? ""}
                                        disabled={avatarData.clotheType !== "GraphicShirt"}
                                        onChange={(event) => { setAvatarData({ ...avatarData, graphicType: event.target.value }) }}>
                                        {GraphicShirts.map(a => { return <MenuItem key={a.value} value={a.value}>{a.text}</MenuItem> })}

                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={12} md={12} xl={12}>
                                <FormControl sx={{ width: "100%" }}>
                                    <InputLabel htmlFor="accessoriesType">🕶️ Accesorios</InputLabel>
                                    <Select
                                        label="🕶️ Accesorios"
                                        id="accessoriesType"
                                        sx={{ width: "100%" }}
                                        value={avatarData.accessoriesType ?? ""}
                                        disabled={avatarData.topType === "Eyepatch"}
                                        onChange={(event) => { setAvatarData({ ...avatarData, accessoriesType: event.target.value }) }}>
                                        {accessories.map(a => { return <MenuItem key={a.value} value={a.value}>{a.text}</MenuItem> })}

                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={12} md={12} xl={12}>
                                <FormControl sx={{ width: "100%" }}>
                                    <InputLabel htmlFor="eyeType">👁️ Ojos</InputLabel>
                                    <Select
                                        label="👁️ Ojos"
                                        id="eyeType"
                                        sx={{ width: "100%" }}
                                        value={avatarData.eyeType ?? ""}
                                        onChange={(event) => { setAvatarData({ ...avatarData, eyeType: event.target.value }) }}>
                                        {eyes.map(a => { return <MenuItem key={a.value} value={a.value}>{a.text}</MenuItem> })}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={12} md={12} xl={12}>
                                <FormControl sx={{ width: "100%" }}>
                                    <InputLabel htmlFor="eyebrowType">✏️ Cejas</InputLabel>
                                    <Select
                                        label="✏️ Cejas"
                                        id="eyebrowType"
                                        sx={{ width: "100%" }}
                                        value={avatarData.eyebrowType ?? ""}
                                        onChange={(event) => { setAvatarData({ ...avatarData, eyebrowType: event.target.value }) }}>
                                        {eyeBrows.map(a => { return <MenuItem key={a.value} value={a.value}>{a.text}</MenuItem> })}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={12} md={12} xl={12}>
                                <FormControl sx={{ width: "100%" }}>
                                    <InputLabel htmlFor="mouthType">👄 Boca</InputLabel>
                                    <Select
                                        label="👄 Boca"
                                        id="mouthType"
                                        sx={{ width: "100%" }}
                                        value={avatarData.mouthType ?? ""}
                                        onChange={(event) => { setAvatarData({ ...avatarData, mouthType: event.target.value }) }}>
                                        {mouths.map(a => { return <MenuItem key={a.value} value={a.value}>{a.text}</MenuItem> })}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={12} md={12} xl={12}>
                                <FormControl sx={{ width: "100%" }}>
                                    <InputLabel htmlFor="skinColor">🌈 Piel</InputLabel>
                                    <Select
                                        label="🌈 Piel"
                                        id="skinColor"
                                        sx={{ width: "100%" }}
                                        value={avatarData.skinColor ?? ""}
                                        onChange={(event) => { setAvatarData({ ...avatarData, skinColor: event.target.value }) }}>
                                        {skins.map(a => { return <MenuItem key={a.value} value={a.value}>{a.text}</MenuItem> })}
                                    </Select>
                                </FormControl>
                            </Grid>


                        </Grid>

                    </Grid>

                    <Grid item xs={12} sm={12} md={12} xl={12} container justifyContent="center" style={{ padding: "20px 0px 0px 0px" }}>
                        <LoadingButton disabled={sending} color="info" variant="contained" onClick={() => { setOpenAvatarModal(false) }} loading={sending}>
                            Continuar
                        </LoadingButton>
                    </Grid>
                </Grid>
            </Box>
        </Modal >

    </>
}