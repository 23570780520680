/* eslint-disable */
import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';

import {
  Card,
  Table,
  Stack,
  Paper,
  Button,
  Popover,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Snackbar,
  Alert,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Tooltip,
  Select,
  Modal,
  Box,
  Grid,
  FormControl,
  InputLabel,
  TextField,
  Tabs,
  Tab,
} from '@mui/material';
import Icon from '@mui/material/Icon';
import { LoadingButton, TabPanel } from '@mui/lab';
// components
import Brightness1Icon from '@mui/icons-material/Brightness1';
import Label from '../components/label';
import Iconify from '../components/iconify';
import { UserListHead } from '../sections/@dashboard/user';
import { DisbursementsToolbar } from '../sections/@dashboard/disbursements';
import TarsService from "../services/TarsService"
import NewInvitation from './NewInvitation';
import useResponsive from '../hooks/useResponsive';
import BellService from "../services/BellService"
import { useGlobalProvider } from '../components/GlobalProvider'
import ViewSDKClient from '../components/ViewSDKClient'

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'MovementType', label: 'Tipo movimiento', alignRight: false },
  { id: 'ReferenceC', label: 'Referencia crédito', alignRight: false },
  { id: 'MovementDate', label: 'Fecha movimiento', alignRight: false },
  { id: 'Client', label: 'Cliente', alignRight: false },
  { id: 'Document', label: 'Documento', alignRight: false },
  { id: 'ReferenceP', label: 'Referencia presupuesto', alignRight: false },
  { id: 'StatusLabel', label: 'Estado', alignRight: false },
  { id: 'Amountlabel', label: 'Valor', alignRight: false }
];


const TABLE_HEAD2 = [
  { id: 'MovementType', label: 'Tipo movimiento', alignRight: false },
  { id: 'ReferenceC', label: 'Referencia crédito', alignRight: false },
  { id: 'MovementDate', label: 'Fecha movimiento', alignRight: false },
  { id: 'Client', label: 'Cliente', alignRight: false },
  { id: 'Document', label: 'Documento', alignRight: false },
  { id: 'ReferenceP', label: 'Referencia presupuesto', alignRight: false },
  // { id: 'StatusLabel', label: 'Estado', alignRight: false },
  { id: 'Amountlabel', label: 'Valor', alignRight: false },
  { id: 'Comprobante', label: 'Comprobante', alignRight: false }
];
// ----------------------------------------------------------------------

const PdfViewer = ({ id, fileName, fileURL }) => {


  useEffect(() => {

    const viewSDKClient = new ViewSDKClient();
    viewSDKClient.ready().then(() => {

      viewSDKClient.previewFile(
        id,
        {
          showAnnotationTools: true,
          showLeftHandPanel: true,
          showPageControls: true,
          showDownloadPDF: true,
          showPrintPDF: true,
        },
        fileURL,
        fileName
      );

    });
  }, []);

  return <div style={{ width: "100%", height: "600px" }} id={id} />
}


export default function DisbursementsPage() {

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('desc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('MovementDate');



  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const { posData, treatments, documentTypes, notificationTypes, invitationsStatus, sending, setSending } = useGlobalProvider();

  const [openDisbursements, setOpenDisbursements] = useState([]);
  const [closedDisbursements, setClosedDisbursements] = useState([]);
  const [totalDis, setTotalDis] = useState(0);
  const [totalDis2, setTotalDis2] = useState(0);
  // const initialInvitation = { notificationType: "", documentType: "CCC", document: "", phone: "", email: "", reference: "", amountTreatment: "", amountFinance: "", expirationDate: dayjs().add(15, 'days').format("YYYY-MM-DD"), expirationDateO: dayjs().add(15, 'days'), treatments: [] }

  const [snackbarData, setSnackbarData] = useState({ message: "msg", severity: "warning" });
  // const [newPosNotes, setNewPosNotes] = useState("");
  const [errors, setErrors] = useState({});
  const [loadingButton, setLoadingButton] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [selectedMovement, setSelectedMovement] = useState({ PosNotes: "" });
  const mdUp = useResponsive('up', 'md');
  const [tabValue, setTabValue] = useState(0);
  const { GetOpenDisbursementsByIdPos, GetClosedDisbursementsByIdPos, UpdateMovement, GetUrlSas } = TarsService();
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - openDisbursements.length) : 0;

  const filteredUsers = applySortFilter(openDisbursements, getComparator(order, orderBy), filterName);
  const filteredUsers2 = applySortFilter(closedDisbursements, getComparator(order, orderBy), filterName);

  const isNotFound = !filteredUsers.length && !!filterName;
  const [openCompMenu, setOpenCompMenu] = useState(null);

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [pdfComprobante, setPdfComprobante] = useState({});
  const [comprobantes, setComprobantes] = useState([]);
  const [showPdfComprobanteModal, setShowPdfComprobanteModal] = useState(false);
  const handleClosePdfComprobanteModal = () => setShowPdfComprobanteModal(false);


  const handleCloseCompMenu = () => {
    setOpenCompMenu(null);
  };

  useEffect(() => {
    GetDisbursements();

  }, []);


  const GetDisbursements = () => {
    setSending(true);

    GetOpenDisbursementsByIdPos().then(res => {
      setSending(false);

      const data = JSON.parse(res.data.data);


      if (data.Movements && data.Movements.length > 0) {
        const tempDis = data.Movements.map(c => {
          return {
            MovementType: c.Movement,
            ReferenceC: c.Reference,
            MovementDate: c.MovementDate,
            Client: c.CusFullName,
            Document: c.CusDocument,
            ReferenceP: c.BudgetReference,
            StatusLabel: c.LabelStatus,
            Amountlabel: c.LabelDisbursementAmount,
            Amount: c.DisbursementAmount,
            Status: c.Status,
            LabelInitialAmount: c.LabelInitialAmount,
            InitialAmount: c.InitialAmount,
            MovDescription: c.MovDescription,
            PosNotes: c.PosNotes ?? "",
            IdMovement: c.IdMovement
          }
        });

        setTotalDis(data.Total);
        setOpenDisbursements(tempDis)
      }

    });


    GetClosedDisbursementsByIdPos(dayjs().add(-90, 'days').format("YYYY-MM-DD"), dayjs().format("YYYY-MM-DD")).then(res => {
      setSending(false);

      const data = JSON.parse(res.data.data);


      if (data.Movements && data.Movements.length > 0) {
        const tempDis = data.Movements.map(c => {
          return {
            MovementType: c.Movement,
            ReferenceC: c.Reference,
            MovementDate: c.MovementDate,
            Client: c.CusFullName,
            Document: c.CusDocument,
            ReferenceP: c.BudgetReference,
            StatusLabel: c.LabelStatus,
            Amountlabel: c.LabelDisbursementAmount,
            Amount: c.DisbursementAmount,
            Status: c.Status,
            LabelInitialAmount: c.LabelInitialAmount,
            InitialAmount: c.InitialAmount,
            MovDescription: c.MovDescription,
            PosNotes: c.PosNotes ?? "",
            IdMovement: c.IdMovement,
            disAttachedFiles: c.disAttachedFiles
          }
        });

        setTotalDis2(data.Total ?? 0);
        setClosedDisbursements(tempDis)
      }

    })
  }

  const handleShowPdfComprobante = (comp) => {

    setSending(true)
    GetUrlSas(comp.URL.replaceAll("/files/", "")).then(res => {
      setSending(false)
      if (["jpg", "jpeg", "png", "gif", "tiff", "svg", "pdf"].includes(comp.FileExtension)) {
        comp.fileURL = res.data.data

        setPdfComprobante(comp);
        setShowPdfComprobanteModal(true)
      }
      else {
        // alert("PENDIENTE")
        window.open(res.data.data, '_blank');
      }

    })

    // setPdfComprobante(comp);

    // setShowPdfComprobanteModal(true)
  }


  const handleChangeIndexTab = (e, index) => {
    setTabValue(index);
  };

  function descendingComparator(a, b, orderBy) {

    if (orderBy === 'MovementDate') {

      if (dayjs(b[orderBy]) < dayjs(a[orderBy])) {
        return -1;
      }
      if (dayjs(b[orderBy]) > dayjs(a[orderBy])) {
        return 1;
      }
      // return (new Date(b[orderBy]).valueOf() - new Date(a[orderBy]).valueOf());
    }

    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function applySortFilter(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    if (query) {

      // return filter(array, (_user) => _user.Document.indexOf(query) !== -1); // por documento
      return filter(array, (_user) =>
        (_user.Client ? _user.Client.toLowerCase().indexOf(query.toLowerCase()) !== -1 : false)
        || _user.Document.indexOf(query) !== -1
        || (_user.StatusLabel ? _user.StatusLabel.toLowerCase().indexOf(query.toLowerCase()) !== -1 : false)
        || (_user.ReferenceP ? _user.ReferenceP.toLowerCase().indexOf(query.toLowerCase()) !== -1 : false)
        || (_user.ReferenceC ? _user.ReferenceC.toLowerCase().indexOf(query.toLowerCase()) !== -1 : false)
        || (_user.StatusLabel ? _user.StatusLabel.toLowerCase().indexOf(query.toLowerCase()) !== -1 : false)
        || (_user.MovementDate ? _user.MovementDate.indexOf(query) !== -1 : false)
      );
    }
    return stabilizedThis.map((el) => el[0]);
  }



  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };




  const queryDisbursements = (datesData) => {
    setSending(true);

    setOpenDisbursements([]);
    setClosedDisbursements([])

    setTotalDis(0)
    setTotalDis2(0)

    GetOpenDisbursementsByIdPos(datesData.date1, datesData.date2).then(res => {
      setSending(false);

      const data = JSON.parse(res.data.data);
      if (data.Movements && data.Movements.length > 0) {
        const tempDis = data.Movements.map(c => {
          return {
            MovementType: c.Movement,
            ReferenceC: c.Reference,
            MovementDate: c.MovementDate,
            Client: c.CusFullName,
            Document: c.CusDocument,
            ReferenceP: c.BudgetReference,
            StatusLabel: c.LabelStatus,
            Amountlabel: c.LabelDisbursementAmount,
            Amount: c.DisbursementAmount,
            Status: c.Status,
            LabelInitialAmount: c.LabelInitialAmount,
            InitialAmount: c.InitialAmount,
            MovDescription: c.MovDescription,
            PosNotes: c.PosNotes ?? "",
            IdMovement: c.IdMovement
          }
        });

        setTotalDis(data.Total);
        setOpenDisbursements(tempDis)
      }

    })

    GetClosedDisbursementsByIdPos(datesData.date1, datesData.date2).then(res => {
      setSending(false);

      const data = JSON.parse(res.data.data);
      if (data.Movements && data.Movements.length > 0) {
        const tempDis = data.Movements.map(c => {
          return {
            MovementType: c.Movement,
            ReferenceC: c.Reference,
            MovementDate: c.MovementDate,
            Client: c.CusFullName,
            Document: c.CusDocument,
            ReferenceP: c.BudgetReference,
            StatusLabel: c.LabelStatus,
            Amountlabel: c.LabelDisbursementAmount,
            Amount: c.DisbursementAmount,
            Status: c.Status,
            LabelInitialAmount: c.LabelInitialAmount,
            InitialAmount: c.InitialAmount,
            MovDescription: c.MovDescription,
            PosNotes: c.PosNotes ?? "",
            IdMovement: c.IdMovement,
            disAttachedFiles: c.disAttachedFiles
          }
        });

        setTotalDis2(data.Total);
        setClosedDisbursements(tempDis)
      }

    })

  }



  const handleOpenMenu = (event, row) => {


    setSelectedMovement(row)

    setOpenModal(true);
    // setOpen(event.currentTarget);
  };

  const handleOpenCompMenu = (event, row) => {

    if (row.disAttachedFiles) {
      setComprobantes(row.disAttachedFiles)
      setOpenCompMenu(event.currentTarget)
    }

  };
  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackbar(false);
  };

  const handleCloseModal = (event, reason) => {

    if (reason && reason === "backdropClick")
      return;
    setOpenModal(false)
  }

  const saveAnnotation = () => {

    setSending(true)
    if (selectedMovement.PosNotes) {
      UpdateMovement(selectedMovement.IdMovement, {
        posNotes: selectedMovement.PosNotes
      }).then(res => {
        setSending(false)

        if (res.status === 200) {
          GetDisbursements();
          setOpenSnackbar(true)
          setSnackbarData({ message: "Datos guardados", severity: "success" });
          setOpenModal(false);
        }
        else {
          setOpenSnackbar(true)
          setSnackbarData({ message: "Error guardando la anotación", severity: "error" })
        }

      })
    }
  }


  const modalStyle = {
    margin: "50px auto",
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };

  return (
    <>

      <Snackbar open={openSnackbar} autoHideDuration={5000} onClose={handleCloseSnackbar} anchorOrigin={{ horizontal: "center", vertical: "bottom" }}>
        <Alert onClose={handleCloseSnackbar} severity={snackbarData.severity} sx={{ width: '100%' }}>
          {snackbarData.message}
        </Alert>
      </Snackbar>




      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
        sx={{

          overflow: 'scroll',

        }}

      >
        <Box sx={{ ...modalStyle, width: mdUp ? "60%" : "90%", }}>




          <Grid container spacing={2}>

            {/* <Grid item xs={10} sm={10} md={10}> <h2 id="parent-modal-title">{isUpdate ? "Editar invitación" : "Crear invitación"}</h2></Grid> */}
            <Grid item xs={12} sm={12} md={12} style={{ textAlign: "right" }}><button style={{ border: "none", backgroundColor: "transparent", fontWeight: "bold", fontSize: "30px", cursor: "pointer", marginTop: "20px" }} onClick={() => { setOpenModal(false); }}><Iconify sx={{ width: "30px", height: "30px" }} icon="eva:close-outline" /></button></Grid>


            <Grid item xs={12} sm={3} md={3}>
              <Grid item xs={12} sm={6} md={6} style={{ textAlign: "left", fontWeight: "bold", minWidth: "100%" }}>
                Tipo de movimiento
              </Grid>
              <Grid item xs={12} sm={6} md={6} style={{ textAlign: "left" }}>
                {selectedMovement?.MovementType}
              </Grid>
            </Grid>

            <Grid item xs={12} sm={3} md={3}>
              <Grid item xs={12} sm={6} md={6} style={{ textAlign: "left", fontWeight: "bold", minWidth: "100%" }}>
                Referencia crédito
              </Grid>
              <Grid item xs={12} sm={6} md={6} style={{ textAlign: "left" }}>
                {selectedMovement?.ReferenceC}
              </Grid>
            </Grid>

            <Grid item xs={12} sm={3} md={3}>
              <Grid item xs={12} sm={6} md={6} style={{ textAlign: "left", fontWeight: "bold" }}>
                Cliente
              </Grid>
              <Grid item xs={12} sm={6} md={6} style={{ textAlign: "left", minWidth: "100%" }}>
                {selectedMovement?.Client}
              </Grid>
            </Grid>

            <Grid item xs={12} sm={3} md={3}>
              <Grid item xs={12} sm={6} md={6} style={{ textAlign: "left", fontWeight: "bold", minWidth: "100%" }}>
                Documento
              </Grid>
              <Grid item xs={12} sm={6} md={6} style={{ textAlign: "left", minWidth: "100%" }}>
                {selectedMovement?.Document}
              </Grid>
            </Grid>



            <Grid item xs={12} sm={3} md={3}>
              <Grid item xs={12} sm={6} md={6} style={{ textAlign: "left", fontWeight: "bold", minWidth: "100%" }}>
                Referencia presupuesto
              </Grid>
              <Grid item xs={12} sm={6} md={6} style={{ textAlign: "left", minWidth: "100%" }}>
                {selectedMovement?.ReferenceP}
              </Grid>
            </Grid>

            <Grid item xs={12} sm={3} md={3}>
              <Grid item xs={12} sm={6} md={6} style={{ textAlign: "left", fontWeight: "bold" }}>
                Estado
              </Grid>
              <Grid item xs={12} sm={6} md={6} style={{ textAlign: "left" }}>
                <Label color={selectedMovement?.Status === "PRO" ? "success" : 'warning'}>{selectedMovement?.StatusLabel}</Label>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={3} md={3}>
              <Grid item xs={12} sm={6} md={6} style={{ textAlign: "left", fontWeight: "bold", minWidth: "100%" }}>
                Valor inicial
              </Grid>
              <Grid item xs={12} sm={6} md={6} style={{ textAlign: "left" }}>
                <Label color={selectedMovement?.InitialAmount > 0 ? "success" : 'warning'}>{selectedMovement?.LabelInitialAmount}</Label>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={3} md={3}>
              <Grid item xs={12} sm={6} md={6} style={{ textAlign: "left", fontWeight: "bold", minWidth: "100%" }}>
                Valor desembolso
              </Grid>
              <Grid item xs={12} sm={6} md={6} style={{ textAlign: "left" }}>
                <Label color={selectedMovement?.Amount > 0 ? "success" : 'warning'}>{selectedMovement?.Amountlabel}</Label>
              </Grid>
            </Grid>


            <Grid item xs={12} sm={12} md={12}>
              <Grid item xs={12} sm={12} md={12} style={{ textAlign: "left", fontWeight: "bold" }}>
                Concepto Meddipay
              </Grid>
              <Grid item xs={12} sm={12} md={12} style={{ textAlign: "left" }}>
                {selectedMovement?.MovDescription}
              </Grid>
            </Grid>


            {selectedMovement.disAttachedFiles && <Grid item xs={12} sm={12} md={12}>
              <Grid item xs={12} sm={12} md={12} style={{ textAlign: "left", fontWeight: "bold" }}>
                Comprobante(s)
              </Grid>

              <Grid item xs={12} sm={12} md={12} style={{ textAlign: "left", fontSize: "14px" }}>
                {selectedMovement.disAttachedFiles.map(comp => {
                  return <div key={comp.Id} style={{ display: "flex", alignItems: "center", marginBottom: "5px" }} >
                    <Iconify icon={'eva:file-text-outline'} sx={{ mr: 1 }} />
                    <span style={{ cursor: "pointer", marginTop: "3px" }} onClick={() => handleShowPdfComprobante(comp)}>{comp.SourceName}</span>
                  </div>

                })}
              </Grid>

            </Grid>}


            <Grid item xs={12} sm={12} md={12}>
              <Grid item xs={12} sm={12} md={12} style={{ textAlign: "left", fontWeight: "bold" }}>
                Anotación Interna
              </Grid>
              <Grid item xs={12} sm={12} md={12} style={{ textAlign: "left", minWidth: "100%" }}>

                <TextField multiline style={{ width: "100%" }} value={selectedMovement.PosNotes} onChange={(event) => {
                  setSelectedMovement({ ...selectedMovement, PosNotes: event.target.value });
                }} />

              </Grid>
            </Grid>


            <Grid item xs={12} sm={12} md={12} style={{ textAlign: "right" }}>
              <LoadingButton disabled={sending} color="success" variant="contained" onClick={saveAnnotation} loading={sending}>
                Guardar Anotación
              </LoadingButton>
            </Grid>
          </Grid>
        </Box>
      </Modal>


      <Helmet>
        <title> Desembolsos Sede | Aliados Meddipay </title>
      </Helmet>


      <Tabs
        value={tabValue}
        onChange={handleChangeIndexTab}
        variant="fullWidth"
      >
        <Tab label="Movimientos abiertos" />
        <Tab label="Movimientos cerrados" />
      </Tabs>


      {tabValue === 0 && <>
        <Container style={{ maxWidth: "unset", marginTop: "20px" }}>



          <Card id="card-ip">

            <DisbursementsToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} disbursements={openDisbursements} queryDisbursements={queryDisbursements} />


            <Card style={{ borderRadius: "0" }} >

              <TableContainer style={{ width: mdUp ? "100%" : "99%", margin: "0 auto" }} className={mdUp ? undefined : "main-container-ip"}>
                <Table >
                  <UserListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={openDisbursements.length}
                    numSelected={selected.length}
                    onRequestSort={handleRequestSort}

                  />
                  <TableBody >
                    {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                      const { IdMovement, MovementType, ReferenceC, MovementDate, Client, Document, ReferenceP, StatusLabel, Amountlabel, Status,
                        Amount } = row;
                      const selectedUser = selected.indexOf(Document) !== -1;

                      return (
                        <TableRow style={{ cursor: "pointer" }} hover key={IdMovement} tabIndex={-1} role="checkbox" selected={selectedUser} onClick={(event) => { handleOpenMenu(event, row) }} >
                          {/* <TableCell padding="checkbox">
          <Checkbox checked={selectedUser} onChange={(event) => handleClick(event, document)} />
        </TableCell> */}



                          <TableCell align="left" style={{ maxWidth: "110px" }}>
                            <Tooltip title={MovementType}>
                              <span>
                                {MovementType && MovementType.length > 12 ? `${MovementType.slice(0, 12)}...` : (MovementType ?? "---")}
                              </span>
                            </Tooltip>
                          </TableCell>


                          <TableCell align="left">{ReferenceC}</TableCell>

                          <TableCell align="left" style={{ minWidth: "110px" }}>{dayjs(MovementDate).format("DD-MM-YYYY")}</TableCell>

                          <TableCell align="left" style={{ minWidth: "200px" }}>
                            <Tooltip title={Client}>



                              <span>

                                {Client && Client.length > 15 ? `${Client.slice(0, 15)}...` : (Client ?? "---")}

                              </span>


                            </Tooltip>
                          </TableCell>

                          {/* <TableCell align="left">{Client}</TableCell> */}

                          <TableCell align="left">{Document}</TableCell>

                          <TableCell align="left">{ReferenceP}</TableCell>
                          <TableCell align="left">
                            <Label color={Status === "PRO" || Status === "CLO" ? "success" : 'warning'}>{sentenceCase(StatusLabel)}</Label>
                          </TableCell>

                          <TableCell align="left">
                            <Label color={Amount > 0 ? "success" : 'warning'}>{Amountlabel}</Label>
                          </TableCell>


                        </TableRow>

                      );
                    })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={8} />
                      </TableRow>
                    )}

                    <TableRow>
                      <TableCell align="right" colSpan={8} >
                        Valor total movimientos abiertos: <Label color="success" >{totalDis}</Label>
                      </TableCell>
                    </TableRow>
                  </TableBody>

                  {isNotFound && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={8} sx={{ py: 3 }}>
                          <Paper
                            sx={{
                              textAlign: 'center',
                            }}
                          >
                            <Typography variant="h6" paragraph>
                              No encontrado
                            </Typography>

                            <Typography variant="body2">
                              Sin resultados para &nbsp;
                              <strong>&quot;{filterName}&quot;</strong>.
                              <br />
                            </Typography>
                          </Paper>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}

                  {sending &&

                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={8} sx={{ py: 3 }}>
                          <CircularProgress color="primary" />
                        </TableCell>
                      </TableRow>
                    </TableBody>}
                </Table>




              </TableContainer>
            </Card>

            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={openDisbursements.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage="Filas por página"
            />
          </Card>

        </Container>

      </>}


      {tabValue === 1 && <>
        <Container style={{ maxWidth: "unset", marginTop: "20px" }}>



          <Card id="card-ip">

            <DisbursementsToolbar query numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} disbursements={closedDisbursements} queryDisbursements={queryDisbursements} />


            <Card style={{ borderRadius: "0" }} >

              <TableContainer style={{ width: mdUp ? "100%" : "99%", margin: "0 auto" }} className={mdUp ? undefined : "main-container-ip"}>
                <Table >
                  <UserListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD2}
                    rowCount={openDisbursements.length}
                    numSelected={selected.length}
                    onRequestSort={handleRequestSort}

                  />
                  <TableBody >
                    {filteredUsers2.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                      const { IdMovement, MovementType, ReferenceC, MovementDate, Client, Document, ReferenceP, StatusLabel, Amountlabel, Status,
                        Amount, disAttachedFiles } = row;
                      const selectedUser = selected.indexOf(Document) !== -1;

                      return (
                        <TableRow style={{ cursor: "pointer" }} hover key={IdMovement} tabIndex={-1} role="checkbox" selected={selectedUser}  >
                          {/* <TableCell padding="checkbox">
          <Checkbox checked={selectedUser} onChange={(event) => handleClick(event, document)} />
        </TableCell> */}



                          <TableCell align="left" style={{ maxWidth: "110px" }} onClick={(event) => { handleOpenMenu(event, row) }}>
                            <Tooltip title={MovementType}>
                              <span>
                                {MovementType && MovementType.length > 12 ? `${MovementType.slice(0, 12)}...` : (MovementType ?? "---")}
                              </span>
                            </Tooltip>
                          </TableCell>


                          <TableCell align="left" onClick={(event) => { handleOpenMenu(event, row) }}>{ReferenceC}</TableCell>

                          <TableCell align="left" style={{ minWidth: "110px" }} onClick={(event) => { handleOpenMenu(event, row) }}>{dayjs(MovementDate).format("DD-MM-YYYY")}</TableCell>

                          <TableCell align="left" style={{ minWidth: "200px" }} onClick={(event) => { handleOpenMenu(event, row) }}>
                            <Tooltip title={Client}>



                              <span>

                                {Client && Client.length > 15 ? `${Client.slice(0, 15)}...` : (Client ?? "---")}

                              </span>


                            </Tooltip>
                          </TableCell>

                          {/* <TableCell align="left">{Client}</TableCell> */}

                          <TableCell align="left" onClick={(event) => { handleOpenMenu(event, row) }}>{Document}</TableCell>

                          <TableCell align="left" onClick={(event) => { handleOpenMenu(event, row) }}>{ReferenceP}</TableCell>
                          {/* <TableCell align="left">
                            <Label color={Status === "PRO" ? "success" : 'warning'}>{sentenceCase(StatusLabel)}</Label>
                          </TableCell> */}

                          <TableCell align="left" onClick={(event) => { handleOpenMenu(event, row) }}>
                            <Label color={Amount > 0 ? "success" : 'warning'}>{Amountlabel}</Label>
                          </TableCell>


                          <TableCell align="left" >

                            <Tooltip title={"Comprobante"}>
                              <Icon style={{ color: disAttachedFiles ? "#637381" : "#e3e0e0" }} onClick={(event) => { handleOpenCompMenu(event, row) }}>{row.disAttachedFiles ? "visibility" : "visibility_off"}</Icon>
                            </Tooltip>
                          </TableCell>

                        </TableRow>

                      );
                    })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={7} />
                      </TableRow>
                    )}

                    <TableRow>
                      <TableCell align="right" colSpan={8} >
                        Valor total movimientos cerrados: <Label color="success" >{totalDis2}</Label>
                      </TableCell>
                    </TableRow>
                  </TableBody>

                  {isNotFound && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={7} sx={{ py: 3 }}>
                          <Paper
                            sx={{
                              textAlign: 'center',
                            }}
                          >
                            <Typography variant="h6" paragraph>
                              No encontrado
                            </Typography>

                            <Typography variant="body2">
                              Sin resultados para &nbsp;
                              <strong>&quot;{filterName}&quot;</strong>.
                              <br />
                            </Typography>
                          </Paper>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}

                  {sending &&

                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={7} sx={{ py: 3 }}>
                          <CircularProgress color="primary" />
                        </TableCell>
                      </TableRow>
                    </TableBody>}
                </Table>




              </TableContainer>
            </Card>

            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={openDisbursements.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage="Filas por página"
            />
          </Card>






        </Container>

      </>}



      <Modal open={showPdfComprobanteModal} onClose={handleClosePdfComprobanteModal}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
        sx={{

          overflow: 'scroll',

        }}>
        <Box sx={{ ...modalStyle, width: mdUp ? "60%" : "90%", }}>

          <Grid container spacing={2}>
            <Grid item xs={10} sm={10} md={10}> <h2 id="parent-modal-title">{pdfComprobante.SourceName}</h2></Grid>
            <Grid item xs={2} sm={2} md={2} style={{ textAlign: "right" }}><button style={{ border: "none", backgroundColor: "transparent", fontWeight: "bold", fontSize: "30px", cursor: "pointer", marginTop: "20px" }} onClick={handleClosePdfComprobanteModal}><Iconify sx={{ width: "30px", height: "30px" }} icon="eva:close-outline" /></button></Grid>

          </Grid>
          <Grid item xs={12} sm={12} md={12} xl={12}>
            {["jpg", "jpeg", "png", "gif", "tiff", "svg"].includes(pdfComprobante.FileExtension) ?
              <img src={pdfComprobante.fileURL} style={{ width: "100%" }} alt="comprobante" />
              : pdfComprobante.FileExtension === "pdf" ? <PdfViewer id="adobe-comprobante" fileName={pdfComprobante.SourceName} fileURL={pdfComprobante.fileURL} />
                : <>FILE TYPE NOT FOUND</>
            }

          </Grid>

        </Box>
        {/* <PdfViewer id="adobe-comprobante" fileName={pdfComprobante.SourceName} fileURL={pdfComprobante.fileURL}/> */}

      </Modal>

      <Popover
        open={Boolean(openCompMenu)}
        anchorEl={openCompMenu}
        onClose={handleCloseCompMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 350,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        {comprobantes.map(comp => {
          return <MenuItem key={comp.Id} onClick={() => { setOpenCompMenu(null); handleShowPdfComprobante(comp); }} disabled={sending}>

            <Iconify icon={'eva:file-text-outline'} sx={{ mr: 2 }} />
            <Tooltip title={comp.SourceName} placement="left-start">
              <span>  {comp.SourceName.length > 30 ? `${comp.SourceName.slice(0, 30)}...` : comp.SourceName}</span>
            </Tooltip>

          </MenuItem>
        })}



      </Popover>
    </>
  );
}
