import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
//
import BlogPage from './pages/BlogPage';
import UserPage from './pages/UserPage';
import LoginPage from './pages/LoginPage';
import Page404 from './pages/Page404';
import ProductsPage from './pages/ProductsPage';
import DashboardAppPage from './pages/DashboardAppPage';
import { ProtectedRoutes } from './components/ProtectedRoutes';
import InvitationPage from './pages/InvitationsPage';
import CreditsPage from './pages/CreditsPage';
import DisbursementsPage from './pages/DisbursementsPage';
import GroupDisbursementsPage from './pages/GroupDisbursementsPage';
import RestorePasswordPage from './pages/RestorePasswordPage';
import ResponseChangePassword from './pages/ResponseChangePassword';
import Reports from './pages/Reports';
import Simulator from './pages/Simulator';
import { GlobalProvider } from './components/GlobalProvider';
import CustomersPage from './pages/CustomersPage';


// ----------------------------------------------------------------------

export default function Router() {


  const routes = useRoutes([
    {
      path: '/',
      element: <ProtectedRoutes><LoginPage /></ProtectedRoutes>,
    },
    {
      path: '/login',
      element: <ProtectedRoutes><LoginPage /></ProtectedRoutes>,
    },
    {
      path: '/restore-password',
      element: <ProtectedRoutes><RestorePasswordPage /></ProtectedRoutes>,
    },
    {
      path: '/ResponseChangePassword',

      element: <ResponseChangePassword />,
    },
    {
      path: '/app',
      element: <ProtectedRoutes> <GlobalProvider> <DashboardLayout /> </GlobalProvider></ProtectedRoutes>,
      children: [
        { element: <Navigate to="/app/dashboard" />, index: true },
        { path: 'dashboard', element: <DashboardAppPage /> },
        { path: 'invitation', element: <InvitationPage /> },
        { path: 'credits', element: <CreditsPage /> },
        { path: 'disbursements', element: <DisbursementsPage /> },
        { path: 'reports', element: <Reports /> },
        { path: 'simulator', element: <Simulator /> },
        { path: 'customers', element: <CustomersPage /> },
        // { path: 'group-disbursements', element: <GroupDisbursementsPage /> },
        // { path: 'products', element: <ProductsPage /> },
        // { path: 'blog', element: <BlogPage /> },
      ],
    },
    // {
    //   element: <SimpleLayout />,
    //   children: [
    //     { element: <Navigate to="/dashboard/app" />, index: true },
    //     { path: '404', element: <Page404 /> },
    //     { path: '*', element: <Navigate to="/404" /> },
    //   ],
    // },
    // {
    //   path: '*',
    //   element: <Navigate to="/404" replace />,
    // },
    {
      path: '/404',
      element: <Page404 />,
    },
  ]);

  return routes;
}
