import { Helmet } from 'react-helmet-async';
// @mui
import { styled } from '@mui/material/styles';
import { Link, Container, Typography, Divider, Stack, Button } from '@mui/material';
// hooks
import useResponsive from '../hooks/useResponsive';
// components
import Logo from '../components/logo';
import Iconify from '../components/iconify';
// sections
import { LoginForm } from '../sections/auth/login';

// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const StyledSection = styled('div')(({ theme }) => ({
  width: '100%',

  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  boxShadow: theme.customShadows.card,
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white
}));



// ----------------------------------------------------------------------

export default function LoginPage() {
  const xsUp = useResponsive('up', 'xs');
  const xlUp = useResponsive('up', 'xl');

  const StyledContent = styled('div')(({ theme }) => ({
    maxWidth: 480,
    margin: 'auto',
    minHeight: "100vh",

    justifyContent: 'center',
    flexDirection: 'column',
    padding: xlUp ? theme.spacing(12, 0) : theme.spacing(5, 0),
  }));

  return (
    <>
      <Helmet>
        <title> Login | Aliados Meddipay </title>
      </Helmet>

      <StyledRoot>

        <StyledSection>
          <Logo
            sx={{


              width: { xs: "70%", sm: "70%", md: "70%", xl: "100%" },

            }}
            src="/assets/images/logo1.png"
            disabledLink
          />

          <Typography variant="h3" sx={{ px: 5, mt: 0, mb: 5, alignSelf: "center" }}>
            Tu aliado perfecto
          </Typography>
          {/* <img src="/assets/illustrations/illustration_login.png" alt="login" /> */}
        </StyledSection>


        <Container maxWidth="sm">
          <StyledContent>
            <Typography variant="h4" gutterBottom style={{ color: "#00a9eb" }}>
              Iniciar sesión
            </Typography>

            <Typography variant="body2" sx={{ mb: 5 }}>
              Averigua <a href="https://www.meddipay.com.co/allies" target="_blank" rel="noreferrer" style={{ color: "#00a9eb", textDecoration: "none", fontWeight: "bold" }}>aquí</a> como ser aliado Meddipay

            </Typography>




            <LoginForm />
          </StyledContent>
        </Container>
      </StyledRoot>
    </>
  );
}
