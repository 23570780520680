


const Privacy = () => {

    return (<section >
        <div className="bg-mp-primary">
            <div className="container-fluid text-justify">
                <p className="fs-1 fw-bold">
                    Aviso de privacidad de tratamiento de datos personales</p>
            </div>
        </div>



        <div className="container">


            <div className="my-5">
                <p></p>
                <p>
                    Meddipay S.A.S (En adelante Meddipay), identificada con NIT
                    901763399-5, es responsable del tratamiento de los datos personales
                    que son recolectados y almacenados en las bases datos. Su
                    tratamiento se realiza conforme a su Pol&iacute;tica de privacidad y
                    tratamiento de datos personales, respetando lo
                    consagrado en la Ley 1581 de 2012 y dem&aacute;s normas
                    concordantes.
                </p>
                <p></p>
                <p className="text-mp-primary fs-3 fw-bold">Autorizaci&oacute;n</p>
                <p></p>
                <p>

                    Esta es la autorización mediante la cual el usuario da su consentimiento previo, expreso e informado para que Meddipay almacene y procese sus datos personales, de acuerdo a la Política de privacidad y tratamiento de datos personales.
                    El usuario autoriza explícita y expresamente a MEDDIPAY a consultar la información de Seguridad Social en las Bases de Datos de los Operadores de Información de la Planilla Integrada de Liquidación de Aportes PILA y Proveedores de Facturación y Nomina Electrónica.
                </p>
                <p></p>
                <p className="text-mp-primary fs-3 fw-bold">Finalidades</p>
                <p></p>
                <p>

                    Los datos serán almacenados y tratados por Meddipay, y sus terceros aliados, para las siguientes finalidades:
                </p>
                <p></p>
                <p>
                    1. Recolectar y almacenar la información en nuestras bases de datos.
                    <br />2. Dar cumplimiento a las obligaciones contraídas con nuestros usuarios y/o clientes.
                    <br />3. Prestar los servicios de forma correcta.
                    <br />4. Ordenar y clasificar los datos personales suministrados.
                    <br />5. Verificar los datos personales suministrados, para evitar fraudes o suplantación de identidad.
                    <br />6. Creación y gestión de los perfiles y/o cuentas de los usuarios y/o clientes.
                    <br />7. Mantener contacto y comunicación con los titulares de los datos.
                    <br />8. Entablar comunicación con los usuarios y/o clientes, a través de llamadas, mensajes de texto, correo electrónico o a través de cualquier otro medio de comunicación físico o digital.
                    <br />9. Para gestión administrativa, contable y fiscal.
                    <br />10. Analizar el perfil crediticio y de riesgo de nuestros clientes y/o usuarios.
                    <br />11. Consultar a las centrales de información de riesgo, crediticia y comercial, el historial crediticio de los clientes y/o usuarios.
                    <br />12. Reportar a las centrales de riesgo incumplimiento de los usuarios y/o clientes de sus obligaciones financieras.
                    <br />13. Verificar el cumplimiento de las obligaciones de nuestros clientes y/o usuarios.
                    <br />14. Compartir con terceros, incluyendo la transferencia y transmisión (inclusive internacional), los datos personales de los usuarios y/o clientes.
                    <br />15. Recibir información de terceros sobre nuestros clientes y/o usuarios.
                    <br />16. Suministrar o recibir a las centrales de información datos relacionados con las solicitudes de celebración de contrato de mutuo.
                    <br />17. Presentar reportes o informes ante las autoridades de inspección, vigilancia y control, o atender requerimientos de estas.
                    <br />18. Enviar información relacionada con los servicios contratados e información comercial de otros servicios ofrecidos por Meddipay o nuevos
                    <br />servicios disponibles.
                    <br />19. Gestión comercial y fidelización de clientes.
                    <br />20. Verificar la existencia o reporte en listas restrictivas y de riesgo reputacional.
                    <br />21. Consultar y acceder a información laboral y de seguridad social, incluyendo facturación y nómina electrónica, a través de bases de datos públicas o privadas.
                </p>
                <p></p>
                <p className="text-mp-primary fs-3 fw-bold">
                    Datos personales almacenados y procesados
                </p>
                <p></p>
                <p></p>
                <p>
                    Meddipay puede solicitar y almacenar datos necesarios para la correcta prestación de sus servicios, tales como: 1) Datos de identificación y de contacto, 2) información financiera (Ingresos, gastos, hábitos), 3) información sobre profesión u oficio, 4) información demográfica, 5) datos sensibles.
                </p>
                <p></p>
                <p className="text-mp-primary fs-3 fw-bold">Derechos del titular</p>
                <p></p>
                <p>Los titulares de los datos tienen los siguientes derechos:
                    <br />1. Presentar solicitudes de actualización, información, eliminación de sus datos personales.
                    <br />2. Ser informado del uso que hace Meddipay de sus datos personales.
                    <br />3. Presentar quejas ante las autoridades competentes por violaciones al régimen de protección de datos.
                    <br />4. Revocar la autorización de tratamiento de datos.

                    <br /><br />Los clientes y/o usuarios solo podrán revocar la autorización o suprimir los datos personales si no existe una obligación legal o contractual que implique conservar los datos personales en las bases de datos de Meddipay.
                </p>
                <p></p>
                <p></p>
                <p className="text-mp-primary fs-3 fw-bold">
                    Acceso a la informaci&oacute;n
                </p>
                <p></p>
                <p>
                    Puede tener acceso a los datos personales los titulares de la información (acreditando su identidad), los causahabientes (acreditando la calidad de causahabientes), los representantes o apoderados de los titulares de la información (acreditando la calidad de representante o apoderado)
                </p>
                <p></p>
                <p>
                    Los canales de atenci&oacute;n para acceso a la informaci&oacute;n
                    son los siguientes:
                </p>
                <p></p>
                <p>
                    Atención virtual <a data-fr-linked="true" href="mailto:hola@meddipay.com">hola@meddipay.com</a>
                </p>
                <p>
                    Atención vía WhatsApp: <a target="_blank" rel="noreferrer" href={"https://wa.me/" + encodeURI("+573011633330")}>+57 3011633330</a>
                </p>
                <p></p>
                <p></p>
                <p className="text-mp-primary fs-3 fw-bold">
                    Canales de comunicación
                </p>
                <p></p>
                <p>
                    En virtud de la LEY 2300 DE 2023, Meddipay solicita autorización previa y a elección del usuario/cliente, acerca de los canales de comunicación a través de los cuales acepta ser contactado para el envío o entrega de información comercial o para ejercer actividades de cobranza, de conformidad con la Política de Tratamiento de Datos Personales.

                    El usuario/cliente acepta ser contactado por los canales manifestados al momento del registro y aceptación de la política de tratamiento de datos personales.

                    En caso de que el usuario/cliente requiera ser contactado en horarios distintos a los definidos, deberá manifestarlo expresamente a través del correo electrónico <a data-fr-linked="true" href="mailto:hola@meddipay.com">hola@meddipay.com</a> o al celular <a target="_blank" rel="noreferrer" href={"https://wa.me/" + encodeURI("+573011633330")}>+57 3011633330</a>.
                </p>
            </div>
        </div>
    </section>)



}


export default Privacy;