import axios from "axios";
import { SoraLogin } from "../Config";
import { useCreditRequestAuth } from "../Context/useCreditRequestAuth";

const AuthSoraService = () => {

    const { getStorage } = useCreditRequestAuth();

    let creditRequestToken = getStorage("creditRequestToken")


    const GenerateOTPMethod = async (idMethod) => {

        let respose = await axios.get(SoraLogin("Customers/GenerateOTP?IdMethod=" + idMethod),
            {
                headers: {
                    'content-type': 'application/json',
                    "Authorization": "Bearer " + creditRequestToken
                }
            })
            .then((data) => {
                return data;
            })
            .catch((error) => {
                if (error.response) {
                    return error.response.data; // => the response payload 
                }
                return { error: 1 }
            })

        return respose;

    };

    const validateOTPMethod = async (idMethod, codeOTP) => {

        let respose = await axios.get(SoraLogin("Customers/ValidateOTP?IdMethod=" + idMethod + "&codeOTP=" + codeOTP),
            {
                headers: {
                    'content-type': 'application/json',
                    "Authorization": "Bearer " + creditRequestToken
                }
            })
            .then(res => {

                return res;
            })
            .catch((error) => {

                if (error.response) {
                    return error.response; // => the response payload 
                }
                return { error: 1 }
            })

        return respose;

    };

    return { GenerateOTPMethod, validateOTPMethod }
}

export default AuthSoraService;