import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
// @mui
import { styled } from '@mui/material/styles';
import { Link, Container, Typography, Divider, Stack, Button } from '@mui/material';
// hooks
import { useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import useResponsive from '../hooks/useResponsive';
// components
import Logo from '../components/logo';
import Iconify from '../components/iconify';
// sections

import RestorePasswordForm from '../sections/auth/login/RestorePasswordForm';




// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        display: 'flex',
    },
}));

const StyledSection = styled('div')(({ theme }) => ({
    width: '100%',

    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    boxShadow: theme.customShadows.card,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white
}));



// ----------------------------------------------------------------------

export default function RestorePasswordPage() {
    const xsUp = useResponsive('up', 'xs');
    const xlUp = useResponsive('up', 'xl');
    const navigate = useNavigate();
    const [emailSended, setEmailSended] = useState(false);
    const [email1, setEmail1] = useState(null);

    const StyledContent = styled('div')(({ theme }) => ({
        maxWidth: 480,
        margin: 'auto',
        minHeight: "100vh",

        justifyContent: 'center',
        flexDirection: 'column',
        padding: xlUp ? theme.spacing(12, 0) : theme.spacing(5, 0),
    }));

    return (
        <>
            <Helmet>
                <title> Cambiar contraseña | Aliados Meddipay </title>
            </Helmet>

            <StyledRoot>

                <StyledSection>
                    <Logo
                        sx={{


                            width: { xs: "70%", sm: "70%", md: "70%", xl: "100%" },

                        }}
                        src="/assets/images/logo1.png"
                    />

                    <Typography variant="h3" sx={{ px: 5, mt: 0, mb: 5, alignSelf: "center" }}>
                        Tu aliado perfecto
                    </Typography>
                    {/* <img src="/assets/illustrations/illustration_login.png" alt="login" /> */}
                </StyledSection>


                {!emailSended && (<Container maxWidth="sm" >
                    <StyledContent>
                        <Typography variant="h2" gutterBottom style={{ color: "#00a9eb" }}>
                            ¿Olvidaste tu contraseña?
                        </Typography>

                        <Typography variant="body1" sx={{ mb: 5 }}>
                            A continuación indícanos el usuario del que olvidaste la contraseña
                        </Typography>



                        <RestorePasswordForm setEmailSended={setEmailSended} setEmail1={setEmail1} />

                    </StyledContent>
                </Container>)}

                {emailSended && (<Container maxWidth="sm" >
                    <StyledContent style={{ paddingTop: "50px" }}>
                        <Typography variant="h2" gutterBottom style={{ color: "#00a9eb" }}>
                            Instrucciones enviadas al correo electrónico
                        </Typography>

                        <Typography variant="body1" sx={{ mb: 4, mt: 4, textAlign: "center" }}>
                            {email1}
                        </Typography>

                        <div>
                            <img src='/assets/images/check.svg' alt='check' style={{ margin: "0 auto" }} />
                        </div>


                        <Typography variant="body2" sx={{ mb: 4, mt: 4 }}>
                            Si el usuario proporcionado se encuentra registrado en nuestras bases de datos, se te habrá enviado un correo electrónico con las instrucciones a seguir para crear una nueva contraseña
                        </Typography>

                        <Stack spacing={3} sx={{ my: 2 }}>



                            <LoadingButton color="success" fullWidth size="large" type="button" variant="contained" onClick={() => { navigate("/") }} >
                                Regresar
                            </LoadingButton>

                        </Stack>


                    </StyledContent>
                </Container>)}

            </StyledRoot>
        </>
    );
}
