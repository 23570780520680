
import DocumentCamera from "./DocumentCamera";
import DocumentUi from "./DocumentUi";

function DocumentAutoCapture({ onPhotoTaken, onError }) {

    const handlePhotoTaken = async (imageData, content) => {
        onPhotoTaken(imageData, content);
    };

    return <>
        <DocumentCamera cameraFacing="environment" onPhotoTaken={handlePhotoTaken} onError={onError} />
        <DocumentUi showCameraButtons
            instructions={{
                candidate_selection: "Mantén el documento en posición",
                document_centering: "Centra el documento en el rectángulo",
                document_not_present: "Posiciona el documento en el rectángulo",
                document_too_far: "Acerca el documento",
                sharpness_too_low: "Se necesita más luz",
                brightness_too_low: "Se necesita más luz",
                brightness_too_high: "Se necesita menos luz",
                hotspots_present: "Evita los reflejos"
            }}
            appStateInstructions={{ loading: { text: "Cargando...", visible: true } }} />
    </>

}

export default DocumentAutoCapture;